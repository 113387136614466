import { styled } from 'styled-components'
import { useEffect, useState } from 'react'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import { CircularProgress } from '@mui/material'

import { FilledOrder, Order, OrderStatus } from '@packages/interfaces'
import { formatUsd, formatNumber } from '@packages/utils'

import {
  BUTTON_HOVER_STYLES,
  Badge,
  HeaderContainer,
  RefreshButton,
  Switch,
  TieredTableCell,
  VirtualizedTable,
} from 'src/shared/components'
import { RunesAmountDisplay } from 'src/runes'
import { useDebounce, useIsMobile } from 'src/shared/hooks'
import { BREAKPOINTS, COLORS, FONTS } from 'src/shared/constants'
import { hexToRgb } from 'src/shared/utils'
import { AddressDetails, useWalletContext } from 'src/wallet'
import { useGoogleAnalyticsContext } from 'src/analytics'

import { OrderStatusQueryParams, useOrdersByStatusApi } from '../hooks'
import { formatPriceSymbol } from '../utils'
import { useOrderContext } from '../OrderContext'
import { BtcAmountDisplay } from './BtcAmountDisplay'

const OPEN_ORDER_STATUS: OrderStatus = 'PlacedActive'
const FILLED_PENDING_ORDER_STATUS: OrderStatus = 'FilledPending'

const OPEN_ORDERS_QUERY_PARAMS: OrderStatusQueryParams = {
  status: OPEN_ORDER_STATUS,
  sortOrder: 'asc',
  sortOrderBy: 'price',
  limit: 100,
}

const FILLED_PENDING_QUERY_PARAMS: OrderStatusQueryParams = {
  status: FILLED_PENDING_ORDER_STATUS,
  sortOrder: 'desc',
  sortOrderBy: 'timestamp',
  limit: 25,
}

function isOwnOrder(order: Order, runesAddress?: AddressDetails) {
  return order.placedByAddress === runesAddress?.addrString
}

export function OpenSellOrdersTable() {
  const {
    selectedSellOrders,
    setSelectedSellOrders,
    isStaleOrderDataTimestamp,
    selectedRune,
    recentlyFilledOrders,
    recentlyCancelledOrders,
  } = useOrderContext()
  const { runesAddress } = useWalletContext()
  const isMobile = useIsMobile()
  const [showPendingOrders, setShowPendingOrders] = useState(false)
  const [hideMyOrders, setHideMyOrders] = useState(false)
  const [filteredOrders, setFilteredOrders] = useState<Order[][]>([])
  const { customEvent, abandonEvent } = useGoogleAnalyticsContext()

  const {
    paginatedData: openOrders,
    fetchPage,
    newPageLoading,
    hasNextPage,
    forceRefresh,
  } = useOrdersByStatusApi({
    runeName: selectedRune?.runeName ?? '',
    queryParams: OPEN_ORDERS_QUERY_PARAMS,
    disabled: !selectedRune,
  })

  const { paginatedData: filledPendingOrders, forceRefresh: filledForceRefresh } =
    useOrdersByStatusApi<FilledOrder>({
      runeName: selectedRune?.runeName ?? '',
      queryParams: FILLED_PENDING_QUERY_PARAMS,
      disabled: !selectedRune,
    })

  async function refreshOrders() {
    await forceRefresh()
    await filledForceRefresh()
  }

  useEffect(() => {
    if (isStaleOrderDataTimestamp) {
      refreshOrders()
    }
  }, [isStaleOrderDataTimestamp])

  useEffect(() => {
    let orders = openOrders

    orders = orders.map((nestedOrders) => {
      return nestedOrders.filter((order) => {
        if (hideMyOrders && runesAddress && isOwnOrder(order, runesAddress)) {
          return false
        }
        // Check if order was recently filled
        if (recentlyFilledOrders.some((filledOrder) => filledOrder.id === order.id)) {
          return false
        }
        // Check if order was recently cancelled
        if (recentlyCancelledOrders.some((cancelledOrder) => cancelledOrder.id === order.id)) {
          return false
        }

        return true
      })
    })

    if (showPendingOrders && filledPendingOrders.length > 0) {
      orders = [...filledPendingOrders, ...orders]
    }
    setFilteredOrders(orders)
  }, [
    runesAddress,
    openOrders,
    showPendingOrders,
    hideMyOrders,
    runesAddress?.addrString,
    filledPendingOrders,
    recentlyFilledOrders,
  ])

  useDebounce(
    async () => {
      if (selectedSellOrders.length === 0) {
        refreshOrders()
      }
    },
    [selectedSellOrders.length],
    1000
  )

  function removeOrder(order: Order) {
    customEvent('remove', false, 'swap', {
      token_name: order.runeName,
      token_type: 'rune',
      order_amount: order.runesAmount,
      price: order.satsAmount,
    })
    if (selectedSellOrders.length == 1)
      abandonEvent(true, 'swap', {
        token_name: order.runeName,
        token_type: 'rune',
        order_action: 'buy',
        order_amount: order.runesAmount,
        price: order.satsAmount,
      })
    setSelectedSellOrders((prev) => prev.filter((selected) => selected.id !== order.id))
  }

  function addOrder(order: Order) {
    customEvent('add', false, 'swap', {
      token_name: order.runeName,
      token_type: 'rune',
      order_action: 'buy',
      order_amount: order.runesAmount,
      price: order.satsAmount,
    })
    setSelectedSellOrders((prev) => [...prev, order])
  }

  function onRefreshButtonClick() {
    refreshOrders()
  }

  return (
    <Container>
      <HeaderContainerWrapper>
        <SwitchesContainer>
          {runesAddress && (filteredOrders[0]?.length > 0 || hideMyOrders) && (
            <Switch checked={hideMyOrders} onChange={setHideMyOrders} label={'Hide My Orders'} />
          )}
          {filledPendingOrders.length > 0 && filledPendingOrders[0].length > 0 && (
            <Switch
              checked={showPendingOrders}
              onChange={setShowPendingOrders}
              label={'Show Pending Orders'}
            />
          )}
        </SwitchesContainer>
        <RefreshButtonWrapper onClick={onRefreshButtonClick} />
      </HeaderContainerWrapper>
      <VirtualizedTable
        columns={[
          {
            dataKey: 'buyButton',
            label: 'Buy',
            width: 15,
            disableSort: true,
            formatter: ({ data: order }) => {
              if (order.status === FILLED_PENDING_ORDER_STATUS) {
                return (
                  <PendingBuyButton>
                    <LoadingIcon />
                  </PendingBuyButton>
                )
              }

              if (isOwnOrder(order, runesAddress)) {
                return <YourOrderBadge>Yours</YourOrderBadge>
              }
              return (
                <BuyButton>
                  {selectedSellOrders.find((selected) => selected.id === order.id) ? (
                    <AddCircleRoundedIconWrapper onClick={() => removeOrder(order)} />
                  ) : (
                    <AddCircleOutlineRoundedIcon onClick={() => addOrder(order)} />
                  )}
                </BuyButton>
              )
            },
          },
          {
            dataKey: 'priceSats',
            width: isMobile ? 25 : 30,
            label:
              'Price' +
              (!isMobile
                ? ` (${formatPriceSymbol({ runeSymbol: selectedRune?.runeSymbolChar })})`
                : ''),
            formatter: ({ data: order }) => {
              return (
                <TdContainer
                  $orderStatus={order.status}
                  $isOwnOrder={isOwnOrder(order, runesAddress)}
                >
                  <TieredTableCell
                    header={formatNumber({ numStr: order.priceSats, useCompactNotation: isMobile })}
                    subHeader={order.priceUsd}
                  />
                </TdContainer>
              )
            },
          },
          {
            dataKey: 'runesAmount',
            width: 25,
            label: isMobile ? 'Qty' : 'Quantity',
            formatter: ({ data: order }) => (
              <RunesAmountDisplay
                rune={selectedRune}
                runesAmount={order.runesAmount}
                shortFormat={isMobile}
                color={
                  order.status === FILLED_PENDING_ORDER_STATUS || isOwnOrder(order, runesAddress)
                    ? hexToRgb(COLORS.white, 0.6)
                    : COLORS.white
                }
              />
            ),
          },
          {
            dataKey: 'satsAmount',
            width: 30,
            label: 'Total',
            formatter: ({ data: order }) => (
              <TdContainer
                $orderStatus={order.status}
                $isOwnOrder={isOwnOrder(order, runesAddress)}
              >
                <TieredTableCell
                  header={<BtcAmountDisplay btcAmount={order.satsAmount} showPriceSymbol />}
                  subHeader={formatUsd({ usd: order.valueUsd, shortFormat: isMobile })}
                />
              </TdContainer>
            ),
          },
        ]}
        paginatedData={filteredOrders}
        fetchPage={fetchPage}
        loading={newPageLoading}
        hasNextPage={hasNextPage}
        rowHeight={45}
        viewableRows={25}
        headerShown={false}
        emptyDataMessage='No open orders'
      />
    </Container>
  )
}

const Container = styled.div``

const HeaderContainerWrapper = styled(HeaderContainer)`
  padding-top: 0px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    align-items: center;
  }
`

const SwitchesContainer = styled.div`
  display: flex;
  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;

    .MuiFormControlLabel-label {
      font-size: 14px;
    }
  }
`

const BuyButton = styled.div`
  ${BUTTON_HOVER_STYLES}
  width: fit-content;
  height: fit-content;
  svg {
    height: 28px;
    width: 28px;
  }
`

const PendingBuyButton = styled.div`
  padding-left: 5px;
`

const LoadingIcon = styled(CircularProgress)`
  color: ${COLORS.white};
  width: 20px !important;
  height: 20px !important;
  animation-duration: 2.8s;

  svg {
    circle {
      animation-duration: 2.8s;
    }
  }
`

const TdContainer = styled.div<{ $orderStatus: OrderStatus; $isOwnOrder: boolean }>`
  width: 100%;
  display: flex;

  color: ${({ $orderStatus, $isOwnOrder }) =>
    $orderStatus === FILLED_PENDING_ORDER_STATUS || $isOwnOrder
      ? hexToRgb(COLORS.white, 0.6)
      : COLORS.white} !important;

  div {
    div:first-child {
      color: ${({ $orderStatus, $isOwnOrder }) =>
        $orderStatus === FILLED_PENDING_ORDER_STATUS || $isOwnOrder
          ? hexToRgb(COLORS.white, 0.6)
          : COLORS.white} !important;
    }
  }
`

const YourOrderBadge = styled(Badge)`
  border: 1px solid ${COLORS.hover};
  font-family: ${FONTS.text};
  text-transform: uppercase;
  font-size: 12px !important;
  border-radius: 5px !important;
  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: 9px !important;
  }
`

const AddCircleRoundedIconWrapper = styled(AddCircleRoundedIcon)`
  color: ${COLORS.hover};
`

const RefreshButtonWrapper = styled(RefreshButton)`
  padding: 0px;
`
