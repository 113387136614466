import { Rune } from './Rune'

export interface Order extends Rune {
  id: bigint

  runesAmount: bigint
  satsAmount: bigint

  valueUsd: bigint // using usd decimals

  priceUsd: string
  priceSats: string

  isActive: boolean
  status: OrderStatus

  /**
   * @deprecated Do not rely on this value if the service fee rate changes this value will be incorrect
   */
  marketplaceFeeAmountSats?: bigint
  /**
   * @deprecated Do not rely on this value if the service fee rate changes this value will be incorrect
   */
  marketplaceFeeAmountUsd?: bigint // using usd decimals
  marketplaceFeeReceivedByAddress?: string

  satsPerVbyteFee?: bigint

  placedByAddress: string
  placedAtTimestamp: bigint

  originalOutpointIds?: string[]
  boxTransactionId?: string
  boxedAtBlockHeight?: bigint
  boxedAtTimestamp?: bigint

  cancelledAtTimestamp?: bigint

  fillTransactionId?: string
  fillBroadcastAtTimestamp?: bigint
  filledByAddress?: string
  filledAtBlockHeight?: bigint
  filledAtTimestamp?: bigint

  voidedAtBlockHeight?: bigint
  voidedByTransactionId?: string
  voidedAtTimestamp?: bigint
}

export type OrderStatus =
  | 'PlacedPending'
  | 'PlacedActive'
  | 'Placed'
  | 'FilledPending'
  | 'Filled'
  | 'Cancelled'
  | 'Voided'

export const ANY_ORDER_STATUS = 'Any' as const

export function isOrderStatus(status: string): status is OrderStatus {
  return [
    'PlacedPending',
    'PlacedActive',
    'Placed',
    'FilledPending',
    'Filled',
    'Cancelled',
    'Voided',
  ].includes(status)
}

export interface FillPendingOrder extends Order {
  status: 'FilledPending'
  fillTransactionId: string
  fillBroadcastAtTimestamp: bigint
}

export interface FilledOrder extends Order {
  status: 'Filled'
  fillTransactionId: string
  fillBroadcastAtTimestamp: bigint
  filledByAddress: string
  filledAtBlockHeight: bigint
  filledAtTimestamp: bigint
}

export interface VoidedOrder extends Order {
  status: 'Voided'
  voidedAtBlockHeight: bigint
  voidedByTransactionId: string
  voidedAtTimestamp: bigint
}

export interface CancelledOrder extends Order {
  status: 'Cancelled'
  cancelledAtTimestamp: bigint
}
