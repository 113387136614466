import { Link } from 'react-router-dom'
import { styled } from 'styled-components'

import { COLORS, FONTS } from 'src/shared/constants'
import { buildMarketDetailsUrl } from 'src/pages'
import { BUTTON_HOVER_STYLES } from 'src/shared/components'
import { applyOnlyBorderGlow } from 'src/shared/styles'

interface Props {
  runeName: string
  className?: string
}

export function MarketDetailsButton({ runeName, className }: Props) {
  return (
    <LinkWrapper to={buildMarketDetailsUrl(runeName, 'sell')} className={className}>
      Swap
    </LinkWrapper>
  )
}

const LinkWrapper = styled(Link)`
  width: fit-content;
  padding: 5px 15px;
  text-transform: uppercase;
  background-color: ${COLORS.black};
  color: ${COLORS.white};
  font-family: ${FONTS.text};
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 20px;
  font-size: 15px;
  ${BUTTON_HOVER_STYLES}
  ${applyOnlyBorderGlow({ color: COLORS.hover, borderWidth: 2 })}
`
