import { useCallback, useEffect } from 'react'
import { toast } from 'react-toastify'

import { RefreshPageToast } from 'src/shared/components'
import { useWalletContext } from 'src/wallet'

export function useUpdateOnBlock(effect: () => void, dependencies: any[], autoUpdate?: boolean) {
  const { blockTip } = useWalletContext()
  const callback = useCallback(effect, dependencies)

  useEffect(() => {
    callback()
  }, [callback])

  useEffect(() => {
    if (autoUpdate) {
      console.debug('block updated, refreshing data', blockTip)
      callback()
    } else {
      console.debug('block updated, but disabled', blockTip)

      toast(
        <RefreshPageToast
          onClick={() => {
            callback()
          }}
          message='Viewing outdated data'
          buttonMessage='Update to latest'
        />,
        { toastId: 'refresh-page-block-update' }
      )
    }
  }, [blockTip])
}
