import { API_ENDPOINTS } from '@packages/constants'
import { BtcPrice } from '@packages/interfaces'

import { API_URL } from 'src/settings'
import { mempoolUrls } from 'src/web3'

export async function getBtcPrice({ setBtcPrice }: { setBtcPrice: (btcPrice: BtcPrice) => void }) {
  try {
    const response = await fetch(`${API_URL}${API_ENDPOINTS.GET.btc.price}`)
    if (response.ok) {
      const btcPrice = await response.json()
      setBtcPrice(btcPrice)
    }
  } catch (error) {
    console.error(error)
    try {
      const response = await fetch(mempoolUrls.price())
      if (response.ok) {
        const btcPrice = await response.json()
        setBtcPrice(btcPrice)
      }
    } catch (error) {
      console.error(error)
    }
  }
}
