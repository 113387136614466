import { EtchCommitRuneForm, LatestEtchingsTable } from 'src/etching'
import { DISABLE_ETCHING } from 'src/settings'
import { HeaderText } from 'src/shared/components'

import { PageContainer } from './PageContainer'

export function EtchPage() {
  return (
    <PageContainer>
      {!DISABLE_ETCHING && <EtchCommitRuneForm />}

      <HeaderText>Latest Etchings</HeaderText>
      <LatestEtchingsTable />
    </PageContainer>
  )
}
