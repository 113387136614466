import { styled } from 'styled-components'
import { FormHelperText } from '@mui/material'

import { bigIntToInteger } from '@packages/utils'

import { COLORS, FONTS } from 'src/shared/constants'
import { hexToRgb } from 'src/shared/utils'

import { BigNumberInput } from './BigNumberInput'

interface Props {
  value?: bigint
  onChange: (val: bigint) => void
  onFocus?: () => void
  onBlur?: () => void
  decimals: bigint | number
  disabled?: boolean
  loading?: boolean
  error?: boolean
  className?: string
  helperText?: string
  inputRef?: any
}

export function BigIntInput({
  value,
  onChange,
  onFocus,
  onBlur,
  decimals,
  disabled = false,
  loading = false,
  error = undefined,
  helperText,
  className,
  inputRef,
}: Props) {
  function handleChange(strValue: string) {
    onChange(strValue === '' ? BigInt(0) : BigInt(strValue))
  }

  return (
    <Container $error={error} className={className}>
      <BigNumberInput
        decimals={typeof decimals === 'bigint' ? bigIntToInteger(decimals) : decimals}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value?.toString()}
        placeholder='0'
        disabled={disabled}
        loading={loading}
        inputRef={inputRef}
      />
      {helperText && <FormHelperText error>{helperText}</FormHelperText>}
    </Container>
  )
}

const Container = styled.div<{ $error?: boolean }>`
  width: 100%;
  height: 100%;
  p {
    line-height: 15px;
    padding: 10px 0px;
    margin: 0px;
  }
  input {
    width: 100%;
    border: 2px solid ${({ $error }) => ($error ? COLORS.negative : COLORS.white)};
    border-radius: 8px;
    font-family: ${FONTS.text};
    color: ${COLORS.white};
    padding: 14px;
    border-radius: 8px;
    width: 100%;
    background: transparent;

    &:hover {
      border-color: ${COLORS.hover};
    }

    &:disabled {
      color: ${hexToRgb(COLORS.white, 0.6)};
    }
  }

  .MuiFormHelperText-root {
    font-family: ${FONTS.text};
    font-size: 15px;
    color: ${COLORS.negative};
  }
`
