import { styled } from 'styled-components'

import { COLORS } from '../constants'
import { DEFAULT_CSS_TRANSITION } from '../styles'

interface Props {
  className?: string
}

export function DotsLoader({ className }: Props) {
  return (
    <Container className={className}>
      <div></div>
      <div></div>
      <div></div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 15px;
  ${DEFAULT_CSS_TRANSITION}

  div {
    width: 12px;
    height: 12px;
    margin: 3px 6px;
    border-radius: 50%;
    background-color: ${COLORS.hover};
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
  }

  @keyframes bouncing-loader {
    to {
      opacity: 0.1;
      transform: translateY(-16px);
    }
  }

  div:nth-child(2) {
    animation-delay: 0.2s;
  }

  div:nth-child(3) {
    animation-delay: 0.4s;
  }
`
