import { formatUnits } from 'ethers'

import { Rune } from '@packages/interfaces'
import { formatNumber } from '@packages/utils'

export function formatRunesAmount({
  rune,
  runesAmount,
  displayShortFormat = false,
  displayRuneSymbol = false,
}: {
  rune: Rune
  runesAmount: bigint
  displayShortFormat?: boolean
  displayRuneSymbol?: boolean
}): string {
  const formattedString = formatUnits(runesAmount, rune.runeDecimals)
  return `${displayRuneSymbol ? rune.runeSymbolChar ?? '¤' : ''}${
    runesAmount !== BigInt(0)
      ? formatNumber({ numStr: formattedString, useCompactNotation: displayShortFormat })
      : 0
  }`
}
