import styled from 'styled-components'

import { BREAKPOINTS } from 'src/shared/constants'

import { FOOTER_HEIGHT, NAVBAR_HEIGHT, NAVBAR_HEIGHT_MOBILE } from '../constants'

export const PageContainer = styled.div`
  width: 100%;
  max-width: ${BREAKPOINTS.large};
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: ${parseInt(NAVBAR_HEIGHT) + 20}px;
  padding-bottom: ${parseInt(FOOTER_HEIGHT) + 20}px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-top: ${parseInt(NAVBAR_HEIGHT_MOBILE) + 10}px;
    gap: 15px;
  }
`
