import { useEffect, useState } from 'react'

import { API_ENDPOINTS } from '@packages/constants'
import { Market } from '@packages/interfaces'

import { apiFetch } from 'src/api'
import { replaceUrlParams } from 'src/shared/utils'
import { useInterval } from 'src/shared/hooks'

const UPDATE_INTERVAL_MS = 1000 * 60 * 2

async function getMarket({
  runeId,
  setRuneMarket,
  setError,
}: {
  runeId?: string
  setRuneMarket: (market: Market) => void
  setError: (error: string) => void
}) {
  if (runeId) {
    try {
      const market = await apiFetch<Market>(
        `${replaceUrlParams(API_ENDPOINTS.GET.runes.markets.get, {
          runeId,
        })}`
      )
      setRuneMarket(market)
    } catch (error) {
      console.error('Error getting market', error)
      setError('Error getting market')
    }
  }
}

export function useMarket(runeId?: string) {
  const [error, setError] = useState<string>()
  const [runeMarket, setRuneMarket] = useState<Market>()

  useInterval(() => getMarket({ runeId, setRuneMarket, setError }), UPDATE_INTERVAL_MS)

  useEffect(() => {
    getMarket({ runeId, setRuneMarket, setError })
  }, [runeId])

  return { market: runeMarket, error }
}
