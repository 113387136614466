import * as React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { styled } from 'styled-components'

import { COLORS, FONTS } from 'src/shared/constants'

interface Props {
  label: string
  value: string
  options: { value: string; label: string }[]
  onChange: (value: string) => void
  row?: boolean
}

export function RadioButtonGroup({ label, value, options, onChange, row }: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange((event.target as HTMLInputElement).value)
  }

  return (
    <Container>
      <FormLabel>{label}</FormLabel>
      <RadioGroup value={value} onChange={handleChange} row={row}>
        {options.map((option) => {
          return <FormControlLabel value={option.value} control={<Radio />} label={option.label} />
        })}
      </RadioGroup>
    </Container>
  )
}

const Container = styled(FormControl)`
  label {
    font-family: ${FONTS.text};
    color: ${COLORS.white} !important;
    font-weight: 500;
  }

  span {
    color: ${COLORS.white};
  }

  span .Mui-checked {
    color: ${COLORS.link} !important;
  }

  svg {
    height: 30px;
    width: 30px;
  }
`
