import Decimal from 'decimal.js-light'

import { Order } from '@packages/interfaces'
import { MAX_SATS_PRICE_DECIMALS, bigIntToDecimal, formatNumber } from '@packages/utils'

import { parsePriceStr } from './price'

export function calculateWeightedAveragePrice({
  orders,
  format = true,
}: {
  orders: Order[]
  format?: boolean
}): string {
  const totalWeightedPrice = orders.reduce(
    (sum, order) =>
      sum.add(new Decimal(parsePriceStr(order.priceSats)).mul(bigIntToDecimal(order.runesAmount))),
    new Decimal(0)
  )
  const totalQuantity = orders.reduce(
    (sum, order) => sum.add(bigIntToDecimal(order.runesAmount)),
    new Decimal(0)
  )

  if (totalQuantity.eq(0)) return '0'
  const numStr = totalWeightedPrice.div(totalQuantity).toFixed(MAX_SATS_PRICE_DECIMALS)
  if (format) {
    return formatNumber({ numStr })
  }
  return numStr
}
