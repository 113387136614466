import { formatRuneName } from '@packages/utils'

function insertLineBreaks(runeName: string) {
  return runeName
    .split('•')
    .flatMap((part, index, array) =>
      index < array.length - 1 ? [part, '•', <wbr key={index} />] : [part]
    )
}

export function RuneNameDisplay({
  rune,
}: {
  rune: { runeName: string; runeNameSpacerIndices?: bigint[] }
}) {
  const runeName = formatRuneName(rune)
  return insertLineBreaks(runeName)
}
