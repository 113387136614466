import { styled } from 'styled-components'

import { formatUsd, satsToBtc } from '@packages/utils'
import { Rune, isMarket } from '@packages/interfaces'

import { DotsLoader, PaginatedTable, TieredTableCell } from 'src/shared/components'
import { BREAKPOINTS } from 'src/shared/constants'
import { RuneNameInput, formatRunesAmount } from 'src/runes'
import { useMintsMarketSearch } from 'src/minting'
import { BtcAmountDisplay } from 'src/orders'

import { MarketDetailsLink } from './MarketDetailsLink'

export function MarketSearch() {
  const { handleOnChange, loading, hasSearched, searchResults, searchQuery } = useMintsMarketSearch(
    {}
  )

  return (
    <Container>
      <InputSection>
        <SearchInput value={searchQuery} onChange={handleOnChange} label='Search by Rune Name' />
      </InputSection>
      {loading && <DotsLoader />}
      {hasSearched && (
        <TableSection>
          <PaginatedTableWrapper
            columns={[
              {
                dataKey: 'name',
                label: 'Token',
                width: 40,
                formatter: ({ data: marketOrDetails }) => {
                  return <MarketDetailsLink rune={marketOrDetails as Rune} />
                },
              },
              // {
              //   dataKey: 'priceSats',
              //   label: 'Floor Price (sats)',
              //   width: 25,
              //   hideOnMobile: true,
              //   formatter: ({ data: marketOrDetails }) => {
              //     if (isMarket(marketOrDetails)) {
              //       return (
              //         <TieredTableCell
              //           header={formatNumber({ numStr: marketOrDetails.floorPriceSats })}
              //           subHeader={formatUsd(marketOrDetails.floorPriceUsd)}
              //         />
              //       )
              //     } else {
              //       return <TieredTableCell header={0} subHeader={'$0.00'} />
              //     }
              //   },
              // },
              // {
              //   dataKey: 'marketCapSats',
              //   label: 'Market Cap (BTC)',
              //   width: isMobile ? 35 : 25,
              //   formatter: ({ data: marketOrDetails }) => {
              //     if (isMarket(marketOrDetails)) {
              //       return (
              //         <TieredTableCell
              //           header={`${shortFormatBtc(satsToBtc(marketOrDetails.marketCapSats))}`}
              //           subHeader={formatUsd(marketOrDetails.marketCapUsd, true)}
              //         />
              //       )
              //     } else {
              //       return <TieredTableCell header={0} subHeader={'$0.00'} />
              //     }
              //   },
              // },
              {
                dataKey: 'volumeSats',
                label: 'Volume',
                width: 30,
                formatter: ({ data: marketOrDetails }) => {
                  if (isMarket(marketOrDetails)) {
                    return (
                      <TieredTableCell
                        header={
                          <BtcAmountDisplay
                            btcAmount={satsToBtc(marketOrDetails.volumeSats)}
                            showPriceSymbol
                          />
                        }
                        subHeader={formatUsd({
                          usd: marketOrDetails.volumeUsd,
                          shortFormat: true,
                        })}
                      />
                    )
                  } else {
                    return <TieredTableCell header={0} subHeader={'$0.00'} />
                  }
                },
              },
              {
                dataKey: 'balance',
                label: 'Balance',
                width: 30,
                formatter: ({ data: marketOrDetails }) => {
                  if ((marketOrDetails as any)?.balance) {
                    return formatRunesAmount({
                      rune: marketOrDetails as any,
                      runesAmount: (marketOrDetails as any)?.balance,
                    })
                  }
                  return 0
                },
              },
            ]}
            paginatedData={[searchResults]}
            fetchPage={async () => {}}
            loading={loading}
            hasNextPage={false}
            emptyDataMessage='No results found.'
            rowHeight={30}
            rowsPerPage={50}
          />
        </TableSection>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: center;
`
const SearchInput = styled(RuneNameInput)`
  width: 100%;
  max-width: 500px;
  text-transform: uppercase;

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`

const InputSection = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-top: 0px;
  }
`

const TableSection = styled.div`
  width: 100%;
  max-width: 800px;
`

const PaginatedTableWrapper = styled(PaginatedTable)`
  th {
    padding: 10px !important;
  }
  td {
    padding: 10px !important;
  }
`
