export const API_ENDPOINTS = {
  healthCheck: '/healthz',
  GET: {
    settings: {
      get: '/v1/settings',
    },
    runes: {
      mints: {
        popular: '/v1/mints/popular',
        batchByAddress: '/v1/account/:address/batch-mints',
      },
      etchings: {
        latest: '/v1/etchings/latest',
      },
      markets: {
        get: '/v1/market/:runeId',
        mintsSearch: '/v1/markets/search/mints',
        ordersSearch: '/v1/markets/search/orders',
        popular: '/v1/markets/popular',
      },
      orders: {
        byStatus: '/v1/rune/:runeName/orders/status',
        byAccount: '/v1/account/:address/orders',
        byRuneForAccount: '/v1/account/:address/orders/:runeId',
      },
      details: '/v1/rune/:runeName/details/',
      balances: {
        byRune: '/v1/rune/:runeId/balances',
        byAccount: '/v1/account/:address/balances',
        runeBalanceByAccount: '/v1/account/:address/balance/:runeId',
      },
      outpoints: {
        runesOutpointsForAccount: '/v1/outpoints/runes/:runeId/:address',
        cleanUtxosForAccount: '/v1/outpoints/clean/:address',
        runeAmountsForOutpoint: '/v1/outpoints/outpoint/:outpointId',
      },
      name: {
        isValidRuneName: '/v1/rune/name/is-valid',
      },
    },
    accounts: {
      history: '/v1/account/:address/history',
    },
    auth: {
      challenge: '/v1/challenge',
    },
    chain: {
      recommendedFees: '/v1/chain/recommended-fees',
      blockTip: '/v1/chain/block-tip',
      // utxos: '/v1/chain/utxos/:address',
    },
    bitcoind: {
      chainInfo: '/v1/bitcoind/chain-info',
    },
    btc: {
      price: '/v1/btc/price',
    },
    externalMarketData: {
      summary: '/v1/market-data/summary',
      // Unclear if we need these yet. It seems like CMC may not require them. Will re-add them if needed.
      // assets: '/v1/market-data/assets',
      // ticker: '/v1/market-data/ticker',
      // orderBook: '/v1/market-data/orderbook/:marketPair',
      // trades: '/v1/market-data/trades/:marketPair',
    },
  },
  POST: {
    runes: {
      orders: {
        sell: '/v1/rune/:runeName/orders/sell',
        cancel: '/v1/rune/:runeName/orders/:orderId/cancel',
        requestBuy: '/v1/rune/:runeName/orders/:orderId/request-buy',
        submitBuy: '/v1/rune/:runeName/orders/:orderId/submit-buy',
      },
      mints: {
        submitMints: '/v1/rune/:runeName/mints/submit-mints',
      },
    },
    chain: {
      sendRawTransactions: '/v1/chain/send-raw-transactions',
    },
  },
}
