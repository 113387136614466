import { styled } from 'styled-components'
import { useMemo } from 'react'

import { RuneDetails, RuneHolder } from '@packages/interfaces'
import { API_ENDPOINTS } from '@packages/constants'
import { normalizePercent } from '@packages/utils'

import { ProgressBar, VirtualizedTable } from 'src/shared/components'
import { formatRunesAmount } from 'src/runes'
import { formatShortAddress } from 'src/wallet'
import { AccountDetailsLink } from 'src/account'
import { replaceUrlParams } from 'src/shared/utils'
import { useIsMobile } from 'src/shared/hooks'
import { useUpdateOnBlockPaginationApi } from 'src/api'

function formatHolderPercentage(percent: number): string {
  if (percent < 0.001) {
    return '<0.001'
  }
  return percent.toFixed(3)
}

interface Props {
  runeDetails: RuneDetails
}

export function RuneHoldersTable({ runeDetails }: Props) {
  const isMobile = useIsMobile()
  const {
    paginatedData: runeHolders,
    fetchPage,
    newPageLoading,
    hasNextPage,
  } = useUpdateOnBlockPaginationApi<RuneHolder>({
    endpoint: replaceUrlParams(API_ENDPOINTS.GET.runes.balances.byRune, {
      runeId: runeDetails.runeId,
    }),
    limit: isMobile ? 25 : 40,
  })

  const normalizeHolderPercent = useMemo(() => {
    const maxHolderPercent = runeHolders
      .flat()
      .reduce((max, holder) => Math.max(max, Number(holder.holderPercent)), 0)
    const minHolderPercent = runeHolders
      .flat()
      .reduce((min, holder) => Math.min(min, Number(holder.holderPercent)), 100)

    return normalizePercent(minHolderPercent, maxHolderPercent)
  }, [runeHolders])

  return (
    <Container>
      <VirtualizedTable
        columns={[
          {
            dataKey: 'rank',
            label: 'Rank',
            width: isMobile ? 12 : 5,
            hideOnMobile: true,
            formatter: ({ index }) => <Rank>#{index + 1}</Rank>,
          },
          {
            dataKey: 'address',
            label: 'Address',
            width: isMobile ? 30 : 20,
            formatter: ({ data: holder, index }) => (
              <AccountDetailsLink
                address={holder.address}
                displayName={
                  isMobile
                    ? `#${index + 1} ${formatShortAddress(holder.address)}`
                    : formatShortAddress(holder.address)
                }
              />
            ),
          },
          {
            dataKey: 'holderPercent',
            label: 'Percent',
            width: isMobile ? 20 : 40,
            formatter: ({ data: holder }) => {
              const holderPercent = Number(holder.holderPercent)
              return (
                <HolderPercentage>
                  <PercentageText>{formatHolderPercentage(holderPercent)}%</PercentageText>{' '}
                  {!isMobile && (
                    <PercentageProgressBar progress={normalizeHolderPercent(holderPercent)} />
                  )}
                </HolderPercentage>
              )
            },
          },
          {
            dataKey: 'runesAmount',
            label: 'Amount',
            width: 30,
            formatter: ({ data: holder }) =>
              formatRunesAmount({
                rune: runeDetails,
                runesAmount: holder.runesAmount,
                displayShortFormat: isMobile,
              }),
          },
        ]}
        paginatedData={runeHolders}
        fetchPage={fetchPage}
        loading={newPageLoading}
        hasNextPage={hasNextPage}
        rowHeight={isMobile ? 35 : 40}
        viewableRows={25}
      />
    </Container>
  )
}

const Container = styled.div``

const Rank = styled.div`
  font-weight: 700;
`

const HolderPercentage = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
`

const PercentageText = styled.div`
  flex: 1;
`

const PercentageProgressBar = styled(ProgressBar)`
  flex: 4;
`
