import { styled } from 'styled-components'

import { COLORS } from '../constants'

interface Props {
  className?: string
  children: React.ReactNode
}

export function Badge({ children, className }: Props) {
  return <BadgeContainer className={className}>{children}</BadgeContainer>
}

const BadgeContainer = styled.div`
  background-color: ${COLORS.black};
  width: fit-content;
  padding: 3px 5px;
  border-radius: 5px;
  max-height: 32px;
  color: ${COLORS.white};
  font-weight: 500;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`
