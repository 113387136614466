import { css, styled } from 'styled-components'

import { COLORS } from '../constants'
import { BUTTON_HOVER_STYLES } from './buttons'

interface Props {
  className?: string
  children: React.ReactNode
  onClick?: () => void
}

export function Card({ onClick, className, children }: Props) {
  return (
    <Container className={className} onClick={onClick}>
      {children}
    </Container>
  )
}

const Container = styled.div`
  border-radius: 16px;
  padding: 15px;
  background-color: ${COLORS.background.container};
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 285px;
  ${(props) =>
    props.onClick &&
    css`
      ${BUTTON_HOVER_STYLES}
    `}
`
