export function hexToBase64(hexString: string) {
  return btoa(
    hexString
      .match(/\w{2}/g)
      ?.map(function (a) {
        return String.fromCharCode(parseInt(a, 16))
      })
      .join('') ?? '',
  )
}

export function base64ToHex(base64Str: string) {
  const raw = atob(base64Str)
  let result = ''
  for (let i = 0; i < raw.length; i++) {
    const hex = raw.charCodeAt(i).toString(16)
    result += hex.length === 2 ? hex : '0' + hex
  }
  return result.toUpperCase()
}

export function decodeBase64(encodedString: string) {
  return atob(encodedString)
}
