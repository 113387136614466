import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'

import { App } from './App.tsx'
import GlobalStyles from './globalStyles'
import { API_URL, ENVIRONMENT, SENTRY } from './settings.ts'

if (ENVIRONMENT === 'production') {
  Sentry.init({
    dsn: 'https://f2e04aeedc054545ad2a1a7865fb06f6@o4507058105614336.ingest.us.sentry.io/4507125793357824',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    environment: ENVIRONMENT,
    // Performance Monitoring
    tracesSampleRate: SENTRY.tracesSampleRate,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', API_URL],
    // Session Replay
    replaysSessionSampleRate: SENTRY.replaysSessionSampleRate,
    replaysOnErrorSampleRate: SENTRY.replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <GlobalStyles />
    <App />
  </React.StrictMode>,
)
