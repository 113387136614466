import { styled } from 'styled-components'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import { useMemo } from 'react'

import { Rune, RuneOutpointDetails } from '@packages/interfaces'
import { API_ENDPOINTS } from '@packages/constants'

import { useUpdateOnBlockPaginationApi } from 'src/api'
import { useWalletContext } from 'src/wallet'
import { hexToRgb, replaceUrlParams } from 'src/shared/utils'
import { BUTTON_HOVER_STYLES, Badge, VirtualizedTable } from 'src/shared/components'
import { useDebounce, useIsMobile } from 'src/shared/hooks'
import { COLORS, FONTS } from 'src/shared/constants'
import { RuneOutpointExternalLink, formatRunesAmount } from 'src/runes'

import { useOrderContext } from '../OrderContext'

function findSelectedOutpointsNotInList(
  outpoints: RuneOutpointDetails[][],
  selectedOutpoints: RuneOutpointDetails[]
) {
  const outpointsSet = new Set(outpoints.flat().map((outpoint) => outpoint.outpointId))
  const uniqueSelectedOutpoints = selectedOutpoints.filter(
    (outpoint) => !outpointsSet.has(outpoint.outpointId) && !outpoint.orderId
  )

  return uniqueSelectedOutpoints
}

function findOutpointsWithOrders(outpoints: RuneOutpointDetails[][]) {
  return outpoints.flat().filter((outpoint) => !!outpoint.orderId)
}

const QUERY_PARAMS = { markOrderOutpoints: true }

interface Props {
  rune?: Rune
}

export function RuneOutpointsTable({ rune }: Props) {
  const {
    selectedRuneOutpoints,
    setSelectedRuneOutpoints,
    setDesiredRunesSellAmount,
    setIsBuying,
  } = useOrderContext()
  const isMobile = useIsMobile()
  const { runesAddress } = useWalletContext()

  const {
    paginatedData: runeOutpoints,
    fetchPage,
    newPageLoading,
    hasNextPage,
    forceRefresh,
  } = useUpdateOnBlockPaginationApi<RuneOutpointDetails>(
    {
      disabled: !rune || !runesAddress,
      endpoint: replaceUrlParams(API_ENDPOINTS.GET.runes.outpoints.runesOutpointsForAccount, {
        runeId: rune?.runeId ?? '',
        address: runesAddress?.addrString ?? '',
      }),
      limit: isMobile ? 50 : 100,
      otherQueryParams: QUERY_PARAMS,
    },
    true
  )

  useDebounce(
    async () => {
      if (selectedRuneOutpoints.length === 0) {
        forceRefresh()
      }
    },
    [selectedRuneOutpoints],
    1000
  )

  const sortedRuneOutpoints = useMemo(() => {
    return [
      findOutpointsWithOrders(runeOutpoints),
      findSelectedOutpointsNotInList(runeOutpoints, selectedRuneOutpoints),
      runeOutpoints.flat().filter((outpoint) => outpoint.orderId === undefined),
    ]
  }, [runeOutpoints, selectedRuneOutpoints])

  // function removeOutpoint(outpoint: RuneOutpointDetails) {
  //   let runeTotal = 0n
  //   setSelectedRuneOutpoints((prev) =>
  //     prev.filter((selected) => {
  //       if (selected.outpointId !== outpoint.outpointId) {
  //         runeTotal += selected.amount
  //         return true
  //       }

  //       return false
  //     })
  //   )
  //   setDesiredRunesSellAmount(runeTotal)
  // }

  // function addOutpoint(outpoint: RuneOutpointDetails) {
  //   let runeTotal = 0n
  //   setSelectedRuneOutpoints((prev) => {
  //     const newOutpoints = [...prev, outpoint]
  //     runeTotal = newOutpoints.reduce((acc, outpoint) => acc + outpoint.amount, 0n)
  //     return newOutpoints
  //   })
  //   setDesiredRunesSellAmount(runeTotal)
  // }

  function removeOutpoint(outpoint: RuneOutpointDetails) {
    const runeAmounts: bigint[] = []
    setIsBuying(false)
    setSelectedRuneOutpoints((prev) =>
      prev.filter((selected) => {
        if (selected.outpointId !== outpoint.outpointId) {
          runeAmounts.push(selected.amount)
          return true
        }
        return false
      })
    )
    setDesiredRunesSellAmount(runeAmounts.reduce((acc, outpoint) => acc + outpoint, 0n))
  }

  function addOutpoint(outpoint: RuneOutpointDetails) {
    setIsBuying(false)
    setSelectedRuneOutpoints((prev) => {
      const newOutpoints = [...prev, outpoint]
      const runeAmounts = newOutpoints.reduce((acc, outpoint) => acc + outpoint.amount, 0n)
      setDesiredRunesSellAmount(runeAmounts)
      return newOutpoints
    })
  }

  if (!rune) {
    return
  }

  return (
    <Container>
      <VirtualizedTable
        columns={[
          {
            dataKey: 'selectButton',
            label: 'Sell',
            width: 15,
            disableSort: true,
            formatter: ({ data: runeOutpoint }) => {
              if (runeOutpoint.orderId) {
                return <InOrderBadge>In Order</InOrderBadge>
              }

              return (
                <SelectOutpointButton>
                  {selectedRuneOutpoints.find(
                    (selected) => selected.outpointId === runeOutpoint.outpointId
                  ) ? (
                    <AddCircleRoundedIcon onClick={() => removeOutpoint(runeOutpoint)} />
                  ) : (
                    <AddCircleOutlineRoundedIcon onClick={() => addOutpoint(runeOutpoint)} />
                  )}
                </SelectOutpointButton>
              )
            },
          },
          {
            dataKey: 'runesAmount',
            label: isMobile ? 'Qty' : 'Quantity',
            formatter: ({ data: runeOutpoint }) => (
              <Quantity disabled={!!runeOutpoint.orderId}>
                {formatRunesAmount({
                  runesAmount: runeOutpoint.amount,
                  rune,
                })}
              </Quantity>
            ),
          },
          {
            dataKey: 'outpointId',
            label: 'ID',
            formatter: ({ data: runeOutpoint }) => (
              <RuneOutpointExternalLink runeOutpoint={runeOutpoint} />
            ),
          },
        ]}
        paginatedData={sortedRuneOutpoints}
        fetchPage={fetchPage}
        loading={newPageLoading}
        hasNextPage={hasNextPage}
        rowHeight={isMobile ? 30 : 45}
        viewableRows={25}
        headerShown={false}
      />
    </Container>
  )
}

const Container = styled.div``

const SelectOutpointButton = styled.div`
  ${BUTTON_HOVER_STYLES}
  width: fit-content;
  height: fit-content;
  svg {
    height: 28px;
    width: 28px;
  }
`

const Quantity = styled.div<{ disabled?: boolean }>`
  font-weight: 700 !important;
  color: ${({ disabled }) => (disabled ? hexToRgb(COLORS.white, 0.6) : COLORS.white)} !important;
`

const InOrderBadge = styled(Badge)`
  border: 1px solid ${hexToRgb(COLORS.white, 0.6)};
  font-family: ${FONTS.text};
  text-transform: uppercase;
  font-size: 12px !important;
  border-radius: 5px !important;
`
