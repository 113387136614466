import styled from 'styled-components'

import { Rune } from '@packages/interfaces'

import { hexToRgb } from 'src/shared/utils'
import { COLORS } from 'src/shared/constants'

import { formatRunesAmount } from '../utils'
import { RuneSymbolDisplay } from './RuneSymbolDisplay'

interface Props {
  rune?: Rune
  runesAmount?: bigint
  showPriceSymbol?: boolean
  color?: string
  shortFormat?: boolean
}
export function RunesAmountDisplay({
  rune,
  runesAmount,
  color,
  shortFormat,
  showPriceSymbol = true,
}: Props) {
  return (
    <>
      {showPriceSymbol && (
        <>
          <RuneSymbolDisplayWrapper runeSymbol={rune?.runeSymbolChar} color={color} />{' '}
        </>
      )}
      <RunesAmount>
        {rune && runesAmount
          ? formatRunesAmount({
              rune,
              runesAmount,
              displayShortFormat: shortFormat,
            })
          : 0}
      </RunesAmount>
    </>
  )
}

const RuneSymbolDisplayWrapper = styled(RuneSymbolDisplay)`
  color: ${({ color }) => hexToRgb(color ?? COLORS.white, 0.6)};
`

const RunesAmount = styled.span`
  font-weight: 700;
`
