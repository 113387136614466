import { useEffect, useRef } from 'react'

import { OkxBitcoinProvider } from '../providers'

export function useOkxProvider() {
  const provider = useRef(new OkxBitcoinProvider())

  useEffect(() => {
    const intervalID = setInterval(() => {
      if (provider.current.isAvailable()) {
        clearInterval(intervalID)
      }
    }, 500)

    return clearInterval(intervalID)
  }, [])

  return provider.current.isAvailable() ? provider.current : undefined
}
