import mempoolJS from '@mempool/mempool.js'
import { MempoolReturn } from '@mempool/mempool.js/lib/interfaces/index'

export const MEMPOOL_URLS: { [key in BtcNetwork]: string } = {
  mainnet: 'https://mempool.space',
  testnet: 'https://mempool.space/testnet',
  signet: 'https://mempool.space/signet',
}

export type BtcNetwork = 'signet' | 'testnet' | 'mainnet'

export function getMempoolUrls(network: BtcNetwork) {
  return {
    block: (blockHeightOrHash: number | string) => getMempoolBlockUrl(blockHeightOrHash, network),
    tx: (txId: string) => getMempoolTxUrl(txId, network),
    utxo: (txId: string, vout: number) => `${getMempoolTxUrl(txId, network)}#vout=${vout}`,
    address: (address: string) => getMempoolAddressUrl(address, network),
    price: () => 'https://mempool.space/api/v1/prices',
  }
}

export function getMempoolBlockUrl(blockHeightOrHash: number | string, network: BtcNetwork) {
  return `${MEMPOOL_URLS[network]}/block/${blockHeightOrHash}`
}

export function getMempoolTxUrl(txId: string, network: BtcNetwork) {
  return `${MEMPOOL_URLS[network]}/tx/${txId}`
}

export function getMempoolAddressUrl(address: string, network: BtcNetwork) {
  return `${MEMPOOL_URLS[network]}/address/${address}`
}

export function getMempoolApiInstance(network: BtcNetwork): MempoolReturn {
  return mempoolJS({
    hostname: 'mempool.space',
    network,
  })
}
