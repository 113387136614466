import _Modal from '@mui/material/Modal'
import { createGlobalStyle, styled } from 'styled-components'

import { BREAKPOINTS, COLORS } from '../constants'
import { CloseButton } from './buttons'
import { HeaderText } from './Header'
import { hexToRgb } from '../utils'

export type MODAL_POSITION = 'center' | 'top'

interface Props {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  className?: string
  width?: number
  title?: string
  position?: MODAL_POSITION
}

export function Modal({
  isOpen,
  onClose,
  title,
  children,
  width,
  position = 'center',
  className,
}: Props) {
  return (
    <>
      <GlobalModalStyles />
      <ModalWrapper open={isOpen} onClose={onClose} className={className}>
        <Content width={width ?? 450} position={position}>
          <Header>
            <Title>{title}</Title>
            <CloseButtonWrapper onClose={onClose} />
          </Header>

          {children}
        </Content>
      </ModalWrapper>
    </>
  )
}

const GlobalModalStyles = createGlobalStyle`
  .MuiModal-root {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
`

const ModalWrapper = styled(_Modal)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const CloseButtonWrapper = styled(CloseButton)`
  align-self: end;
  img {
    height: 30px;
    width: 30px;
  }
  @media (max-width: ${BREAKPOINTS.medium}) {
    img {
      height: 25px;
    }
  }
`

const Title = styled(HeaderText)`
  font-size: 20px;
  color: ${hexToRgb(COLORS.white, 0.75)};
`

const Content = styled.div<{ width: number; position: MODAL_POSITION }>`
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  @keyframes moveUp {
    0% {
      top: 50%;
      transform: translateY(-50%);
    }
    100% {
      top: 5%;
      transform: none;
    }
  }

  ${(props) =>
    props.position === 'top'
      ? 'animation: moveUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;'
      : ''}

  overflow: hidden;
  background-color: ${COLORS.background.primary};
  border: 1px solid ${COLORS.white};
  border-radius: 15px;
  padding: 20px;
  width: ${(props) => props.width}px;
  max-height: 90vh;
  height: fit-content;

  @media (max-width: ${BREAKPOINTS.small}) {
    width: 90%;
  }
`
