import styled from 'styled-components'

import { formatNumber, parseToFloat } from '@packages/utils'

import { hexToRgb } from 'src/shared/utils'
import { COLORS } from 'src/shared/constants'

import { formatPriceSymbol } from '../utils'

interface Props {
  priceSats?: number | string
  runeSymbol?: string
  showPriceSymbol?: boolean
  color?: string
}
export function PriceSatsDisplay({ priceSats, runeSymbol, showPriceSymbol = true, color }: Props) {
  const symbolColor = color || COLORS.white

  return (
    <>
      {formatNumber({
        numStr: `${typeof priceSats === 'string' ? parseToFloat(priceSats) : priceSats ?? 0}`,
        showAllDecimals: true,
      })}{' '}
      {showPriceSymbol && (
        <Container color={symbolColor}>{formatPriceSymbol({ runeSymbol })}</Container>
      )}
    </>
  )
}

const Container = styled.span<{ color: string }>`
  color: ${({ color }) => hexToRgb(color, 0.6)};
`
