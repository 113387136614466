import styled from 'styled-components'

import { formatUsdPrice, priceSatsToPriceUsd } from '@packages/utils'

import { hexToRgb } from 'src/shared/utils'
import { COLORS } from 'src/shared/constants'
import { useWalletContext } from 'src/wallet'

interface Props {
  priceUsd?: number | string
  priceSats?: number | string
  color?: string
}

export function PriceUsdDisplay({ priceUsd, priceSats, color }: Props) {
  const { btcPrice } = useWalletContext()
  const symbolColor = color || COLORS.white
  const price = priceUsd
    ? formatUsdPrice({ price: priceUsd })
    : priceSatsToPriceUsd({
        priceSats: priceSats ?? '0',
        satPriceUsd: btcPrice?.satPriceUsd ?? 0,
        format: true,
      })

  return <Container color={symbolColor}>{price}</Container>
}

const Container = styled.span<{ color: string }>`
  color: ${({ color }) => hexToRgb(color, 0.6)};
`
