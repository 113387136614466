import { styled } from 'styled-components'
import { useEffect, useState } from 'react'

import { formatUsd } from '@packages/utils'
import { API_ENDPOINTS } from '@packages/constants'
import { Order, OrderStatus } from '@packages/interfaces'

import {
  Badge,
  Button,
  HelpTooltip,
  TieredTableCell,
  VirtualizedTable,
} from 'src/shared/components'
import { replaceUrlParams, unixTimestampToDateString } from 'src/shared/utils'
import { RunesAmountDisplay } from 'src/runes'
import { useUpdateOnBlockPaginationApi } from 'src/api'
import { BREAKPOINTS, COLORS, FONTS } from 'src/shared/constants'
import { useIsMobile } from 'src/shared/hooks'
import { MarketDetailsLink } from 'src/markets'
import { TransactionExternalLink } from 'src/web3'

import { useOrderContext } from '../OrderContext'
import { BtcAmountDisplay } from './BtcAmountDisplay'

const status: OrderStatus = 'Placed'

interface Props {
  address: string
  runeId?: string
}

export function OpenOrdersByAddress({ address, runeId }: Props) {
  const {
    isStaleOrderDataTimestamp,
    setSelectedCancelOrder,
    openCancelDrawer,
    recentlyCancelledOrders,
  } = useOrderContext()
  const isMobile = useIsMobile()
  const [filteredOrders, setFilteredOrders] = useState<Order[][]>([])

  const {
    paginatedData: openOrders,
    loading,
    fetchPage,
    hasNextPage,
    forceRefresh,
  } = useUpdateOnBlockPaginationApi<Order>({
    endpoint: runeId
      ? `${replaceUrlParams(API_ENDPOINTS.GET.runes.orders.byRuneForAccount, {
          address,
          runeId,
        })}?status=${status}`
      : `${replaceUrlParams(API_ENDPOINTS.GET.runes.orders.byAccount, {
          address,
        })}?status=${status}`,
  })

  useEffect(() => {
    if (isStaleOrderDataTimestamp) {
      forceRefresh()
    }
  }, [isStaleOrderDataTimestamp])

  useEffect(() => {
    let orders = openOrders

    orders = orders.map((nestedOrders) => {
      return nestedOrders.filter((order) => {
        // Check if order was recently cancelled
        if (recentlyCancelledOrders.some((cancelledOrder) => cancelledOrder.id === order.id)) {
          return false
        }

        return true
      })
    })

    setFilteredOrders(orders)
  }, [openOrders, recentlyCancelledOrders])

  const hideRuneName = !!runeId // do not need to show runeName if on the market details page

  return (
    <Container>
      <VirtualizedTable
        columns={[
          // {
          //   dataKey: 'id',
          //   label: 'ID',
          //   width: 5,
          //   hideOnMobile: true,
          // },
          ...(hideRuneName
            ? []
            : [
                {
                  dataKey: 'runeName',
                  label: 'Name',
                  width: 25,
                  formatter: ({ data: order }: { data: Order }) => (
                    <MarketDetailsLink rune={order} />
                  ),
                },
              ]),
          {
            dataKey: 'priceSats',
            width: isMobile ? 30 : 20,
            label: 'Price (sats)',
            hideOnMobile: !hideRuneName,
            formatter: ({ data: order }) => (
              <TieredTableCell header={order.priceSats} subHeader={order.priceUsd} />
            ),
          },
          {
            dataKey: 'runesAmount',
            width: isMobile ? 30 : 20,
            label: isMobile ? 'Qty' : 'Quantity',
            formatter: ({ data: order }) => (
              <RunesAmountDisplay
                rune={order}
                runesAmount={order.runesAmount}
                shortFormat={isMobile}
              />
            ),
          },
          {
            dataKey: 'satsAmount',
            label: 'Total',
            width: isMobile ? 25 : 20,
            formatter: ({ data: order }) => (
              <TieredTableCell
                header={<BtcAmountDisplay btcAmount={order.satsAmount} showPriceSymbol />}
                subHeader={formatUsd({ usd: order.valueUsd, shortFormat: isMobile })}
              />
            ),
          },
          {
            dataKey: 'placedAtTimestamp',
            label: 'Placed',
            width: 15,
            hideOnMobile: true,
            content: (
              <ThContainer>
                Placed{' '}
                <HelpTooltip
                  content={
                    'If applicable, this column links to the tx used to split or combine your runes in preparation for your sell order.'
                  }
                  icon='info'
                />
              </ThContainer>
            ),
            formatter: ({ data: order }) => {
              if (order.boxTransactionId) {
                return (
                  <TransactionExternalLink transactionId={order.boxTransactionId}>
                    {unixTimestampToDateString(order.placedAtTimestamp, true)}
                  </TransactionExternalLink>
                )
              } else {
                return unixTimestampToDateString(order.placedAtTimestamp, true)
              }
            },
          },
          {
            dataKey: 'isActive',
            label: 'Status',
            width: 15,
            hideOnMobile: true,
            formatter: ({ data: order }) => {
              if (order.isActive) {
                return <BadgeWrapper color={COLORS.positive}>Active</BadgeWrapper>
              } else if (order.boxTransactionId) {
                return <BadgeWrapper color={COLORS.warning}>PENDING</BadgeWrapper>
              }
            },
          },
          {
            dataKey: 'cancel',
            label: '',
            width: isMobile ? 15 : 20,
            disableSort: true,
            formatter: ({ data: order }) => {
              function onClick() {
                setSelectedCancelOrder(order)
                openCancelDrawer()
              }
              if (order.isActive) {
                if (isMobile) {
                  return <CancelButton onClick={onClick}>X</CancelButton>
                }
                return <CancelButton onClick={onClick}>Cancel</CancelButton>
              } else {
                return null
              }
            },
          },
        ]}
        paginatedData={filteredOrders}
        fetchPage={fetchPage}
        loading={loading}
        hasNextPage={hasNextPage}
        rowHeight={45}
        viewableRows={25}
        emptyDataMessage='No open orders'
      />
    </Container>
  )
}

const Container = styled.div``

const CancelButton = styled(Button)`
  background-color: ${COLORS.negative};
  color: ${COLORS.white};
  @media (max-width: ${BREAKPOINTS.medium}) {
    height: 25px;
    width: 25px;
    font-weight: 700;
    font-size: 11px;
  }
`

const BadgeWrapper = styled(Badge)<{ color: string }>`
  background-color: ${(props) => props.color} !important;
  border-radius: 5px !important;
  text-transform: uppercase;
  font-family: ${FONTS.text};
`

const ThContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    height: 15px;
  }
`
