export function formatPercent(percentage: number): string {
  if (percentage >= 1) {
    return `${percentage.toFixed(2)}%`
  } else if (percentage >= 0.001) {
    return `${percentage.toFixed(3)}%`
  } else if (percentage > 0) {
    return '<0.001%'
  } else {
    return '0%'
  }
}
