import { AccountDetailsPageContent } from 'src/account'

import { PageContainer } from './PageContainer'

export function AccountDetailsPage() {
  return (
    <PageContainer>
      <AccountDetailsPageContent />
    </PageContainer>
  )
}
