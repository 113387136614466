import * as React from 'react'
import { styled } from '@mui/system'
import { Badge as BaseBadge, badgeClasses } from '@mui/base/Badge'

import { BREAKPOINTS, COLORS, FONTS } from '../constants'

interface Props {
  count: number
  children: React.ReactNode
  className?: string
}

export function IconWithCount({ count, children, className }: Props) {
  return (
    <Badge badgeContent={count} className={className}>
      {children}
    </Badge>
  )
}

const Badge = styled(BaseBadge)`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 16px;
  list-style: none;
  font-family: ${FONTS.text};
  position: relative;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 3px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding: 0px;
  }

  & .${badgeClasses.badge} {
    z-index: auto;
    position: absolute;
    top: 0;
    right: 0;
    min-width: 22px;
    height: 22px;
    padding: 0 6px;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    white-space: nowrap;
    text-align: center;
    border-radius: 12px;
    background: ${COLORS.buttons.primary};
    transform: translate(50%, -50%);
    transform-origin: 100% 0;
  }

  & .${badgeClasses.invisible} {
    display: none;
  }
`
