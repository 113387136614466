import { FormControlLabel } from '@mui/material'
import _Switch from '@mui/material/Switch'
import { styled } from 'styled-components'

import { COLORS, FONTS } from 'src/shared/constants'
import { hexToRgb } from 'src/shared/utils'

interface Props {
  onChange: (checked: boolean) => void
  checked: boolean
  disabled?: boolean
  label?: string
  className?: string
}

export function Switch({ disabled, checked, onChange, label, className }: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked)
  }

  if (label) {
    return (
      <Container className={className}>
        <FormControlLabel
          control={<SwitchWrapper disabled={disabled} checked={checked} onChange={handleChange} />}
          label={label}
        />
      </Container>
    )
  }

  return (
    <Container className={className}>
      <SwitchWrapper disabled={disabled} checked={checked} onChange={handleChange} />
    </Container>
  )
}

const Container = styled.div`
  font-family: ${FONTS.text};
  .MuiSwitch-thumb {
    background-color: ${hexToRgb(COLORS.white, 0.6)};
  }
  .Mui-checked {
    .MuiSwitch-thumb {
      background-color: ${COLORS.white};
    }
  }

  .MuiSwitch-track {
    background-color: ${hexToRgb(COLORS.buttons.primary, 0.4)};
  }
  .Mui-checked + .MuiSwitch-track {
    background-color: ${COLORS.buttons.primary};
  }
`

const SwitchWrapper = styled(_Switch)``
