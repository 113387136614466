import LinearProgress from '@mui/material/LinearProgress'
import { styled } from 'styled-components'

import { COLORS } from '../constants'
import { GRADIENT_BG_CSS } from '../styles'

interface Props {
  progress: number
  className?: string
  showGradient?: boolean
}

export function ProgressBar({ progress, className, showGradient = false }: Props) {
  return (
    <ProgressWrapper
      variant='determinate'
      value={progress}
      className={className}
      $showGradient={showGradient}
    />
  )
}

const ProgressWrapper = styled(LinearProgress)<{ $showGradient: boolean }>`
  width: 100%;
  height: 18px;
  border-radius: 100px;
  background-color: ${COLORS.background.container};
  span {
    border-radius: 100px;
    background-color: ${COLORS.white};

    ${(props) => props.$showGradient && GRADIENT_BG_CSS}
  }
`
