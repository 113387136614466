import styled from 'styled-components'

import { RuneBalance } from '@packages/interfaces'
import { API_ENDPOINTS } from '@packages/constants'

import { replaceUrlParams } from 'src/shared/utils'
import { VirtualizedTable } from 'src/shared/components'
import { useUpdateOnBlockPaginationApi } from 'src/api'
import { MarketDetailsButton } from 'src/markets'
import { BREAKPOINTS } from 'src/shared/constants'
import { useIsMobile } from 'src/shared/hooks'

import { RuneDetailsLink } from './RuneDetailsLink'
import { formatRunesAmount } from '../utils'
import { TransferRunesButton } from './TransferRunesButton'

interface Props {
  address: string
}

export function RuneBalancesTable({ address }: Props) {
  const isMobile = useIsMobile()
  const {
    paginatedData: runeBalances,
    fetchPage,
    newPageLoading,
    hasNextPage,
  } = useUpdateOnBlockPaginationApi<RuneBalance>({
    endpoint: replaceUrlParams(API_ENDPOINTS.GET.runes.balances.byAccount, { address }),
    limit: isMobile ? 20 : 40,
  })

  return (
    <Container>
      <VirtualizedTable
        columns={[
          {
            dataKey: 'symbol',
            label: 'Token',
            width: isMobile ? 35 : 30,
            formatter: ({ data: balanceRecord }) => <RuneDetailsLink rune={balanceRecord} />,
          },
          {
            dataKey: 'runesAmount',
            label: 'Amount',
            width: isMobile ? 15 : 20,
            formatter: ({ data: balanceRecord }) =>
              formatRunesAmount({
                rune: balanceRecord,
                runesAmount: balanceRecord.runesAmount,
                displayShortFormat: isMobile,
              }),
          },
          {
            dataKey: 'actions',
            label: '',
            width: 45,
            disableSort: true,
            formatter: ({ data: balanceRecord }) => (
              <ButtonsContainer>
                <TransferButton balance={balanceRecord} />
                <TradeButton runeName={balanceRecord.runeName} />
              </ButtonsContainer>
            ),
          },
        ]}
        paginatedData={runeBalances}
        fetchPage={fetchPage}
        loading={newPageLoading}
        hasNextPage={hasNextPage}
        viewableRows={20}
        rowHeight={50}
      />
    </Container>
  )
}

const Container = styled.div``

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`

const TransferButton = styled(TransferRunesButton)`
  @media (max-width: ${BREAKPOINTS.medium}) {
    height: 25px;
    font-size: 10px;
  }
`

const TradeButton = styled(MarketDetailsButton)`
  @media (max-width: ${BREAKPOINTS.medium}) {
    height: 25px;
    font-size: 10px;
  }
  @media (max-width: ${BREAKPOINTS.tiny}) {
    display: none;
  }
`
