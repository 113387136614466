import { useEffect, useMemo, useState } from 'react'
import { styled } from 'styled-components'

import { COLORS } from '../constants'
import { GRADIENT_BG_CSS } from '../styles'

interface PercentageConfig {
  key: string
  percentage: number
  color: string
  showGradient?: boolean
}

interface Props {
  percentages: PercentageConfig[]
}

export function MultiPartProgressBar({ percentages }: Props) {
  const filteredPercentages = useMemo(() => {
    return percentages.filter((item) => item.percentage >= 0.05)
  }, [percentages])

  // Starting values needed for the animation
  // Mapped by "visualParts" so it can work with multiple values dynamically
  // It's an array of percentage widths
  const [widths, setWidths] = useState(
    filteredPercentages.map(() => {
      return 0
    }),
  )

  useEffect(() => {
    // https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
    // You need to wrap it to trigger the animation
    requestAnimationFrame(() => {
      // Set a new array of percentage widths based on the props
      setWidths(
        filteredPercentages.map((item) => {
          return item.percentage
        }),
      )
    })
  }, [filteredPercentages])

  return (
    <Container>
      {filteredPercentages.map((item, index) => {
        // map each part into separate div and each will be animated
        // because of the "transition: width 2s;" css in class "progressVisualPart"
        // and because of the new width ("widths[index]", previous one was 0)

        let borderRadius = '100px'
        if (filteredPercentages.length === 1) {
          borderRadius = '100px'
        } else if (index === 0) {
          borderRadius = '100px 20px 20px 100px'
        } else if (index === filteredPercentages.length - 1) {
          borderRadius = '20px 100px 100px 20px'
        } else if (filteredPercentages.length > 2) {
          borderRadius = '0px'
        }

        return (
          <VisualPart
            // There won't be additional changes in the array so the index can be used
            /* eslint-disable-next-line react/no-array-index-key */
            key={item.key}
            $showGradient={item.showGradient}
            style={{
              width: `${widths[index]}%`,
              // setting the actual color of bar part
              backgroundColor: `${item.color}`,
              borderRadius,
            }}
          />
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  border-radius: 100px;
  width: 100%;
  height: 18px;
  background-color: ${COLORS.background.container};
`

const VisualPart = styled.div<{ $showGradient?: boolean }>`
  border-radius: 100px;
  transition: 0.7s ease-in-out;
  ${(props) => props.$showGradient && GRADIENT_BG_CSS}
`
