import { styled } from 'styled-components'
import { Skeleton } from '@mui/material'

import { RuneDetails } from '@packages/interfaces'
import { API_ENDPOINTS } from '@packages/constants'
import { bigIntToInteger, formatNumber, formatRuneName } from '@packages/utils'

import { HeaderText, Section } from 'src/shared/components'
import { BREAKPOINTS, COLORS } from 'src/shared/constants'
import { TransactionExternalLink } from 'src/web3'
import { hexToRgb, replaceUrlParams, unixTimestampToDateString } from 'src/shared/utils'
import { useApi } from 'src/api'
import { useWalletContext } from 'src/wallet'
import { MintProgressBar, useMintDetails } from 'src/minting'
import { useIsMobile } from 'src/shared/hooks'

import { RuneDetailsBalanceCard } from './RuneDetailsBalanceCard'
import { RuneHoldersTable } from './RuneHoldersTable'
import { formatRunesAmount } from '../utils'
import { RuneNameHeader } from './RuneNameHeader'

const BIG_INT_ZERO = 0n

interface Props {
  runeName: string
}

export function RuneDetailsPageContent({ runeName }: Props) {
  const isMobile = useIsMobile()
  const { runesAddress } = useWalletContext()
  const { data: runeDetails, loading } = useApi<RuneDetails>({
    endpoint: replaceUrlParams(API_ENDPOINTS.GET.runes.details, {
      runeName: runeName.toUpperCase(),
    }),
  })
  const mintDetails = useMintDetails({ runeDetails })

  if (loading) {
    return (
      <Container>
        <RuneName>
          <SkeletonWrapper variant='rounded' height={67} />
        </RuneName>
        <SkeletonWrapper variant='rounded' height={isMobile ? 103 : 60} />
        <InfoContainer>
          <OverviewSection title='OVERVIEW'>
            <SkeletonWrapper height={370} variant='rounded' />
          </OverviewSection>
          <TokenBalanceSection title={runeName}>
            <SkeletonWrapper height={78} variant='rounded' />
          </TokenBalanceSection>
        </InfoContainer>
      </Container>
    )
  }

  return (
    <Container>
      <RuneNameHeader runeDetails={runeDetails} />
      {mintDetails && runeDetails && (
        <MintProgressBar mintDetails={mintDetails} runeDetails={runeDetails} />
      )}
      <InfoContainer>
        <OverviewSection title='OVERVIEW'>
          {runeDetails && mintDetails && (
            <Details>
              <Column>
                <DetailDataItem>
                  <DetailHeader>ID</DetailHeader>
                  {runeDetails.runeId.toString()}
                </DetailDataItem>
                <DetailDataItem>
                  <DetailHeader>Decimals</DetailHeader>
                  {runeDetails.runeDecimals.toString()}
                </DetailDataItem>
                <DetailDataItem>
                  <DetailHeader>Turbo</DetailHeader>
                  {runeDetails.isTurbo ? 'True' : 'False'}
                </DetailDataItem>
                <DetailDataItem>
                  <DetailHeader>Etched At</DetailHeader>
                  <TransactionExternalLink transactionId={runeDetails.etchedTransactionId}>
                    {unixTimestampToDateString(runeDetails.etchedAtTimestamp, true)}
                  </TransactionExternalLink>
                </DetailDataItem>
              </Column>
              <Column>
                <DetailDataItem>
                  <DetailHeader>Holders</DetailHeader>
                  {runeDetails.holderCount?.toLocaleString()}
                </DetailDataItem>
                <DetailDataItem>
                  <DetailHeader>Current Supply</DetailHeader>
                  {formatRunesAmount({
                    rune: runeDetails,
                    runesAmount: runeDetails.currentSupply,
                    displayShortFormat: isMobile,
                  })}
                </DetailDataItem>
                <DetailDataItem>
                  <DetailHeader>Maximum Supply</DetailHeader>
                  {formatRunesAmount({
                    rune: runeDetails,
                    runesAmount: runeDetails.maximumSupply,
                    displayShortFormat: isMobile,
                  })}
                </DetailDataItem>
                <DetailDataItem>
                  <DetailHeader>Premined Amount</DetailHeader>
                  {formatRunesAmount({
                    rune: runeDetails,
                    runesAmount: runeDetails.amountPremined,
                    displayShortFormat: isMobile,
                  })}
                </DetailDataItem>
                {runeDetails.amountBurned > 0n && (
                  <DetailDataItem>
                    <DetailHeader>Burned Amount</DetailHeader>
                    {formatRunesAmount({
                      rune: runeDetails,
                      runesAmount: runeDetails.amountBurned,
                      displayShortFormat: isMobile,
                    })}
                  </DetailDataItem>
                )}
              </Column>

              <Column>
                {runeDetails.runeSymbolChar && (
                  <DetailDataItem>
                    <DetailHeader>Symbol</DetailHeader>
                    {runeDetails.runeSymbolChar}
                  </DetailDataItem>
                )}
                {runeDetails.isMintable ? (
                  <>
                    <DetailDataItem>
                      <DetailHeader>Minted Amount</DetailHeader>
                      {formatRunesAmount({
                        rune: runeDetails,
                        runesAmount:
                          runeDetails.numberOfMints *
                          (runeDetails.runesAmountPerMint ?? BIG_INT_ZERO),
                        displayShortFormat: isMobile,
                      })}
                    </DetailDataItem>
                    <DetailDataItem>
                      <DetailHeader>Runes Per Mint</DetailHeader>
                      {formatRunesAmount({
                        rune: runeDetails,
                        runesAmount: runeDetails.runesAmountPerMint ?? BIG_INT_ZERO,
                      })}
                    </DetailDataItem>
                    {runeDetails.maxMints && (
                      <DetailDataItem>
                        <DetailHeader>Max Mints</DetailHeader>
                        {formatNumber({
                          numStr: `${bigIntToInteger(runeDetails.maxMints)}`,
                          useCompactNotation: isMobile,
                        })}
                      </DetailDataItem>
                    )}
                    {runeDetails.mintOpenBlockHeight && (
                      <DetailDataItem>
                        <DetailHeader>Mint Starts At Block</DetailHeader>
                        {runeDetails.mintOpenBlockHeight.toString()}
                      </DetailDataItem>
                    )}
                    {runeDetails.mintCloseBlockHeight && (
                      <DetailDataItem>
                        <DetailHeader>
                          {mintDetails.mintState === 'Ended'
                            ? 'Mint Ended At Block'
                            : 'Mint Ends At Block'}
                        </DetailHeader>
                        {runeDetails.mintCloseBlockHeight.toString()}
                      </DetailDataItem>
                    )}
                  </>
                ) : (
                  <DetailDataItem>
                    <DetailHeader>Mintable</DetailHeader>
                    False
                  </DetailDataItem>
                )}
              </Column>
            </Details>
          )}
        </OverviewSection>
        {runeDetails && (
          <TokenBalanceSection title={formatRuneName(runeDetails)}>
            <RuneDetailsBalanceCard
              runeDetails={runeDetails}
              walletRunesAddress={runesAddress?.addrString}
            />
          </TokenBalanceSection>
        )}
      </InfoContainer>
      <TabbedContentContainer>
        {runeDetails && <RuneHoldersTable runeDetails={runeDetails} />}
      </TabbedContentContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const SkeletonWrapper = styled(Skeleton)`
  border-radius: 10px;
  background-color: ${COLORS.background.container};
`

const TabbedContentContainer = styled.div`
  padding-top: 20px;
`

const RuneName = styled(HeaderText)``

const InfoContainer = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;
  }
`

const OverviewSection = styled(Section)`
  display: flex;
  flex: 2;
`

const Details = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 20px 0px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 15px;
  gap: 20px;
  flex: 1;
`

const DetailDataItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const DetailHeader = styled.h3`
  font-weight: 300;
  font-size: 15px;
  color: ${hexToRgb(COLORS.white, 0.7)};
`

const TokenBalanceSection = styled(Section)`
  flex: 1;
  height: fit-content;
`
