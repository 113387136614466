import { API_ENDPOINTS, ORDERS_SORT_ORDER } from '@packages/constants'
import { Order, OrderStatus } from '@packages/interfaces'

import { useUpdateOnBlockPaginationApi } from 'src/api'
import { replaceUrlParams } from 'src/shared/utils'

export interface OrderStatusQueryParams {
  status: OrderStatus
  limit: number
  sortOrderBy: ORDERS_SORT_ORDER
  sortOrder: 'asc' | 'desc'
}

export function useOrdersByStatusApi<T extends Order>({
  runeName,
  queryParams,
  disabled,
}: {
  runeName: string
  queryParams: OrderStatusQueryParams
  disabled?: boolean
}) {
  const response = useUpdateOnBlockPaginationApi<T>({
    endpoint: `${replaceUrlParams(API_ENDPOINTS.GET.runes.orders.byStatus, {
      runeName: runeName.toUpperCase(),
    })}`,
    otherQueryParams: queryParams,
    disabled,
  })
  return response
}
