import Bottleneck from 'bottleneck'
import { Address } from '@mempool/mempool.js/lib/interfaces/bitcoin/addresses'

import { satsToBtc } from '@packages/utils'

import { mempoolApiInstance } from 'src/web3'

import { BtcBalances } from '../interfaces'

const BALANCE_THROTTLE_INTERVAL_MS = 1000 * 30

let limiter = new Bottleneck({
  maxConcurrent: 1,
  minTime: BALANCE_THROTTLE_INTERVAL_MS,
})

const {
  bitcoin: { addresses },
} = mempoolApiInstance

const wrapped = limiter.wrap(addresses.getAddress)

export async function getAddressBtcBalances({
  forceUpdate = false,
  address,
  setBtcBalances,
}: {
  forceUpdate?: boolean
  address: string
  setBtcBalances: (balances: BtcBalances) => void
}) {
  try {
    let response: Address
    if (forceUpdate) {
      await limiter.stop({ dropWaitingJobs: true })
      response = await addresses.getAddress({
        address,
      })
      // create a new limiter
      limiter = new Bottleneck({
        maxConcurrent: 1,
        minTime: BALANCE_THROTTLE_INTERVAL_MS,
      })
    } else {
      response = await wrapped({ address })
    }

    const data: {
      address: string
      chain_stats: {
        funded_txo_count: number
        funded_txo_sum: number
        spent_txo_count: number
        spent_txo_sum: number
        tx_count: number
      }
      mempool_stats: {
        funded_txo_count: number
        funded_txo_sum: number
        spent_txo_count: number
        spent_txo_sum: number
        tx_count: number
      }
    } = await response

    const mempoolBalanceSats = data.mempool_stats.funded_txo_sum - data.mempool_stats.spent_txo_sum
    const chainBalanceSats = data.chain_stats.funded_txo_sum - data.chain_stats.spent_txo_sum

    setBtcBalances({
      mempoolBalanceSats: BigInt(mempoolBalanceSats),
      mempoolBalanceBtc: satsToBtc(mempoolBalanceSats),
      chainBalanceSats: BigInt(chainBalanceSats),
      chainBalanceBtc: satsToBtc(chainBalanceSats),
    })
  } catch (error) {
    console.error('Failed to fetch BTC balances:', error)
  }
}
