import { styled } from 'styled-components'

import { BREAKPOINTS, COLORS } from '../constants'

interface Props {
  title: string
  children: React.ReactNode
  className?: string
}

export function Section({ title, children, className }: Props) {
  return (
    <Container className={className}>
      <Title>{title}</Title>
      {children}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  border-radius: 16px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${COLORS.white};
`

const Title = styled.h3`
  font-weight: 300;
  font-size: 25px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: 22px;
  }
`
