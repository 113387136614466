export interface Rune {
  runeId: string
  runeName: string
  runeSymbolChar?: string // ¤ if not specified
  runeNameSpacerIndices?: bigint[]
  runeDecimals: bigint
}

export const DEFAULT_RUNE_SYMBOL = '¤'

export const DEFAULT_PRICE_SYMBOL = `sats / ${DEFAULT_RUNE_SYMBOL}`
