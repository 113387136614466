const OKX_BTC_EXPLORER_BASE_URL = 'https://www.okx.com/web3/explorer/btc'

export const OKX_URLS = {
  block: (blockHeightOrHash: number | string) => getOkxBlockUrl(blockHeightOrHash),
  tx: (txId: string) => getOkxTxUrl(txId),
  address: (address: string) => getOkxAddressUrl(address),
}

export function getOkxBlockUrl(blockHeightOrHash: number | string) {
  return `${OKX_BTC_EXPLORER_BASE_URL}/block/${blockHeightOrHash}`
}

export function getOkxTxUrl(txId: string) {
  return `${OKX_BTC_EXPLORER_BASE_URL}/tx/${txId}/transfer`
}

export function getOkxAddressUrl(address: string) {
  return `${OKX_BTC_EXPLORER_BASE_URL}/address/${address}`
}
