import { useEffect, useState } from 'react'
import { InputAdornment, TextField } from '@mui/material'
import { styled } from 'styled-components'

import { hexToRgb } from '../../utils'
import { COLORS, FONTS, NUMBER_REGEX } from '../../constants'

interface Props {
  value?: number
  onChange: (val?: number) => void
  onBlur?: () => void
  disabled?: boolean
  error?: boolean
  className?: string
  helperText?: string
  placeholder?: string
  inputRef?: any
  endAdornment?: string
}

export function NumberInput({
  value,
  onChange,
  onBlur,
  disabled = false,
  error = false,
  helperText,
  className,
  placeholder,
  inputRef,
  endAdornment,
}: Props) {
  const [inputValue, setInputValue] = useState<string>(value?.toString() ?? '')

  useEffect(() => {
    if (value) {
      if (value.toString() !== inputValue) setInputValue(value.toString())
    }
  }, [value])

  function handleChange(event: any) {
    const strValue = event.target.value

    const startsWithDot = strValue.startsWith('.')
    const isEmptyString = strValue === ''
    if (isEmptyString || startsWithDot || NUMBER_REGEX.test(strValue)) {
      setInputValue(strValue)
      if (isEmptyString) {
        onChange(undefined)
      } else {
        onChange(parseFloat(startsWithDot ? `0${strValue}` : strValue))
      }
    }
  }

  return (
    <TextFieldWrapper
      value={inputValue}
      onChange={handleChange}
      onBlur={onBlur}
      disabled={disabled}
      error={error}
      helperText={helperText}
      className={className}
      inputRef={inputRef}
      placeholder={placeholder}
      InputProps={{
        ...(endAdornment && {
          endAdornment: <InputAdornment position='end'>{endAdornment}</InputAdornment>,
        }),
      }}
    />
  )
}

const TextFieldWrapper = styled(TextField)`
  p {
    line-height: 15px;
    padding: 10px 0px;
    margin: 0px;
  }

  border: none;

  &:disabled {
    color: ${hexToRgb(COLORS.white, 0.6)};
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${COLORS.white};
    border-radius: 8px;

    &:hover {
      border-color: ${hexToRgb(COLORS.white, 0.5)};
    }
  }

  .MuiInputLabel-root {
    border-radius: 8px;
    color: ${COLORS.black};
  }

  .MuiInputBase-input {
    border-radius: 8px;
    color: ${COLORS.black};
    background-color: ${COLORS.white};
    text-align: center;
    height: 11px;
    width: 45px;
  }

  .MuiFormHelperText-root {
    font-family: ${FONTS.text};
    font-size: 15px;
  }

  div {
    p {
      font-family: ${FONTS.text};
      font-weight: 500;
    }
  }
`
