import { styled } from 'styled-components'
import { useEffect, useState } from 'react'

import { Market, SortOrderParameters } from '@packages/interfaces'
import { API_ENDPOINTS, POPULAR_MINTS_SORT_ORDER, TIMEFRAMES } from '@packages/constants'
import { formatUsd, satsToBtc } from '@packages/utils'

import {
  VirtualizedTable,
  SmallButtonGroup,
  HeaderText,
  HeaderContainer,
  TieredTableCell,
} from 'src/shared/components'
import { useUpdateOnBlockPaginationApi } from 'src/api'
import { useIsMobile } from 'src/shared/hooks'
import { BREAKPOINTS, TIMEFRAME_LABELS } from 'src/shared/constants'
import { IS_TESTNET_DEPLOYMENT } from 'src/settings'
import { BtcAmountDisplay, PriceSatsDisplay, PriceUsdDisplay } from 'src/orders'

import { MarketDetailsLink } from './MarketDetailsLink'

const DEFAULT_TIMEFRAME = IS_TESTNET_DEPLOYMENT ? TIMEFRAME_LABELS['1h'] : TIMEFRAME_LABELS['24h']

const selectedTimeframeOptions = Object.values(TIMEFRAME_LABELS).map((timeframe) => ({
  value: `${timeframe.valueHours}`,
  label: timeframe.label,
}))

interface Props {
  className?: string
}

export function PopularMarketsTable({ className }: Props) {
  const [selectedTimeframe, setSelectedTimeframe] = useState(DEFAULT_TIMEFRAME)
  const [switchedTimeframe, setSwitchedTimeframe] = useState(false)
  const isMobile = useIsMobile()
  const [queryParams, setQueryParams] = useState<
    SortOrderParameters<POPULAR_MINTS_SORT_ORDER> & {
      timeframe: TIMEFRAMES
    }
  >({
    timeframe: '24h',
  })
  const {
    paginatedData: popularMarkets,
    fetchPage,
    newPageLoading,
    loading,
    hasNextPage,
  } = useUpdateOnBlockPaginationApi<Market>({
    endpoint: `${API_ENDPOINTS.GET.runes.markets.popular}`,
    limit: 100,
    otherQueryParams: queryParams,
  })

  useEffect(() => {
    if (switchedTimeframe && !loading) {
      setSwitchedTimeframe(false)
    }
  }, [loading, popularMarkets])

  function onTimeframeChange(value: string) {
    const selectedTimeframe = Object.values(TIMEFRAME_LABELS).find(
      (timeframe) => timeframe.valueHours === parseInt(value)
    )
    if (selectedTimeframe) {
      setSelectedTimeframe(selectedTimeframe)
      setQueryParams({
        ...queryParams,
        timeframe: selectedTimeframe.timeframe,
      })
      setSwitchedTimeframe(true)
    }
  }

  return (
    <Container className={className}>
      <HeaderContainer>
        <HeaderText $wordBreak='break-word'>Popular Markets</HeaderText>
        <FilteringOptions>
          <SmallButtonGroup
            options={selectedTimeframeOptions}
            value={selectedTimeframe.valueHours.toString()}
            onChange={onTimeframeChange}
            exclusive
          />
        </FilteringOptions>
      </HeaderContainer>

      <VirtualizedTable
        columns={[
          {
            dataKey: 'rank',
            label: 'Rank',
            width: 13,
            disableSort: true,
            hideOnMobile: true,
            formatter: ({ index }) => <Rank>#{index + 1}</Rank>,
          },
          {
            dataKey: 'runeName',
            label: 'Token',
            width: 40,
            formatter: ({ data: market }) => <MarketDetailsLink rune={market} />,
          },
          {
            dataKey: 'floorPriceSats',
            label: 'Price',
            width: 30,
            formatter: ({ data: market }) => (
              <TieredTableCell
                header={
                  <PriceSatsDisplay
                    priceSats={market.floorPriceSats}
                    runeSymbol={market.runeSymbolChar}
                  />
                }
                subHeader={<PriceUsdDisplay priceSats={market.floorPriceSats} />}
              />
            ),
          },
          {
            dataKey: 'volumeSats',
            label: 'Volume',
            width: 22,
            hideOnMobile: true,
            formatter: ({ data: market }) => (
              <TieredTableCell
                header={<BtcAmountDisplay btcAmount={market.volumeSats} showPriceSymbol />}
                subHeader={formatUsd({ usd: market.volumeUsd, shortFormat: true })}
              />
            ),
          },
          {
            dataKey: 'marketCapSats',
            label: 'Market Cap',
            width: 30,
            formatter: ({ data: market }) => (
              <TieredTableCell
                header={
                  <BtcAmountDisplay btcAmount={satsToBtc(market.marketCapSats)} showPriceSymbol />
                }
                subHeader={formatUsd({ usd: market.marketCapUsd, shortFormat: true })}
              />
            ),
          },
        ]}
        paginatedData={switchedTimeframe ? [] : popularMarkets}
        fetchPage={fetchPage}
        loading={newPageLoading || loading}
        hasNextPage={hasNextPage}
        rowHeight={isMobile ? 60 : 50}
        viewableRows={10}
        emptyDataMessage='No popular markets'
      />
    </Container>
  )
}

const Container = styled.div``

const Rank = styled.div`
  font-weight: 700;
`

const FilteringOptions = styled.div`
  display: flex;
  gap: 15px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column-reverse;
    align-items: flex-end;
    gap: 0px;
  }
`
