import CircularProgress from '@mui/material/CircularProgress'
import { styled } from 'styled-components'

import { COLORS } from '../constants'

export const LoadingIcon = styled(CircularProgress)`
  color: ${COLORS.white};
  width: 13px !important;
  height: 13px !important;
  animation-duration: 2.8s;

  svg {
    circle {
      animation-duration: 2.8s;
    }
  }
`
