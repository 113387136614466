import { useEffect, useState } from 'react'
import { BitcoinProvider } from 'sats-connect'

function getMagicEdenProvider() {
  if ('magicEden' in window) {
    const anyWindow: any = window
    if (anyWindow.magicEden.bitcoin && anyWindow.magicEden.bitcoin.isMagicEden) {
      return anyWindow.magicEden.bitcoin
    }
  }
}

export function useMagicEdenProvider() {
  const [wallet, setWallet] = useState<BitcoinProvider>()

  useEffect(() => {
    const magicEdenProvider = getMagicEdenProvider()
    if (magicEdenProvider) {
      setWallet(magicEdenProvider)
    }
  }, [])

  useEffect(() => {
    const intervalID = setInterval(() => {
      if (wallet) {
        clearInterval(intervalID)
      } else {
        const magicEdenProvider = getMagicEdenProvider()
        if (magicEdenProvider) {
          setWallet(magicEdenProvider)
        }
      }
    }, 500)

    return clearInterval(intervalID)
  }, [])

  return wallet
}
