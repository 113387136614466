import TextField from '@mui/material/TextField'
import _Autocomplete from '@mui/material/Autocomplete'
import { createGlobalStyle, styled } from 'styled-components'

import { COLORS } from 'src/shared/constants'
import { hexToRgb } from 'src/shared/utils'

interface Props {
  options: string[]
  value: string | null
  onChange: (value: string | null) => void
  inputValue?: string
  onInputChange: (value: string) => void
  className?: string
}

export function Autocomplete({
  options,
  value,
  inputValue = '',
  onChange,
  onInputChange,
  className,
}: Props) {
  return (
    <>
      <GlobalAutocompleteStyles />
      <AutocompleteWrapper
        className={className}
        sx={{ width: 300 }}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        noOptionsText='No Matches'
        value={value}
        onChange={(_event, value) => {
          onChange(value as any)
        }}
        inputValue={inputValue}
        onInputChange={(_event, newInputValue) => {
          onInputChange(newInputValue)
        }}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />
    </>
  )
}

const GlobalAutocompleteStyles = createGlobalStyle`
  .MuiAutocomplete-paper {
    border: 1px solid ${COLORS.white};
    font-weight: 700;
    color: ${COLORS.white};
    background-color: ${COLORS.background.primary};

    .Mui-focused {
      background-color: ${hexToRgb(COLORS.white, 0.1)} !important;
    }

    .MuiAutocomplete-noOptions {
      font-weight: 700;
      color: ${COLORS.white};
    }
  }
`

const AutocompleteWrapper = styled(_Autocomplete)`
  color: ${COLORS.white};
  border: none;
  width: 100%;

  .MuiOutlinedInput-notchedOutline {
    background-color: ${hexToRgb(COLORS.white, 0.3)};
    border: none;
  }
  svg {
    color: ${COLORS.white};
  }
  div {
    font-weight: 700;
    border: none;
    color: ${COLORS.white};
  }
  input {
    text-align: right;
  }
`
