import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import styled, { createGlobalStyle } from 'styled-components'

import { NOISE_BG_CSS } from '../styles'
import { COLORS } from '../constants'
import { hexToRgb } from '../utils'
import { CloseButton } from './buttons'

interface Props {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  title?: string
}

export function Popup({ isOpen, onClose, children, title }: Props) {
  return (
    <PopupWrapper onClose={onClose} open={isOpen}>
      <PopupStyles />

      <CloseButtonWrapper onClose={onClose} />
      <Container>
        {title && <Title>{title}</Title>}
        <Card>{children}</Card>
      </Container>
    </PopupWrapper>
  )
}

const PopupStyles = createGlobalStyle`
   .MuiDialog-paper {
      ${NOISE_BG_CSS}
        padding: 15px;
        border: 1px solid ${COLORS.white};
        border-radius: 16px;
    }
  `

const PopupWrapper = styled(Dialog)`
  border-left: 1px solid ${hexToRgb(COLORS.white, 0.6)};
`

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${COLORS.white};
  width: 100%;
  min-height: 50px;
  padding: 20px;
  background-color: ${COLORS.background.container};
  flex-direction: column;
  gap: 15px;
  border-radius: 16px;
`

const Title = styled.h3`
  font-weight: 500;
  text-align: center;
`

const CloseButtonWrapper = styled(CloseButton)`
  position: absolute;
  top: 12px;
  right: 12px;
`
