import { styled } from 'styled-components'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { BTC_PRICE_SYMBOL } from '@packages/interfaces'

import { BREAKPOINTS, COLORS } from 'src/shared/constants'
import {
  CopyText,
  LargeButtonGroup,
  HeaderText,
  HeaderContainer,
  Badge,
} from 'src/shared/components'
import { OpenOrdersByAddress, OrderHistoryByAddress } from 'src/orders'
import { formatShortAddress, useWalletContext } from 'src/wallet'
import { RuneBalancesTable } from 'src/runes'
import { EtchRevealTable, EtchedRunesTable } from 'src/etching'
import { useIsMobile } from 'src/shared/hooks'
import { BatchMintsTable } from 'src/minting'
import { hexToRgb } from 'src/shared/utils'

import { AccountHistoryTable } from './AccountHistoryTable'

export const ACCOUNT_DETAILS_TABS = {
  balances: 'Balances',
  openOrders: 'Open Orders',
  orderHistory: 'Order History',
  mints: 'Mints',
  commitedEtches: 'Commited Etches',
  revealedEtches: 'Revealed Etches',
  history: 'History',
}

const defaultTabOptions = [
  { label: ACCOUNT_DETAILS_TABS.balances, value: ACCOUNT_DETAILS_TABS.balances },
  { label: ACCOUNT_DETAILS_TABS.history, value: ACCOUNT_DETAILS_TABS.history },
]

export function AccountDetailsPageContent() {
  const isMobile = useIsMobile()
  const { address } = useParams() // this should be their ordinals / runes address
  const { runesAddress, paymentAddress, btcBalances } = useWalletContext()
  const [selectedTab, setSelectedTab] = useState(ACCOUNT_DETAILS_TABS.balances)
  const [tabOptions, setTabOptions] = useState(defaultTabOptions)

  function handleTabOnChange(value: string) {
    setSelectedTab(value)
  }

  const isConnectedAddress = runesAddress?.addrString === address

  useEffect(() => {
    // length prevents infinite loop
    if (isConnectedAddress && tabOptions.length === 2) {
      // tabOptions.push({
      //   label: ACCOUNT_DETAILS_TABS.commitedEtches,
      //   value: ACCOUNT_DETAILS_TABS.commitedEtches,
      // })
      // tabOptions.push({
      //   label: ACCOUNT_DETAILS_TABS.revealedEtches,
      //   value: ACCOUNT_DETAILS_TABS.revealedEtches,
      // })
      setTabOptions([
        ...tabOptions,
        {
          label: ACCOUNT_DETAILS_TABS.openOrders,
          value: ACCOUNT_DETAILS_TABS.openOrders,
        },
        {
          label: ACCOUNT_DETAILS_TABS.orderHistory,
          value: ACCOUNT_DETAILS_TABS.orderHistory,
        },
        {
          label: ACCOUNT_DETAILS_TABS.mints,
          value: ACCOUNT_DETAILS_TABS.mints,
        },
      ])
    } else if (!isConnectedAddress && tabOptions.length === 5) {
      setTabOptions(defaultTabOptions)
    }
  }, [runesAddress?.addrString, isConnectedAddress])

  let tabbedContent
  if (address) {
    if (selectedTab === ACCOUNT_DETAILS_TABS.balances) {
      tabbedContent = <RuneBalancesTable address={address} />
    } else if (selectedTab === ACCOUNT_DETAILS_TABS.commitedEtches) {
      tabbedContent = <EtchRevealTable />
    } else if (selectedTab === ACCOUNT_DETAILS_TABS.revealedEtches) {
      tabbedContent = <EtchedRunesTable />
    } else if (selectedTab === ACCOUNT_DETAILS_TABS.history) {
      tabbedContent = <AccountHistoryTable address={address} />
    } else if (selectedTab === ACCOUNT_DETAILS_TABS.mints && paymentAddress) {
      tabbedContent = <BatchMintsTable paymentAddress={paymentAddress.addrString} />
    } else if (selectedTab === ACCOUNT_DETAILS_TABS.openOrders) {
      tabbedContent = <OpenOrdersByAddress address={address} />
    } else if (selectedTab === ACCOUNT_DETAILS_TABS.orderHistory) {
      tabbedContent = <OrderHistoryByAddress address={address} />
    }
  }

  const singleAddress = paymentAddress?.addrString === address

  return (
    <Container>
      <HeaderContainer>
        <HeaderText>Account Details</HeaderText>{' '}
        {isConnectedAddress && <ConnectedLabel>Connected</ConnectedLabel>}
      </HeaderContainer>
      {!singleAddress && (
        <CopyText textToCopy={address ?? ''} tooltipPlacement={'bottom-end'}>
          <>
            <AddressPurpose>Runes Address</AddressPurpose>{' '}
            {isMobile && address ? formatShortAddress(address) : address}{' '}
          </>
        </CopyText>
      )}
      {isConnectedAddress && (
        <PaymentAddress>
          <CopyText textToCopy={paymentAddress?.addrString ?? ''} tooltipPlacement={'bottom-end'}>
            <AddressPurpose>
              {singleAddress ? 'Payment and Runes Address' : 'Payment Address'}
            </AddressPurpose>{' '}
            {isMobile && paymentAddress?.addrString
              ? formatShortAddress(paymentAddress?.addrString)
              : paymentAddress?.addrString}
          </CopyText>
          <BtcBalance>
            Balance: {btcBalances?.chainBalanceBtc} {BTC_PRICE_SYMBOL}
          </BtcBalance>
        </PaymentAddress>
      )}
      <LargeButtonGroupWrapper
        options={tabOptions}
        value={selectedTab}
        onChange={handleTabOnChange}
        exclusive
        updateSearchParams
      />
      {tabbedContent}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    gap: 10px;
  }
`

const ConnectedLabel = styled(Badge)`
  background-color: ${COLORS.positive};
`

const AddressPurpose = styled.div`
  font-size: 15px;
  color: ${hexToRgb(COLORS.white, 0.6)};
`

const PaymentAddress = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
`

const BtcBalance = styled.div``

const LargeButtonGroupWrapper = styled(LargeButtonGroup)`
  padding-top: 40px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-top: 10px;
  }
`
