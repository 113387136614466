import { styled } from 'styled-components'

import { BREAKPOINTS, COLORS } from 'src/shared/constants'
import { hexToRgb } from 'src/shared/utils'

import { TieredTableCell } from './tables'
import { LoadingSkeleton } from './LoadingSkeleton'

interface Props {
  title?: React.ReactNode
  content: React.ReactNode
  subContent: React.ReactNode
  loading?: boolean
  className?: string
}

export function TieredContent({ title, content, subContent, loading, className }: Props) {
  if (loading) {
    return (
      <Container className={className}>
        {title && (
          <Title>
            <LoadingSkeletonWrapper />
          </Title>
        )}
        <TieredTableCellWrapper
          header={content}
          subHeader={subContent}
          loading={loading}
        ></TieredTableCellWrapper>
      </Container>
    )
  }

  return (
    <Container className={className}>
      {title && <Title>{title}</Title>}
      <TieredTableCellWrapper header={content} subHeader={subContent}></TieredTableCellWrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${BREAKPOINTS.small}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`

const Title = styled.div`
  font-size: 16px;
  color: ${hexToRgb(COLORS.white, 0.7)} !important;
`

const TieredTableCellWrapper = styled(TieredTableCell)`
  @media (max-width: ${BREAKPOINTS.small}) {
    text-align: right;
  }
`

const LoadingSkeletonWrapper = styled(LoadingSkeleton)`
  border-radius: 5px;
  min-width: 130px;
`
