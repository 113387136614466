/* eslint-disable @typescript-eslint/quotes */
import { styled } from 'styled-components'
import { useState } from 'react'

import { BREAKPOINTS } from 'src/shared/constants'
import { MintSearch, PopularMintsTable } from 'src/minting'
import { LargeButtonGroup } from 'src/shared/components'
import { LatestEtchingsTable } from 'src/etching'
import { PopularMarketsTable } from 'src/markets'
import { RuneSwapper } from 'src/orders'

import { PageContainer } from './PageContainer'

const HOME_PAGE_TABS = {
  popularMarkets: 'Popular Markets',
  popularMints: 'Popular Mints',
  latest: 'Latest',
}

const tabOptions = Object.values(HOME_PAGE_TABS).map((value) => ({
  value: value,
  label: value,
}))

export function HomePage() {
  const [selectedTab, setSelectedTab] = useState(HOME_PAGE_TABS.popularMarkets)

  let searchContent = <MintSearch />

  let tabbedContent
  if (selectedTab === HOME_PAGE_TABS.popularMarkets) {
    searchContent = <RuneSwapper />
    tabbedContent = <PopularMarketsTableWrapper />
  } else if (selectedTab === HOME_PAGE_TABS.popularMints) {
    tabbedContent = <PopularMintsTableWrapper />
  } else if (selectedTab === HOME_PAGE_TABS.latest) {
    tabbedContent = <LatestEtchingsTable />
  }

  function handleSelectedTabOnChange(value: string) {
    setSelectedTab(value)
  }

  return (
    <PageContainer>
      {searchContent}

      <LargeButtonGroupWrapper
        options={tabOptions}
        value={selectedTab}
        onChange={handleSelectedTabOnChange}
        exclusive
        updateSearchParams
      />
      {tabbedContent}
    </PageContainer>
  )
}

const PopularMarketsTableWrapper = styled(PopularMarketsTable)`
  div:nth-child(1) {
    padding-top: 0px;
  }
`

const PopularMintsTableWrapper = styled(PopularMintsTable)`
  div:nth-child(1) {
    padding-top: 0px;
  }
`

const LargeButtonGroupWrapper = styled(LargeButtonGroup)`
  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-top: 10px;
  }
`
