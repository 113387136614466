import type { Wallet } from '@wallet-standard/base'

const SatsConnectNamespace = 'sats-connect:'

export function isSatsConnectCompatibleWallet(wallet: Wallet) {
  return SatsConnectNamespace in wallet.features
}

export function getSatsProvider(wallet: Wallet) {
  return (wallet as any).features[SatsConnectNamespace]?.provider
}

export function getXverseProvider() {
  return (window as any).XverseProviders?.BitcoinProvider || window.BitcoinProvider
}
