import { useMemo } from 'react'

import { RuneDetails } from '@packages/interfaces'

import { useWalletContext } from 'src/wallet'

import { getMintDetails } from '../utils'

export function useMintDetails({ runeDetails }: { runeDetails?: RuneDetails }) {
  const { blockTip } = useWalletContext()

  const mintDetails = useMemo(() => {
    if (runeDetails) return getMintDetails({ runeDetails, blockTip: blockTip?.height })
  }, [blockTip, runeDetails])

  return mintDetails
}
