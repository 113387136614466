import Decimal from 'decimal.js-light'
import { BigNumberish } from 'ethers'

export function bigIntToInteger(num: bigint) {
  return parseInt(num.toString())
}

export function bigIntToFloat(num: bigint) {
  return parseFloat(num.toString())
}

export function bigIntToDecimal(num: bigint) {
  return new Decimal(num.toString())
}

export function bigIntToBigNumber(num: bigint): BigNumberish {
  return num.toString()
}
