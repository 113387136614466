import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import _Select, { SelectChangeEvent } from '@mui/material/Select'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import { styled } from 'styled-components'

import { hexToRgb } from 'src/shared/utils'

import { COLORS, FONTS } from '../../constants'

interface Props {
  label: string
  options: { value: string; label: string }[]
  value: string
  onChange: (value: string) => void
  className?: string
}

export function Select({ onChange, label, options, value, className }: Props) {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value as string)
  }

  return (
    <FormControl className={className}>
      <InputLabel id={`${label}-select-label`}>{label}</InputLabel>
      <SelectWrapper
        labelId={`${label}-select-label`}
        id={`${label}-select`}
        value={value}
        label={label}
        onChange={handleChange}
        IconComponent={ArrowDropDownRoundedIcon}
        className='select-shared-component'
      >
        {options.map((option) => {
          return (
            <MenuItemWrapper value={option.value} key={option.value}>
              {option.label}
            </MenuItemWrapper>
          )
        })}
      </SelectWrapper>
    </FormControl>
  )
}

const SelectWrapper = styled(_Select<string>)`
  background-color: ${COLORS.buttons.primary};
  border-radius: 8px;
  font-family: ${FONTS.text};
  color: ${COLORS.black};
  font-weight: 700;
  font-size: 15px;
  border: none;

  border-radius: 5px;
  background-color: ${COLORS.buttons.primary};
  white-space: nowrap;

  &:hover {
    background-color: ${hexToRgb(COLORS.white, 0.5)};
  }

  .MuiSelect-select {
    padding: 8px 14px;
  }

  svg {
    color: ${COLORS.black};
  }
`

const MenuItemWrapper = styled(MenuItem)`
  font-family: ${FONTS.text};
  color: ${COLORS.black};
  font-weight: 700;
  font-size: 13px;
  min-height: auto;
`
