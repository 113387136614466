import styled from 'styled-components'

import { formatBtc, satsToBtc, shortFormatBtc } from '@packages/utils'
import { BTC_PRICE_SYMBOL } from '@packages/interfaces'

import { hexToRgb } from 'src/shared/utils'
import { COLORS } from 'src/shared/constants'

interface Props {
  btcAmount: bigint | number
  showPriceSymbol?: boolean
  color?: string
  shortFormat?: boolean
}

export function BtcAmountDisplay({ btcAmount, color, showPriceSymbol, shortFormat }: Props) {
  const symbolColor = color || COLORS.white

  return (
    <Container>
      {showPriceSymbol && <Symbol color={symbolColor}>{BTC_PRICE_SYMBOL}</Symbol>}{' '}
      {typeof btcAmount === 'number' || shortFormat
        ? shortFormatBtc(typeof btcAmount === 'bigint' ? satsToBtc(btcAmount) : btcAmount)
        : formatBtc(btcAmount)}{' '}
    </Container>
  )
}

const Container = styled.span`
  display: flex;
  gap: 3px;
`

const Symbol = styled.span<{ color: string }>`
  color: ${({ color }) => hexToRgb(color, 0.6)};
`
