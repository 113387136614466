import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { styled } from 'styled-components'

import { BREAKPOINTS, COLORS, FONTS } from '../../constants'
import { hexToRgb } from '../../utils'
import { BUTTON_HOVER_STYLES } from './styles'

interface Props {
  options: { value: string; label: string }[]
  value: string
  onChange: (value: string) => void
  exclusive?: boolean
  className?: string
}

export function SmallButtonGroup({
  value,
  onChange,
  options,
  exclusive = false,
  className,
}: Props) {
  const handleChange = (_event: React.MouseEvent<HTMLElement>, selectedValue: string) => {
    onChange(selectedValue)
  }

  return (
    <ToggleButtonGroupWrapper
      value={value}
      exclusive={exclusive}
      onChange={handleChange}
      className={className}
    >
      {options.map((option) => {
        return (
          <ToggleButton key={option.label} value={option.value}>
            {option.label}
          </ToggleButton>
        )
      })}
    </ToggleButtonGroupWrapper>
  )
}

const ToggleButtonGroupWrapper = styled(ToggleButtonGroup)`
  display: flex;
  gap: 13px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    gap: 10px;
  }

  button {
    height: 40px;
    background-color: ${COLORS.background.container};
    color: ${COLORS.white};
    border-radius: 8px !important;
    font-weight: 700;
    font-size: 15px;
    font-family: ${FONTS.text};
    ${BUTTON_HOVER_STYLES}
    &:hover {
      background-color: ${COLORS.background.container};
      color: ${hexToRgb(COLORS.white, 0.5)};
    }

    @media (max-width: ${BREAKPOINTS.medium}) {
      height: 35px;
      font-size: 13px;
    }
  }

  .Mui-selected {
    background-color: ${COLORS.white};
    color: ${COLORS.black};
    &:hover {
      background-color: ${COLORS.white};
      color: ${COLORS.black};
    }
  }
`
