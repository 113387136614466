import Bottleneck from 'bottleneck'

import { PaginationResponse, RuneOutpointDetails } from '@packages/interfaces'
import { API_ENDPOINTS } from '@packages/constants'

import { apiFetchWithCache } from 'src/api'
import { replaceUrlParams } from 'src/shared/utils'

import { AddressDetails } from '../interfaces'

const RUNE_OUTPOINTS_THROTTLE_INTERVAL_MS = 1000 * 2

const limiter = new Bottleneck({
  maxConcurrent: 1,
  minTime: RUNE_OUTPOINTS_THROTTLE_INTERVAL_MS,
  highWater: 2,
})

export async function getRuneUtxos({
  address,
  runeId,
  desiredAmount,
}: {
  address: AddressDetails
  runeId: string
  desiredAmount?: bigint
}): Promise<RuneOutpointDetails[]> {
  if (!address) {
    throw new Error('Invalid address')
  }
  if (!runeId) {
    throw new Error('Invalid rune')
  }
  let runeUtxos: RuneOutpointDetails[] = []
  try {
    let outpointApiResult: PaginationResponse<RuneOutpointDetails> | undefined
    const runeUtxoUrl = replaceUrlParams(
      API_ENDPOINTS.GET.runes.outpoints.runesOutpointsForAccount,
      {
        runeId,
        address: address.addrString,
      }
    )
    let totalAmount = 0n
    do {
      outpointApiResult = await limiter.schedule(
        async () =>
          await apiFetchWithCache<PaginationResponse<RuneOutpointDetails>>(
            `${runeUtxoUrl}?hideOrderOutpoints=true${
              outpointApiResult?.nextCursor ? `&cursor=${outpointApiResult.nextCursor}` : ''
            }`
          )
      )
      runeUtxos = runeUtxos.concat(outpointApiResult.data)
      if (desiredAmount) {
        totalAmount += outpointApiResult.data.reduce((acc, utxo) => acc + utxo.amount, 0n)
        if (totalAmount >= desiredAmount) {
          break
        }
      }
    } while (outpointApiResult.data.length > 0)

    return runeUtxos
  } catch (error: any) {
    console.error(error)
    throw new Error('Failed to fetch UTXOs')
  }
}
