import { base64 } from '@scure/base'

import * as btc from '@packages/scure-btc-signer'
import { SendRawTxsResults } from '@packages/interfaces'
import { API_ENDPOINTS } from '@packages/constants'

import { apiFetch } from 'src/api'

import { finalizeTx } from './btcSigner'

export async function finalizeAndBroadcastTx(signedPsbtBase64: string) {
  const tx = btc.Transaction.fromPSBT(base64.decode(signedPsbtBase64))
  if (!tx.isFinal) {
    const finalized = finalizeTx(signedPsbtBase64)
    return broadcastTx(finalized.hex)
  } else {
    const hex = tx.hex
    return broadcastTx(hex)
  }
}

export async function broadcastTx(hex: string) {
  const apiResponse = await apiFetch<SendRawTxsResults>(
    API_ENDPOINTS.POST.chain.sendRawTransactions,
    {},
    { method: 'POST', body: JSON.stringify([hex]) }
  )
  if (apiResponse.error) {
    throw new Error(apiResponse.error)
  }

  if (apiResponse.results[0].error) {
    throw new Error(apiResponse.results[0].error)
  }

  if (!apiResponse.results[0].txid) {
    throw new Error('Failed to send transaction')
  }

  const txId = apiResponse.results[0].txid

  return txId
}
