import { styled } from 'styled-components'
import { useEffect, useMemo, useState } from 'react'
import Skeleton from '@mui/material/Skeleton'
import { toast } from 'react-toastify'
import { useAutoAnimate } from '@formkit/auto-animate/react'

import { BTC_PRICE_SYMBOL, RuneDetails } from '@packages/interfaces'
import { calculateFeeFromBps, formatBtc, formatRuneName } from '@packages/utils'
import { SATOSHI_DUST_THRESHOLD } from '@packages/constants'

import {
  Drawer,
  DrawerCard,
  ErrorMessage,
  NumberInputWithButtons,
  TextInput,
  WarningMessage,
  Slider,
  HelpTooltip,
  DrawerColumn,
  DrawerBoldColumn,
  DrawerRow,
  DrawerConfirmButton,
  DrawerButtons,
  DrawerCancelButton,
  DrawerTitle,
  ErrorToast,
  LinkPageToast,
  AccordionContent,
  AccordianHeader,
} from 'src/shared/components'
import { BREAKPOINTS, COLORS } from 'src/shared/constants'
import { NetworkFeeSelector, SuccessfulTransactionToast } from 'src/web3'
import { RunesAmountDisplay } from 'src/runes'
import {
  WalletPickerMenu,
  useWalletContext,
  SignTransactionResult,
  calculateBulkMintTxOutputs,
  calculateBulkMintNetworkFeeTotal,
} from 'src/wallet'
import { buildAccountDetailsUrl } from 'src/pages'
import { ACCOUNT_DETAILS_TABS } from 'src/account'
import { useDebounce } from 'src/shared/hooks'
import { useGoogleAnalyticsContext } from 'src/analytics/GoogleAnalyticsContext'

import { useMintDetails } from '../hooks'

const MAX_MINTS = 2300

function transactionToast(result: SignTransactionResult) {
  if (result?.success) {
    toast(
      <SuccessfulTransactionToast message='Mint successful!' transactionId={result.txId ?? ''} />,
      { toastId: result.txId ?? `${Date.now()}` }
    )
  } else {
    console.error(result)
    toast(<ErrorToast errorMessage={result.error} />, { toastId: result.error + Date.now() })
  }
}

interface Props {
  isOpen: boolean
  onClose: () => void
  runeDetails: RuneDetails
}

export function MintRunesDrawer({ isOpen, onClose, runeDetails }: Props) {
  const [animateRefParent] = useAutoAnimate()
  const [isTotalDetailsExpanded, setIsTotalDetailsExpanded] = useState(false)
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false)
  const {
    settings,
    mintRune,
    mintBulkRune,
    estimateMintBulkRuneVsize,
    isConnected,
    runesAddress,
    btcBalances,
  } = useWalletContext()

  const mintDetails = useMintDetails({ runeDetails })

  const { initiateEvent, confirmEvent, executeEvent, abandonEvent } = useGoogleAnalyticsContext()

  const [numberOfMints, setNumberOfMints] = useState(1)
  const [numberOfMintsError, setNumberOfMintsError] = useState<string | undefined>()
  const [recipientAddress, setRecipientAddress] = useState('')
  const [selectedNetworkFee, setSelectedNetworkFee] = useState(0)
  const [totals, setTotals] = useState<{
    networkFeeTotal: bigint
    serviceFeeTotal: bigint
    mintUtxosTotal: bigint
    satsTotal: bigint
    childEstimatedVsize: bigint
  }>()
  const [error, setError] = useState<string>()
  const [loadingText, setLoadingText] = useState<string>()
  const [loadingTotalForQuantity, setLoadingTotalForQuantity] = useState<number[]>([])

  useEffect(() => {
    if (isOpen) {
      initiateEvent('mint', { token_name: runeDetails.runeName, token_type: 'rune' })
    }
  }, [isOpen])

  useEffect(() => {
    if (runesAddress) {
      setRecipientAddress(runesAddress.addrString)
    }
  }, [runesAddress])

  useDebounce(
    async () => {
      if (!selectedNetworkFee || !recipientAddress) return
      try {
        setLoadingTotalForQuantity((prev) => [...prev, numberOfMints])
        const { parent: parentEstimatedVsize, child: childEstimatedVsize } =
          await estimateMintBulkRuneVsize({
            quantity: BigInt(numberOfMints),
            addFee: false,
          })

        const mintUtxosTotal = calculateBulkMintTxOutputs(numberOfMints) * SATOSHI_DUST_THRESHOLD

        let networkFeeTotal = calculateBulkMintNetworkFeeTotal({
          feeRate: BigInt(selectedNetworkFee),
          parentEstimatedVsize,
          childEstimatedVsize,
          numberOfMints,
        })

        const serviceFeeTotal =
          numberOfMints == 1
            ? 0n
            : calculateFeeFromBps(networkFeeTotal, settings.serviceFees.feeBps.mint)

        const { parent: parentEstimatedVsizeUpdated } = await estimateMintBulkRuneVsize({
          quantity: BigInt(numberOfMints),
          addFee: serviceFeeTotal != 0n,
        })

        networkFeeTotal = calculateBulkMintNetworkFeeTotal({
          feeRate: BigInt(selectedNetworkFee),
          parentEstimatedVsize: parentEstimatedVsizeUpdated,
          childEstimatedVsize,
          numberOfMints,
        })

        const satsTotal = BigInt(mintUtxosTotal) + serviceFeeTotal + networkFeeTotal

        setTotals({
          serviceFeeTotal,
          mintUtxosTotal,
          networkFeeTotal,
          satsTotal,
          childEstimatedVsize: BigInt(childEstimatedVsize),
        })
      } catch (error) {
        console.error(error)
        setNumberOfMintsError((error as Error).message)
      } finally {
        setLoadingTotalForQuantity((prev) => prev.filter((q) => q !== numberOfMints))
      }
    },
    [numberOfMints, selectedNetworkFee, recipientAddress],
    150
  )

  const totalMints = useMemo(() => {
    return (runeDetails.runesAmountPerMint ?? 0n) * BigInt(numberOfMints)
  }, [runeDetails.runesAmountPerMint, numberOfMints])

  function resetForm() {
    abandonEvent(true, 'mint', {
      token_name: runeDetails.runeName,
      token_type: 'rune',
      mint_amount: numberOfMints,
      fee: selectedNetworkFee,
    })
    setNumberOfMints(1)
    setSelectedNetworkFee(0)
    setError(undefined)
    setNumberOfMintsError(undefined)
  }

  async function onConfirmClick() {
    if (!isConnected) {
      setIsWalletModalOpen(true)
      return
    }
    confirmEvent('mint', {
      token_name: runeDetails.runeName,
      token_type: 'rune',
      mint_amount: numberOfMints,
      fee: selectedNetworkFee,
    })

    try {
      setLoadingText('Minting...')
      setError(undefined)

      if (numberOfMints === 1) {
        setLoadingText('See Wallet')
        const result = await mintRune({
          runeId: runeDetails.runeId,
          runeName: runeDetails.runeName,
          fee: BigInt(selectedNetworkFee),
          recipientAddress,
        })
        transactionToast(result)
        if (result?.success)
          executeEvent('mint', {
            token_name: runeDetails.runeName,
            token_type: 'rune',
            mint_amount: numberOfMints,
            fee: selectedNetworkFee,
          })
        else onClose()
        resetForm()
      } else {
        const result = await mintBulkRune({
          runeId: runeDetails.runeId,
          runeName: runeDetails.runeName,
          fee: BigInt(selectedNetworkFee),
          recipientAddress,
          quantity: BigInt(numberOfMints),
          onStatusChange: (status) => {
            switch (status) {
              case 'build':
                setLoadingText('Building txs')
                break
              case 'wallet-prompt':
                setLoadingText('See Wallet')
                break
              case 'temp-key-sign':
                setLoadingText('Finalizing txs')
                break
              case 'api-submit':
                setLoadingText('Submitting txs')
                break
            }
          },
        })

        if (result.success) {
          const anyErrors = result.signedTransactionResults.some((r) => !r.success)
          if (anyErrors) {
            toast(
              <LinkPageToast
                message={'Bulk mint submitted. Some broadcasts failed, but will be retried'}
                buttonMessage='View status'
                reactRouterPathname={buildAccountDetailsUrl(runesAddress?.addrString ?? '')}
                reactRouterSearch={`?tab=${ACCOUNT_DETAILS_TABS.mints}`}
                styleVariant={'warning'}
              />,
              { toastId: Date.now() }
            )
          } else {
            toast(
              <LinkPageToast
                message={'Bulk mint submitted'}
                buttonMessage='View status'
                reactRouterPathname={buildAccountDetailsUrl(runesAddress?.addrString ?? '')}
                reactRouterSearch={`?tab=${ACCOUNT_DETAILS_TABS.mints}`}
              />,
              { toastId: Date.now() }
            )
          }
          executeEvent('mint', {
            token_name: runeDetails.runeName,
            token_type: 'rune',
            mint_amount: numberOfMints,
            fee: selectedNetworkFee,
          })
          onClose()
          resetForm()
        } else {
          abandonEvent(false, 'mint', {
            token_name: runeDetails.runeName,
            token_type: 'rune',
            mint_amount: numberOfMints,
            fee: selectedNetworkFee,
          })
          setError('Minting failed: ' + result.error)
        }
      }
    } catch (error: any) {
      console.error(error)
      setError(error.message)
    } finally {
      setLoadingText(undefined)
    }
  }

  function onNumberOfMintsChange(mints: number) {
    setNumberOfMintsError(undefined)
    if (mints + Number(runeDetails.numberOfMints ?? 0n) > Number(runeDetails.maxMints ?? 0n)) {
      setNumberOfMintsError('You are trying to mint more times than the remaining mints.')
    }
    setNumberOfMints(mints)
  }

  function handleOnClose() {
    onClose()
    resetForm()
  }

  function handleOnCancel() {
    abandonEvent(false, 'mint', {
      token_name: runeDetails.runeName,
      token_type: 'rune',
      mint_amount: numberOfMints,
      fee: selectedNetworkFee,
    })
    setLoadingText(undefined)
  }

  const isTotalsLoading = loadingTotalForQuantity.length > 0
  const buttonText = loadingText ?? (isTotalsLoading ? 'Calculating Totals' : 'Confirm Mint')

  return (
    <Drawer isOpen={isOpen} onClose={handleOnClose} canClose={!loadingText}>
      <DrawerCard>
        <DrawerTitle>Minting {formatRuneName(runeDetails)}</DrawerTitle>
      </DrawerCard>
      <DrawerCard>
        <MintsDescriptionRow>
          <MintAmountColumn>
            <Header>Repeat Mints</Header>
            {numberOfMints > 1 ? (
              <SubHeader>
                You will be prompted to sign 1 transaction that will split your BTC. Mints will take
                at least 2 blocks to confirm.
              </SubHeader>
            ) : (
              <SubHeader>You will be prompted to sign 1 transaction</SubHeader>
            )}
          </MintAmountColumn>
          <DrawerColumn>
            <NumberInputWithButtons
              onChange={onNumberOfMintsChange}
              value={numberOfMints}
              minValue={1}
              maxValue={MAX_MINTS}
            />
          </DrawerColumn>
        </MintsDescriptionRow>
        <DrawerRow>
          <Slider
            spacing={1}
            value={numberOfMints}
            onChange={onNumberOfMintsChange}
            min={1}
            max={MAX_MINTS}
          />
        </DrawerRow>
        {numberOfMintsError && (
          <DrawerRow>
            <ErrorMessageWrapper message={numberOfMintsError} />
          </DrawerRow>
        )}
        <TotalAmountRow>
          <TotalAmountHeader>Tokens Per Mint</TotalAmountHeader>
          <DrawerColumn>
            <RunesAmountDisplay rune={runeDetails} runesAmount={runeDetails.runesAmountPerMint} />
          </DrawerColumn>
        </TotalAmountRow>
        <TotalAmountRow>
          <TotalAmountHeader>Total Minted Amount</TotalAmountHeader>
          <DrawerColumn>
            <RunesAmountDisplay rune={runeDetails} runesAmount={totalMints} />
          </DrawerColumn>
        </TotalAmountRow>
      </DrawerCard>
      <DrawerCard>
        <DrawerRow>
          <Header>Recipient Address</Header>
        </DrawerRow>
        <DrawerRow>
          <TextInput
            value={recipientAddress}
            onChange={setRecipientAddress}
            error={!recipientAddress}
            helperText={!recipientAddress ? 'Required' : undefined}
          />
        </DrawerRow>
      </DrawerCard>
      <DrawerCard>
        <DrawerRow>
          <NetworkFeeSelector
            selectedFee={selectedNetworkFee}
            onChange={(fee) => setSelectedNetworkFee(fee)}
            onlyAllowFastestFee
            addFastestFeeBuffer={numberOfMints > 24}
          />
        </DrawerRow>
      </DrawerCard>

      <DrawerCard>
        <TotalAmountRow>
          <TotalAmountHeader>BTC Balance</TotalAmountHeader>
          <TotalColumn>
            {btcBalances?.chainBalanceBtc} {BTC_PRICE_SYMBOL}
          </TotalColumn>
        </TotalAmountRow>
      </DrawerCard>

      <TotalsDrawerCard>
        <TotalAmountRow>
          <TotalAmountHeader>
            <AccordianHeader
              linkText='Mint Total'
              expanded={isTotalDetailsExpanded}
              onExpand={() => setIsTotalDetailsExpanded(!isTotalDetailsExpanded)}
            />
          </TotalAmountHeader>

          <TotalColumn>
            {isTotalsLoading ? (
              <SkeletonWrapper />
            ) : (
              `${formatBtc(totals?.satsTotal ?? 0n)} ${BTC_PRICE_SYMBOL}`
            )}
          </TotalColumn>
        </TotalAmountRow>

        <div ref={animateRefParent}>
          <AccordionContentWrapper expanded={isTotalDetailsExpanded}>
            <DrawerRow>
              <TotalAmountHeader>
                Mint UTXOs{' '}
                <HelpTooltip content='This is the minimum amount required in a UTXO that will hold a runestone multiplied by the selected number of mints' />
              </TotalAmountHeader>
              <TotalColumn>
                {isTotalsLoading ? (
                  <SkeletonWrapper />
                ) : (
                  `${formatBtc(totals?.mintUtxosTotal ?? 0n)} ${BTC_PRICE_SYMBOL}`
                )}
              </TotalColumn>
            </DrawerRow>
            <DrawerRow>
              <TotalAmountHeader>
                Total Network Fees{' '}
                <HelpTooltip content='This includes all of the network fees that will be used to broadcast the mint transactions once the splitter tx has been included in a block' />
              </TotalAmountHeader>
              <TotalColumn>
                {isTotalsLoading ? (
                  <SkeletonWrapper />
                ) : (
                  `≈ ${formatBtc(totals?.networkFeeTotal ?? 0n)} ${BTC_PRICE_SYMBOL}`
                )}
              </TotalColumn>
            </DrawerRow>
            <DrawerRow>
              <TotalAmountHeader>Service Fee</TotalAmountHeader>
              <TotalColumn>
                {isTotalsLoading ? (
                  <SkeletonWrapper />
                ) : (
                  `${formatBtc(totals?.serviceFeeTotal ?? 0n)} ${BTC_PRICE_SYMBOL}`
                )}
              </TotalColumn>
            </DrawerRow>
          </AccordionContentWrapper>
        </div>
      </TotalsDrawerCard>

      {(mintDetails?.mintedPercentWithPendingMints ?? 0) >= 85 && (
        <DrawerCard>
          <DrawerRow>
            <DrawerColumn>
              <WarningMessage message='Warning: this rune is almost minted out and your transaction may fail. If the mint closes before your transaction confirms your BTC will still be lost and spent as network fees.' />
            </DrawerColumn>
          </DrawerRow>
        </DrawerCard>
      )}

      {error && <ErrorMessage message={error} />}
      <DrawerButtons>
        {loadingText ? (
          <DrawerCancelButton onClick={handleOnCancel}>Cancel Tx</DrawerCancelButton>
        ) : (
          <DrawerCancelButton onClick={handleOnClose}>Exit</DrawerCancelButton>
        )}
        <DrawerConfirmButton
          onClick={onConfirmClick}
          loading={!!loadingText || isTotalsLoading}
          disabled={isTotalsLoading || !!numberOfMintsError}
        >
          {buttonText}
        </DrawerConfirmButton>
      </DrawerButtons>

      <WalletPickerMenu isOpen={isWalletModalOpen} onClose={() => setIsWalletModalOpen(false)} />
    </Drawer>
  )
}

const MintAmountColumn = styled(DrawerColumn)`
  flex-direction: column;
`

const TotalColumn = styled(DrawerColumn)`
  text-align: right;
  text-wrap: nowrap;
`

const MintsDescriptionRow = styled(DrawerRow)`
  gap: 5px;
  flex-direction: row;

  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;
  }
`

const TotalsDrawerCard = styled(DrawerCard)`
  gap: 0px;
`

const TotalAmountHeader = styled(DrawerBoldColumn)`
  flex: 2;
  font-size: 16px;
`

const TotalAmountRow = styled(DrawerRow)`
  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: row;
    gap: 5px;
  }
`

const Header = styled(DrawerBoldColumn)`
  display: flex;
  gap: 10px;
  font-size: 17px;
  font-weight: 500;
`

const SubHeader = styled.div`
  font-weight: 300;
  font-size: 14px;
`

const ErrorMessageWrapper = styled(ErrorMessage)`
  width: 100%;
`

const SkeletonWrapper = styled(Skeleton)`
  border-radius: 5px;
  height: 30px;
  background-color: ${COLORS.background.container};
  width: 100%;
`

const AccordionContentWrapper = styled(AccordionContent)`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  gap: 5px;
`
