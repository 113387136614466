import { BitcoinProvider, GetAddressResponse } from 'sats-connect'

export interface CustomBitcoinProvider extends BitcoinProvider {
  isAvailable: () => boolean
  disconnect: () => Promise<void>
  getCurrentAddress: () => Promise<GetAddressResponse>
}

export function isCustomBitcoinProvider(object: any): object is CustomBitcoinProvider {
  return 'disconnect' in object
}
