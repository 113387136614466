import styled from 'styled-components'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useState } from 'react'

import { COLORS } from '../constants'

interface Props {
  linkText: string
  children?: React.ReactNode
  useAnimation?: boolean
  expandedByDefault?: boolean
}

export function Accordion({
  linkText,
  useAnimation = true,
  expandedByDefault = false,
  children,
}: Props) {
  const [animateRefParent] = useAutoAnimate()
  const [expanded, setExpanded] = useState(expandedByDefault)

  function onExpand() {
    setExpanded(!expanded)
  }

  const containerProps = {
    ...(useAnimation && { ref: animateRefParent }),
  }

  return (
    <Container {...containerProps}>
      <ExpandLink onClick={onExpand}>
        <ExpandLinkText>{linkText}</ExpandLinkText>
        <ExpandLinkIcon $expanded={expanded} />
      </ExpandLink>
      <AccordionContent expanded={expanded}>{children}</AccordionContent>
    </Container>
  )
}

export function AccordianHeader({
  linkText,
  onExpand,
  expanded,
  className,
}: {
  linkText: string
  onExpand: () => void
  expanded: boolean
  className?: string
}) {
  return (
    <ExpandLink onClick={onExpand} className={className}>
      <ExpandLinkText>{linkText}</ExpandLinkText>
      <ExpandLinkIcon $expanded={expanded} />
    </ExpandLink>
  )
}

export function AccordionContent({
  expanded,
  children,
  className,
}: {
  expanded: boolean
  children: React.ReactNode
  className?: string
}) {
  if (expanded) {
    return <Content className={className}>{children}</Content>
  }
}

const Container = styled.div<{ ref?: any }>`
  width: 100%;
`

const ExpandLink = styled.div`
  display: flex;
  color: ${COLORS.link};
  cursor: pointer;
  align-items: center;
`

const ExpandLinkText = styled.span``

const ExpandLinkIcon = styled(ExpandMoreIcon)<{ $expanded: boolean }>`
  transform: ${(props) => (props.$expanded ? 'rotate(0deg)' : 'rotate(-90deg)')};
`

const Content = styled.div`
  margin-top: 15px;
`
