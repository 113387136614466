import { styled } from 'styled-components'

import { RuneDetails } from '@packages/interfaces'

import { COLORS, FONTS } from 'src/shared/constants'
import { useWalletContext } from 'src/wallet'

import { getMintState } from '../utils'

interface Props {
  runeDetails: RuneDetails
}

export function MintStateBadge({ runeDetails }: Props) {
  const { blockTip } = useWalletContext()
  const mintState = getMintState({ runeDetails, blockTip: blockTip?.height })

  let color = COLORS.white
  switch (mintState) {
    case 'Ended':
    case 'Not Mintable':
    case 'Minted Out':
      color = COLORS.negative
      break
    case 'Not Started':
      color = COLORS.warning
      break
    case 'Premined':
      color = COLORS.white
      break
    case 'Minting':
    default:
      color = COLORS.positive
      break
  }

  return <MintState color={color}>{mintState}</MintState>
}

const MintState = styled.div<{ color: string }>`
  width: fit-content;
  padding: 3px 5px;
  border-radius: 5px;
  color: ${({ color }) => color} !important;
  font-size: 14px;
  font-weight: 500;
  font-family: ${FONTS.text};
`
