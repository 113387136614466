import { useState, useEffect } from 'react'

import { apiFetch } from '../utils'

export function useApi<T>({
  endpoint,
  queryParams,
  disable,
}: {
  endpoint: string
  queryParams?: { [key: string]: any }
  disable?: boolean
}) {
  const [data, setData] = useState<T>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const responseData = await apiFetch<T>(endpoint, queryParams)
        setData(responseData)
        setLoading(false)
      } catch (error: any) {
        setError((error as Error).message)
        setLoading(false)
      }
    }

    if (!disable) {
      fetchData()
    }
  }, [endpoint, queryParams, disable])

  return { data, loading, error }
}
