import Grid from '@mui/material/Grid'
import { styled } from 'styled-components'

import { COLORS } from 'src/shared/constants'

import { Slider, SliderProps } from './Slider'
import { NumberInput } from './NumberInput'

interface Props extends SliderProps {}

export function SliderWithInput({ value, onChange, min, max, spacing, className }: Props) {
  const handleInputChange = (newValue?: number) => {
    onChange(newValue ? parseInt(`${newValue}`) : NaN)
  }

  return (
    <Container className={className}>
      <Grid item xs>
        <Slider value={value} min={min} max={max} spacing={spacing} onChange={onChange} />
      </Grid>
      <Grid item>
        <NumberInput value={value} onChange={handleInputChange} />
      </Grid>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  gap: 20px;
  .MuiSlider-root {
    color: ${COLORS.link};
  }
`
