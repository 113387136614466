import { styled } from 'styled-components'

import { DEFAULT_RUNE_SYMBOL } from '@packages/interfaces'
import { formatUsd, satsToBtc } from '@packages/utils'

import { HelpTooltip, TieredContent } from 'src/shared/components'
import { RuneDetailsLink } from 'src/runes'
import { BREAKPOINTS, COLORS } from 'src/shared/constants'
import { BtcAmountDisplay, PriceSatsDisplay, PriceUsdDisplay, useOrderContext } from 'src/orders'
import { hexToRgb } from 'src/shared/utils'

const INFO_HEADERS = {
  floorPrice: 'Price',
  marketCap: 'Market Cap',
  volume: 'Volume (24h)',
}

export function MarketOverviewSection() {
  const { selectedRuneMarket, selectedRuneName } = useOrderContext()

  const isMarketLoading = !selectedRuneMarket

  return (
    <Container>
      {isMarketLoading ? (
        <LoadingDetailsLink>
          {DEFAULT_RUNE_SYMBOL} {selectedRuneName}
        </LoadingDetailsLink>
      ) : (
        <RuneDetailsLinkWrapper rune={selectedRuneMarket} />
      )}
      <TieredContentWrapper
        title={
          <TitleWithIcon>
            {INFO_HEADERS.floorPrice}
            <HelpTooltip
              icon='info'
              content='This price is the lowest asking price across all Rune marketplaces'
            />
          </TitleWithIcon>
        }
        content={
          <PriceSatsDisplay
            priceSats={isMarketLoading ? 0 : selectedRuneMarket?.floorPriceSats}
            runeSymbol={isMarketLoading ? DEFAULT_RUNE_SYMBOL : selectedRuneMarket?.runeSymbolChar}
          />
        }
        subContent={<PriceUsdDisplay priceSats={selectedRuneMarket?.floorPriceSats} />}
      />
      <TieredContentWrapper
        title={INFO_HEADERS.marketCap}
        content={
          isMarketLoading ? (
            0
          ) : (
            <BtcAmountDisplay
              btcAmount={satsToBtc(selectedRuneMarket?.marketCapSats ?? 0)}
              showPriceSymbol
            />
          )
        }
        subContent={
          isMarketLoading
            ? '$0.00'
            : formatUsd({ usd: selectedRuneMarket?.marketCapUsd ?? '0', shortFormat: true })
        }
      />
      <TieredContentWrapper
        title={
          <TitleWithIcon>
            {INFO_HEADERS.volume}
            <HelpTooltip
              icon='info'
              content='This total includes the volume across all Rune marketplaces'
            />
          </TitleWithIcon>
        }
        content={
          isMarketLoading ? (
            0
          ) : (
            <BtcAmountDisplay btcAmount={selectedRuneMarket?.volumeSats ?? 0n} showPriceSymbol />
          )
        }
        subContent={
          isMarketLoading
            ? '$0.00'
            : formatUsd({ usd: selectedRuneMarket?.volumeUsd ?? '0', shortFormat: true })
        }
      />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  border-radius: 16px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  border: 1px solid ${COLORS.border};

  flex: 2;

  @media (max-width: ${BREAKPOINTS.medium}) {
    gap: 10px;
    div {
      font-size: 15px;
    }
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    h3 {
      align-self: flex-start;
    }
    flex-direction: column;
  }
`

const LoadingDetailsLink = styled.div`
  color: ${COLORS.link} !important;
  font-weight: 700;
  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-bottom: 10px;
  }
`

const RuneDetailsLinkWrapper = styled(RuneDetailsLink)`
  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-bottom: 10px;
  }
`

const TitleWithIcon = styled.div`
  display: flex;

  align-items: center;
  color: ${hexToRgb(COLORS.white, 0.7)}!important;

  svg {
    height: 20px;
    color: ${hexToRgb(COLORS.white, 0.7)}!important;
  }
`

const TieredContentWrapper = styled(TieredContent)`
  h4 {
    padding-top: 2px;
    padding-bottom: 0px;
  }
  @media (max-width: ${BREAKPOINTS.medium}) {
    align-items: flex-start;
  }
`
