import JSONbig from '@cardanosolutions/json-bigint'

import { API_URL } from 'src/settings'

import { formatQueryParams } from './queryParams'

export interface FetchOptions {
  method?: 'GET' | 'POST'
  body?: any
  disableJsonBigParsing?: boolean
}

const jsonBig = JSONbig({ useNativeBigInt: true, alwaysParseAsBig: true })

// returns an object
export async function apiFetch<T>(
  endpoint: string,
  queryParams?: { [key: string]: string | number | bigint | boolean },
  options?: FetchOptions
): Promise<T> {
  let response: Response
  try {
    const queryParamsStr = queryParams ? formatQueryParams(queryParams, endpoint) : ''
    response = await fetch(new URL(`${endpoint}${queryParamsStr}`, API_URL), {
      method: options?.method ?? 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip, compress, br',
      },
      body: options?.body
        ? typeof options.body === 'object' && !options.disableJsonBigParsing
          ? jsonBig.stringify(options?.body)
          : options.body
        : undefined,
    })
  } catch (error) {
    console.error('api network error', error)

    throw new Error('API Connection Issue')
  }

  if (!response.ok) {
    if (response.status === 400) {
      const json: { message: string; name: string } = await response.json()
      throw new Error(json.message)
    } else {
      console.error('api fetch error', response)

      const json: { message: string; name: string } = await response.json()
      throw new Error(json.message)
    }
  }

  try {
    const text = await response.text()

    const parsed = options?.disableJsonBigParsing ? JSON.parse(text) : jsonBig.parse(text)

    return parsed as T
  } catch (error) {
    console.error('api parse error', error)

    throw new Error('API Parse Issue')
  }
}
