import { Link } from 'react-router-dom'
import { styled } from 'styled-components'

import { formatRuneName } from '@packages/utils'

import { buildRuneDetailsUrl } from 'src/pages'
import { COLORS } from 'src/shared/constants'
import { BUTTON_HOVER_STYLES } from 'src/shared/components'
import { useIsMobile } from 'src/shared/hooks'

import { RuneNameDisplay } from './RuneNameDisplay'
import { RuneImage } from './RuneImage'

interface Props {
  rune: { runeName: string; runeSymbolChar?: string; runeNameSpacerIndices?: bigint[] }
  displayName?: string
  className?: string
}

export function RuneDetailsLink({ rune, displayName, className }: Props) {
  const isMobile = useIsMobile()
  const runeName = formatRuneName(rune)

  return (
    <LinkWrapper
      to={buildRuneDetailsUrl(isMobile ? rune.runeName : runeName)}
      className={className}
    >
      <RuneName>
        {displayName ?? (
          <>
            <RuneImage runeDetails={rune} />
            <RuneNameDisplay rune={rune} />
          </>
        )}
      </RuneName>
    </LinkWrapper>
  )
}

const LinkWrapper = styled(Link)`
  ${BUTTON_HOVER_STYLES}
`

const RuneName = styled.div`
  color: ${COLORS.link} !important;
  font-weight: 700;
  word-break: break-word;
  gap: 5px;
  display: flex;
  align-items: center;
`
