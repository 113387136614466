import { Address, WalletEvent } from 'sats-connect'

export interface AccountChangeEvent {
  type: 'accountChange'
  addresses: Address[]
}

export interface NetworkChangeEvent {
  type: 'networkChange'
}

// Define EventListeners with mapped types for specific event listeners
export type EventListeners = {
  accountChange: ListenerCallback<'accountChange'>[]
  networkChange: ListenerCallback<'networkChange'>[]
  disconnect: ListenerCallback<'disconnect'>[]
}

type ListenerCallback<T extends WalletEvent['type']> = T extends 'accountChange'
  ? (event: AccountChangeEvent) => void
  : T extends 'networkChange'
    ? (event: NetworkChangeEvent) => void
    : () => void

export function addListener<T extends WalletEvent['type']>(
  listeners: EventListeners,
  eventName: T,
  cb: ListenerCallback<T>
) {
  if (eventName === 'accountChange') {
    listeners.accountChange.push(cb as ListenerCallback<'accountChange'>)
  } else if (eventName === 'networkChange') {
    listeners.networkChange.push(cb as ListenerCallback<'networkChange'>)
  } else if (eventName === 'disconnect') {
    listeners.disconnect.push(cb as ListenerCallback<'disconnect'>)
  }

  return () => {
    listeners[eventName] = (listeners[eventName] as ListenerCallback<T>[]).filter(
      (listener) => listener !== cb
    ) as EventListeners[typeof eventName]
  }
}
