import { styled } from 'styled-components'

import { FilledOrder, Rune } from '@packages/interfaces'
import { formatUsd } from '@packages/utils'

import { TieredTableCell, VirtualizedTable } from 'src/shared/components'
import { useIsMobile } from 'src/shared/hooks'
import { RunesAmountDisplay } from 'src/runes'
import { TransactionExternalLink } from 'src/web3'
import { unixTimestampToDateString } from 'src/shared/utils'

import { formatPriceSymbol } from '../utils'
import { OrderStatusQueryParams, useOrdersByStatusApi } from '../hooks'
import { BtcAmountDisplay } from './BtcAmountDisplay'

const QUERY_PARAMS: OrderStatusQueryParams = {
  status: 'Filled',
  sortOrder: 'desc',
  sortOrderBy: 'timestamp',
  limit: 40,
}

interface Props {
  rune: Rune
}

export function OrderHistoryTable({ rune }: Props) {
  const isMobile = useIsMobile()

  const {
    paginatedData: filledOrders,
    fetchPage,
    newPageLoading,
    hasNextPage,
  } = useOrdersByStatusApi<FilledOrder>({ runeName: rune.runeName, queryParams: QUERY_PARAMS })

  return (
    <Container>
      <VirtualizedTable
        columns={[
          {
            dataKey: 'priceSats',
            width: 25,
            label: isMobile
              ? 'Price'
              : `Price (${formatPriceSymbol({ runeSymbol: rune.runeSymbolChar })})`,
            formatter: ({ data: order }) => (
              <TieredTableCell header={order.priceSats} subHeader={order.priceUsd} />
            ),
          },
          {
            dataKey: 'runesAmount',
            width: 20,
            label: isMobile ? 'Qty' : 'Quantity',
            formatter: ({ data: order }) => (
              <Quantity>
                <RunesAmountDisplay
                  rune={rune}
                  runesAmount={order.runesAmount}
                  shortFormat={isMobile}
                />
              </Quantity>
            ),
          },
          {
            dataKey: 'satsAmount',
            width: 25,
            label: 'Total',
            formatter: ({ data: order }) => (
              <TieredTableCell
                header={
                  <BtcAmountDisplay
                    btcAmount={order.satsAmount}
                    showPriceSymbol
                    shortFormat={isMobile}
                  />
                }
                subHeader={formatUsd({ usd: order.valueUsd })}
              />
            ),
          },
          {
            dataKey: 'fillTransactionId',
            width: 30,
            label: 'Date',
            formatter: ({ data: order }) => (
              <TransactionExternalLink
                transactionId={order.fillTransactionId}
                displayIcon={!isMobile}
              >
                {unixTimestampToDateString(order.filledAtTimestamp, true)}
              </TransactionExternalLink>
            ),
          },
        ]}
        paginatedData={filledOrders}
        fetchPage={fetchPage}
        loading={newPageLoading}
        hasNextPage={hasNextPage}
        rowHeight={50}
        viewableRows={25}
        headerShown={false}
        emptyDataMessage='No trade history'
      />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Quantity = styled.div`
  font-weight: 700 !important;
`
