import { useEffect, useState } from 'react'

import { readItemFromLocalStorage, writeItemToLocalStorage } from 'src/shared/utils'
import { useWalletContext } from 'src/wallet'

import {
  ETCH_COMMITS_TRANSACTION_IDS,
  ETCH_COMMIT_REQUEST_LOCAL_STORAGE_KEY_PREFIX,
} from '../constants'
import { EtchRunePayload } from '../interfaces'

export function useCommittedEtchings() {
  const { runesAddress } = useWalletContext()

  const [committedEtchings, setCommittedEtchings] = useState<EtchRunePayload[]>([])

  function readCommittedRunesFromLocalStorage() {
    const committedRunesTrxIds = readItemFromLocalStorage(
      ETCH_COMMITS_TRANSACTION_IDS,
      [],
    ) as string[]
    setCommittedEtchings(
      committedRunesTrxIds
        .map((transactionId) => {
          const rune: EtchRunePayload | undefined = readItemFromLocalStorage(
            `${ETCH_COMMIT_REQUEST_LOCAL_STORAGE_KEY_PREFIX}${transactionId}`,
            undefined,
          )
          return rune
        })
        .filter(
          (rune): rune is EtchRunePayload =>
            rune !== undefined &&
            rune.etchingCommit.committedByAddress === runesAddress?.addrString,
        ),
    )
  }

  function updateCommittedEtching(rune: EtchRunePayload) {
    writeItemToLocalStorage(
      `${ETCH_COMMIT_REQUEST_LOCAL_STORAGE_KEY_PREFIX}${rune.etchingCommit.txId}`,
      rune,
    )
    readCommittedRunesFromLocalStorage()
  }

  useEffect(() => {
    readCommittedRunesFromLocalStorage()
  }, [runesAddress?.addrString])

  return {
    committedEtchings,
    updateCommittedEtching,
    committedEtchingsCount: committedEtchings.length,
    forceUpdate: readCommittedRunesFromLocalStorage,
  }
}
