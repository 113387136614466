import { styled } from 'styled-components'
import { BitcoinProvider } from 'sats-connect'

import { BUTTON_HOVER_STYLES, Button, Drawer, DrawerCard, HeaderText } from 'src/shared/components'
import { COLORS } from 'src/shared/constants'
import leatherIcon from 'src/wallet/assets/leather.png'
import magicEdenIcon from 'src/wallet/assets/magic-eden.svg'
import okxIcon from 'src/wallet/assets/okx.jpeg'
import unisatIcon from 'src/wallet/assets/unisat.png'
import xverseIcon from 'src/wallet/assets/xverse.png'
import { ENABLE_EXTRA_WALLETS } from 'src/settings'

import { useWalletContext } from '../WalletContext'
import {
  useLeatherProvider,
  useUnisatProvider,
  useOkxProvider,
  useMagicEdenProvider,
} from '../hooks'
import { WALLET_NAME } from '../constants'
import { getXverseProvider } from '../utils'

const WALLET_LINKS = {
  magicEden: 'https://wallet.magiceden.io/',
  xverse: 'https://www.xverse.app/',
  unisat: 'https://unisat.io/download',
  leather: 'https://leather.io/install-extension',
  okx: 'https://www.okx.com/web3',
}

interface Props {
  isOpen: boolean
  onClose: () => void
}

export function WalletPickerMenu({ isOpen, onClose }: Props) {
  const { connectWallet } = useWalletContext()

  const magicEdenProvider = useMagicEdenProvider()
  const xverseProvider = getXverseProvider()
  const unisatProvider = useUnisatProvider()
  const leatherProvider = useLeatherProvider()
  const okxProvider = useOkxProvider()

  async function onXverseSelect() {
    if (xverseProvider) {
      await connectWallet(xverseProvider, WALLET_NAME.xverse)
    }
    onClose()
  }

  async function onUnisatSelect() {
    if (unisatProvider) {
      await connectWallet(unisatProvider, WALLET_NAME.unisat)
    }
    onClose()
  }

  async function onLeatherSelect() {
    if (leatherProvider) {
      await connectWallet(leatherProvider, WALLET_NAME.leather)
    }
    onClose()
  }

  async function onMagicEdenSelect() {
    if (magicEdenProvider) {
      await connectWallet(magicEdenProvider, WALLET_NAME.magicEden)
      onClose()
    }
  }

  async function onOkxSelect() {
    if (okxProvider) {
      await connectWallet(okxProvider, WALLET_NAME.okx)
      onClose()
    }
  }

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <Container>
        <Title>Connect Wallet</Title>
        <WalletItems>
          <WalletCard
            icon={xverseIcon}
            name='Xverse'
            onConnect={onXverseSelect}
            installLink={WALLET_LINKS.xverse}
            provider={xverseProvider}
          />
          <WalletCard
            icon={leatherIcon}
            name='Leather'
            onConnect={onLeatherSelect}
            installLink={WALLET_LINKS.leather}
            provider={leatherProvider}
          />
          <WalletCard
            icon={unisatIcon}
            name='Unisat'
            onConnect={onUnisatSelect}
            installLink={WALLET_LINKS.unisat}
            provider={unisatProvider}
          />
          <WalletCard
            icon={okxIcon}
            name='OKX'
            onConnect={onOkxSelect}
            installLink={WALLET_LINKS.okx}
            provider={okxProvider}
          />
          {ENABLE_EXTRA_WALLETS && (
            <WalletCard
              icon={magicEdenIcon}
              name='Magic Eden'
              onConnect={onMagicEdenSelect}
              installLink={WALLET_LINKS.magicEden}
              provider={magicEdenProvider}
            />
          )}
        </WalletItems>
      </Container>
    </Drawer>
  )
}

interface WalletCardProps {
  name: string
  icon: string
  installLink: string
  provider?: BitcoinProvider
  onConnect: () => void
}

function WalletCard({ name, icon, installLink, provider, onConnect }: WalletCardProps) {
  return (
    <WalletDrawerCard onClick={provider ? onConnect : () => window.open(installLink, '_newtab')}>
      <WalletTitle>
        <WalletIcon src={icon} /> {name}
      </WalletTitle>
      {provider ? <ConnectButton>Connect</ConnectButton> : <ConnectButton>Install</ConnectButton>}
    </WalletDrawerCard>
  )
}

const Container = styled.div`
  width: 100%;
`

const Title = styled(HeaderText)`
  font-weight: 500;
`

const WalletItems = styled.div`
  padding-top: 30px;
  display: flex;
  gap: 20px;
  flex-direction: column;
`

const WalletTitle = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

const WalletIcon = styled.img`
  width: 30px;
  border-radius: 5px;
`

const ConnectButton = styled(Button)`
  color: ${COLORS.black};
  background-color: ${COLORS.buttons.primary};
`

const WalletDrawerCard = styled(DrawerCard)`
  ${BUTTON_HOVER_STYLES}
  flex-direction: row;
`
