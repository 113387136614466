import { createContext, ReactNode, useContext } from 'react'

import { ENVIRONMENT } from 'src/settings'

import EventId from './EventId'

interface GoogleAnalyticsContextType {
  connectWalletEvent: (provider: string) => void
  viewAccountEvent: () => void
  initiateEvent: (eventType: UserEventType, args: UserEventArgs) => void
  confirmEvent: (eventType: UserEventType, args: UserEventArgs) => void
  executeEvent: (eventType: UserEventType, args: UserEventArgs) => void
  abandonEvent: (destoryEventId: boolean, eventType: UserEventType, args: UserEventArgs) => void
  customEvent: (
    eventName: string,
    destoryEventId: boolean,
    eventType: UserEventType,
    args: UserEventArgs
  ) => void
}

type UserEventType = 'mint' | 'transfer' | 'swap'

type UserEventArgs = MintArgs | TransferArgs | SwapArgs

interface MintArgs {
  token_name: string
  token_type: string
  mint_amount?: number
  fee?: number
}

interface TransferArgs {
  token_name: string
  token_type: string
  transfer_amount: bigint
  fee: number
}

type OrderAction = 'buy' | 'sell'

interface SwapArgs {
  token_name: string
  token_type: string
  order_amount: bigint
  order_action: OrderAction
  price: bigint
}

interface EventArgs {
  event_category: string
  event_id: string
}

const GoogleAnalyticsContext = createContext<GoogleAnalyticsContextType | undefined>(undefined)

export const useGoogleAnalyticsContext = () => {
  const context = useContext(GoogleAnalyticsContext)
  if (context === undefined) {
    throw new Error('useAnalyticsContext must be used within a AnalyticsProvider')
  }
  return context
}

export const GoogleAnalyticsProvider = ({
  window,
  children,
}: {
  window: Window
  children: ReactNode
}) => {
  const _blockAnalytics = (): boolean => {
    // Don't send GA events when running locally
    if (ENVIRONMENT == 'local') return true
    if (typeof window.gtag !== 'function') return true

    return false
  }

  const connectWalletEvent = (provider: string) => {
    if (_blockAnalytics()) return

    window.gtag('event', 'connect_wallet', {
      event_category: 'user',
      provider: provider,
    })
  }

  const viewAccountEvent = () => {
    if (_blockAnalytics()) return

    window.gtag('event', 'view_account', {
      event_category: 'user',
    })
  }

  const initiateEvent = (eventType: UserEventType, args: UserEventArgs) => {
    if (_blockAnalytics() || EventId.hasInstance()) return

    const eventArgs: EventArgs = {
      event_category: eventType,
      event_id: EventId.fetch(),
    }

    window.gtag('event', 'initiate_' + eventType, {
      ...eventArgs,
      ...args,
    })
  }

  const confirmEvent = (eventType: UserEventType, args: UserEventArgs) => {
    if (_blockAnalytics() || !EventId.hasInstance()) return

    const eventArgs: EventArgs = {
      event_category: eventType,
      event_id: EventId.fetch(),
    }

    window.gtag('event', 'confirm_' + eventType, {
      ...eventArgs,
      ...args,
    })
  }

  const executeEvent = (eventType: UserEventType, args: UserEventArgs) => {
    if (_blockAnalytics() || !EventId.hasInstance()) return

    const eventArgs: EventArgs = {
      event_category: eventType,
      event_id: EventId.fetch(),
    }

    window.gtag('event', 'execute_' + eventType, {
      ...eventArgs,
      ...args,
    })
  }

  const abandonEvent = (destoryEventId: boolean, eventType: UserEventType, args: UserEventArgs) => {
    if (_blockAnalytics() || !EventId.hasInstance()) return

    const eventArgs: EventArgs = {
      event_category: eventType,
      event_id: EventId.fetch(),
    }

    window.gtag('event', 'abandon_' + eventType, {
      ...eventArgs,
      ...args,
    })

    if (destoryEventId) EventId.destroy()
  }

  const customEvent = (
    eventName: string,
    destoryEventId: boolean,
    eventType: UserEventType,
    args: UserEventArgs
  ) => {
    if (_blockAnalytics()) return

    const eventArgs: EventArgs = {
      event_category: eventType,
      event_id: EventId.fetch(),
    }

    window.gtag('event', eventName + '_' + eventType, {
      ...eventArgs,
      ...args,
    })

    if (destoryEventId) EventId.destroy()
  }

  return (
    <GoogleAnalyticsContext.Provider
      value={{
        connectWalletEvent,
        viewAccountEvent,
        initiateEvent,
        confirmEvent,
        executeEvent,
        abandonEvent,
        customEvent,
      }}
    >
      {children}
    </GoogleAnalyticsContext.Provider>
  )
}
