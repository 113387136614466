import { Route, Routes, useLocation } from 'react-router-dom'
import { useEffect } from 'react'

import { ROUTES } from './constants'
import {
  MarketDetailsPage,
  RuneDetailsPage,
  AccountDetailsPage,
  DemoWasmPage,
  EtchPage,
  MintPage,
  SettingsPage,
  MarketsPage,
} from './pages'
import { ScrollToTop } from './components'
import { getCurrentRoute, getCurrentRouteParam } from './utils'

const TITLES = {
  [ROUTES.home]: () => 'Mystic',
  [ROUTES.etch]: () => 'Etch',
  [ROUTES.mint]: () => 'Mint',
  [ROUTES.markets]: () => 'Markets',
  [ROUTES.marketDetails]: (runeName: string) => 'Market | ' + runeName,
  [ROUTES.runeDetails]: (runeName: string) => 'Rune Details | ' + runeName,
  [ROUTES.accountDetails]: () => 'Account Details',
  [ROUTES.demoWasm]: () => 'Demo Wasm',
  [ROUTES.settings]: () => 'Settings',
}

export function PageRoutes() {
  const location = useLocation()
  useEffect(() => {
    const route = getCurrentRoute(location.pathname)
    const title = route ? TITLES[route] : undefined
    const routeParam = getCurrentRouteParam(location.pathname)
    document.title = title ? title(routeParam ? decodeURIComponent(routeParam) : '') : 'Mystic'
  }, [location])

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path={ROUTES.home} element={<MarketsPage />} />
        <Route path={ROUTES.etch} element={<EtchPage />} />
        <Route path={ROUTES.markets} element={<MarketsPage />} />
        <Route path={ROUTES.mint} element={<MintPage />} />
        <Route path={ROUTES.marketDetails} element={<MarketDetailsPage />} />
        <Route path={ROUTES.runeDetails} element={<RuneDetailsPage />} />
        <Route path={ROUTES.accountDetails} element={<AccountDetailsPage />} />
        <Route path={ROUTES.demoWasm} element={<DemoWasmPage />} />
        <Route path={ROUTES.settings} element={<SettingsPage />} />
        <Route path='/*' element={<MarketsPage />} />
      </Routes>
    </>
  )
}
