import { Decimal } from 'decimal.js-light'
import { formatUnits } from 'ethers'

import { USD_DECIMALS } from '@packages/constants'

import { formatNumber } from './numbers'
import { parseToFloat } from './float'

export const MAX_SATS_PRICE_DECIMALS = 6
export const MAX_USD_PRICE_DECIMALS = 6
export const MINIMUM_USD_PRICE_DISPLAY = 0.00001

export function transformDbPriceSats({
  dbPrice,
  satPriceUsd,
  runeDecimals,
  format,
}: {
  dbPrice: number
  satPriceUsd: bigint
  runeDecimals: number
  format?: boolean
}): { priceSats: string; priceUsd: string } {
  const priceSats = new Decimal(dbPrice).mul(10 ** runeDecimals).toFixed(MAX_SATS_PRICE_DECIMALS)
  const priceUsd = new Decimal(`${satPriceUsd}`).mul(priceSats).div(10 ** USD_DECIMALS)
  const priceUsdNum = priceUsd.toNumber()
  if (format) {
    return {
      priceSats: formatNumber({ numStr: priceSats, showAllDecimals: true }),
      priceUsd: formatUsdPrice({ price: priceUsdNum }),
    }
  } else {
    return {
      priceSats,
      priceUsd: `${priceUsdNum}`,
    }
  }
}

export function priceSatsToPriceUsd({
  satPriceUsd,
  priceSats,
  format = false,
}: {
  satPriceUsd: number | string | bigint
  priceSats: number | string
  format?: boolean
}) {
  let price: Decimal
  if (typeof satPriceUsd === 'bigint') {
    price = new Decimal(`${satPriceUsd}`).mul(new Decimal(priceSats)).div(10 ** USD_DECIMALS)
  } else {
    price = new Decimal(`${satPriceUsd}`).mul(new Decimal(parseToFloat(priceSats)))
  }
  if (format) {
    return formatUsdPrice({ price: price.toNumber() })
  } else {
    return price.toString()
  }
}

export function formatUsdPrice({ price }: { price: number | string }) {
  const priceNum = typeof price === 'string' ? parseToFloat(price) : price
  if (priceNum === 0) {
    return '$0.00'
  }
  if (priceNum < MINIMUM_USD_PRICE_DISPLAY) {
    return `<$${MINIMUM_USD_PRICE_DISPLAY}`
  }
  return `$${formatNumber({
    numStr: price.toString(),
    maxDecimals: MAX_USD_PRICE_DECIMALS,
  })}`
}

export function calculateOrderPrice(params: {
  satPriceUsd: bigint
  satsAmount: bigint
  runesAmount: bigint
  runeDecimals: number
}): { priceSats: string; priceUsd: string } {
  const { priceSats } = calculateOrderPriceDecimal(params)
  return {
    priceSats: formatNumber({ numStr: priceSats.toFixed(MAX_SATS_PRICE_DECIMALS) }),
    priceUsd: priceSatsToPriceUsd({
      priceSats: priceSats.toNumber(),
      satPriceUsd: params.satPriceUsd,
      format: true,
    }),
  }
}

export function calculateOrderPriceDecimal({
  satPriceUsd,
  satsAmount,
  runesAmount,
  runeDecimals,
}: {
  satPriceUsd: bigint
  satsAmount: bigint
  runesAmount: bigint
  runeDecimals: number
}): { priceSats: Decimal; priceUsd: Decimal } {
  const sats = new Decimal(`${satsAmount}`)
  const runes = new Decimal(formatUnits(runesAmount, runeDecimals))
  const priceSats = sats.div(runes)
  const priceUsd = new Decimal(`${satPriceUsd}`).mul(priceSats).div(10 ** USD_DECIMALS)

  return {
    priceSats,
    priceUsd,
  }
}
