import { v4 as uuidv4 } from 'uuid'

const EventId = (function () {
  let instance = ''

  const generateUUID = () => uuidv4()

  return {
    hasInstance: function () {
      return instance !== ''
    },

    create: function () {
      if (instance == '') {
        instance = generateUUID()
        console.log('Event ID created:', instance)
      } else {
        console.log('Event ID already exists:', instance)
      }
      return instance
    },

    fetch: function () {
      if (instance == '') {
        return this.create()
      }
      return instance
    },

    destroy: function () {
      instance = ''
    },
  }
})()

export default EventId
