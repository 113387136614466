import { useParams } from 'react-router-dom'

import { RuneDetailsPageContent } from 'src/runes'

import { PageContainer } from './PageContainer'

export function RuneDetailsPage() {
  const { runeName } = useParams()

  if (typeof runeName == 'undefined') {
    return <PageContainer>Invalid Rune Name: {runeName}</PageContainer>
  }

  return (
    <PageContainer>
      <RuneDetailsPageContent runeName={runeName} />
    </PageContainer>
  )
}
