import { styled } from 'styled-components'

import { COLORS } from 'src/shared/constants'
import { hexToRgb } from 'src/shared/utils'

import { LoadingSkeleton } from '../LoadingSkeleton'

interface Props {
  header: React.ReactNode
  subHeader: React.ReactNode
  className?: string
  loading?: boolean
}

export function TieredTableCell({ header, subHeader, loading, className }: Props) {
  if (loading) {
    return (
      <Container className={className}>
        <Header>
          <LoadingSkeletonWrapper />
        </Header>
        <SubHeader>
          <LoadingSkeletonWrapper />
        </SubHeader>
      </Container>
    )
  }

  return (
    <Container className={className}>
      <Header>{header}</Header>
      <SubHeader>{subHeader}</SubHeader>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Header = styled.h4`
  font-weight: 700;
  font-size: 15px;
  padding-bottom: 2px;
`

const SubHeader = styled.h5`
  font-weight: 400;
  font-size: 14px !important;
  color: ${hexToRgb(COLORS.white, 0.7)} !important;
`

const LoadingSkeletonWrapper = styled(LoadingSkeleton)`
  border-radius: 5px;
  min-width: 100px;
`
