import { useState, useEffect, useRef } from 'react'

export function useDebouncedValue<T>(value: T, delay: number): [T, React.MutableRefObject<number>] {
  const [debouncedValue, setDebouncedValue] = useState(value)
  const callCounter = useRef(0)

  useEffect(() => {
    const handler = setTimeout(() => {
      if (debouncedValue !== value) {
        setDebouncedValue(value)
        callCounter.current += 1
      }
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return [debouncedValue, callCounter]
}
