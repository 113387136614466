let wasm;
export function __wbg_set_wasm(val) {
    wasm = val;
}


const heap = new Array(128).fill(undefined);

heap.push(undefined, null, true, false);

function getObject(idx) { return heap[idx]; }

let heap_next = heap.length;

function dropObject(idx) {
    if (idx < 132) return;
    heap[idx] = heap_next;
    heap_next = idx;
}

function takeObject(idx) {
    const ret = getObject(idx);
    dropObject(idx);
    return ret;
}

function addHeapObject(obj) {
    if (heap_next === heap.length) heap.push(heap.length + 1);
    const idx = heap_next;
    heap_next = heap[idx];

    heap[idx] = obj;
    return idx;
}

let WASM_VECTOR_LEN = 0;

let cachedUint8Memory0 = null;

function getUint8Memory0() {
    if (cachedUint8Memory0 === null || cachedUint8Memory0.byteLength === 0) {
        cachedUint8Memory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachedUint8Memory0;
}

const lTextEncoder = typeof TextEncoder === 'undefined' ? (0, module.require)('util').TextEncoder : TextEncoder;

let cachedTextEncoder = new lTextEncoder('utf-8');

const encodeString = (typeof cachedTextEncoder.encodeInto === 'function'
    ? function (arg, view) {
    return cachedTextEncoder.encodeInto(arg, view);
}
    : function (arg, view) {
    const buf = cachedTextEncoder.encode(arg);
    view.set(buf);
    return {
        read: arg.length,
        written: buf.length
    };
});

function passStringToWasm0(arg, malloc, realloc) {

    if (realloc === undefined) {
        const buf = cachedTextEncoder.encode(arg);
        const ptr = malloc(buf.length, 1) >>> 0;
        getUint8Memory0().subarray(ptr, ptr + buf.length).set(buf);
        WASM_VECTOR_LEN = buf.length;
        return ptr;
    }

    let len = arg.length;
    let ptr = malloc(len, 1) >>> 0;

    const mem = getUint8Memory0();

    let offset = 0;

    for (; offset < len; offset++) {
        const code = arg.charCodeAt(offset);
        if (code > 0x7F) break;
        mem[ptr + offset] = code;
    }

    if (offset !== len) {
        if (offset !== 0) {
            arg = arg.slice(offset);
        }
        ptr = realloc(ptr, len, len = offset + arg.length * 3, 1) >>> 0;
        const view = getUint8Memory0().subarray(ptr + offset, ptr + len);
        const ret = encodeString(arg, view);

        offset += ret.written;
        ptr = realloc(ptr, len, offset, 1) >>> 0;
    }

    WASM_VECTOR_LEN = offset;
    return ptr;
}

function isLikeNone(x) {
    return x === undefined || x === null;
}

let cachedInt32Memory0 = null;

function getInt32Memory0() {
    if (cachedInt32Memory0 === null || cachedInt32Memory0.byteLength === 0) {
        cachedInt32Memory0 = new Int32Array(wasm.memory.buffer);
    }
    return cachedInt32Memory0;
}

const lTextDecoder = typeof TextDecoder === 'undefined' ? (0, module.require)('util').TextDecoder : TextDecoder;

let cachedTextDecoder = new lTextDecoder('utf-8', { ignoreBOM: true, fatal: true });

cachedTextDecoder.decode();

function getStringFromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return cachedTextDecoder.decode(getUint8Memory0().subarray(ptr, ptr + len));
}

function debugString(val) {
    // primitive types
    const type = typeof val;
    if (type == 'number' || type == 'boolean' || val == null) {
        return  `${val}`;
    }
    if (type == 'string') {
        return `"${val}"`;
    }
    if (type == 'symbol') {
        const description = val.description;
        if (description == null) {
            return 'Symbol';
        } else {
            return `Symbol(${description})`;
        }
    }
    if (type == 'function') {
        const name = val.name;
        if (typeof name == 'string' && name.length > 0) {
            return `Function(${name})`;
        } else {
            return 'Function';
        }
    }
    // objects
    if (Array.isArray(val)) {
        const length = val.length;
        let debug = '[';
        if (length > 0) {
            debug += debugString(val[0]);
        }
        for(let i = 1; i < length; i++) {
            debug += ', ' + debugString(val[i]);
        }
        debug += ']';
        return debug;
    }
    // Test for built-in
    const builtInMatches = /\[object ([^\]]+)\]/.exec(toString.call(val));
    let className;
    if (builtInMatches.length > 1) {
        className = builtInMatches[1];
    } else {
        // Failed to match the standard '[object ClassName]'
        return toString.call(val);
    }
    if (className == 'Object') {
        // we're a user defined class or Object
        // JSON.stringify avoids problems with cycles, and is generally much
        // easier than looping through ownProperties of `val`.
        try {
            return 'Object(' + JSON.stringify(val) + ')';
        } catch (_) {
            return 'Object';
        }
    }
    // errors
    if (val instanceof Error) {
        return `${val.name}: ${val.message}\n${val.stack}`;
    }
    // TODO we could test for more things here, like `Set`s and `Map`s.
    return className;
}

function _assertClass(instance, klass) {
    if (!(instance instanceof klass)) {
        throw new Error(`expected instance of ${klass.name}`);
    }
    return instance.ptr;
}
/**
*/
export function init() {
    wasm.init();
}

let cachedBigInt64Memory0 = null;

function getBigInt64Memory0() {
    if (cachedBigInt64Memory0 === null || cachedBigInt64Memory0.byteLength === 0) {
        cachedBigInt64Memory0 = new BigInt64Array(wasm.memory.buffer);
    }
    return cachedBigInt64Memory0;
}

function getArrayU8FromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return getUint8Memory0().subarray(ptr / 1, ptr / 1 + len);
}
/**
* @param {string} id
* @param {number} pointer
* @returns {Uint8Array}
*/
export function encode_mint(id, pointer) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        const ptr0 = passStringToWasm0(id, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.encode_mint(retptr, ptr0, len0, pointer);
        var r0 = getInt32Memory0()[retptr / 4 + 0];
        var r1 = getInt32Memory0()[retptr / 4 + 1];
        var r2 = getInt32Memory0()[retptr / 4 + 2];
        var r3 = getInt32Memory0()[retptr / 4 + 3];
        if (r3) {
            throw takeObject(r2);
        }
        var v2 = getArrayU8FromWasm0(r0, r1).slice();
        wasm.__wbindgen_free(r0, r1 * 1, 1);
        return v2;
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}

let cachedUint32Memory0 = null;

function getUint32Memory0() {
    if (cachedUint32Memory0 === null || cachedUint32Memory0.byteLength === 0) {
        cachedUint32Memory0 = new Uint32Array(wasm.memory.buffer);
    }
    return cachedUint32Memory0;
}

function getArrayJsValueFromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    const mem = getUint32Memory0();
    const slice = mem.subarray(ptr / 4, ptr / 4 + len);
    const result = [];
    for (let i = 0; i < slice.length; i++) {
        result.push(takeObject(slice[i]));
    }
    return result;
}

function passArrayJsValueToWasm0(array, malloc) {
    const ptr = malloc(array.length * 4, 4) >>> 0;
    const mem = getUint32Memory0();
    for (let i = 0; i < array.length; i++) {
        mem[ptr / 4 + i] = addHeapObject(array[i]);
    }
    WASM_VECTOR_LEN = array.length;
    return ptr;
}
/**
* @param {string} id
* @param {bigint} amount
* @param {number} to_index
* @param {number} change_index
* @returns {Uint8Array}
*/
export function encode_simple_transfer(id, amount, to_index, change_index) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        const ptr0 = passStringToWasm0(id, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.encode_simple_transfer(retptr, ptr0, len0, addHeapObject(amount), to_index, change_index);
        var r0 = getInt32Memory0()[retptr / 4 + 0];
        var r1 = getInt32Memory0()[retptr / 4 + 1];
        var r2 = getInt32Memory0()[retptr / 4 + 2];
        var r3 = getInt32Memory0()[retptr / 4 + 3];
        if (r3) {
            throw takeObject(r2);
        }
        var v2 = getArrayU8FromWasm0(r0, r1).slice();
        wasm.__wbindgen_free(r0, r1 * 1, 1);
        return v2;
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}

function _assertChar(c) {
    if (typeof(c) === 'number' && (c >= 0x110000 || (c >= 0xD800 && c < 0xE000))) throw new Error(`expected a valid Unicode scalar value, found ${c}`);
}
/**
* @param {Uint8Array} tr_script
* @param {SpacedRune} name
* @returns {Uint8Array}
*/
export function encode_commitment(tr_script, name) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        _assertClass(name, SpacedRune);
        var ptr0 = name.__destroy_into_raw();
        wasm.encode_commitment(retptr, addHeapObject(tr_script), ptr0);
        var r0 = getInt32Memory0()[retptr / 4 + 0];
        var r1 = getInt32Memory0()[retptr / 4 + 1];
        var r2 = getInt32Memory0()[retptr / 4 + 2];
        if (r2) {
            throw takeObject(r1);
        }
        return takeObject(r0);
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}

/**
* @param {EtchingParams} params
* @returns {Uint8Array}
*/
export function encode_etching(params) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        _assertClass(params, EtchingParams);
        var ptr0 = params.__destroy_into_raw();
        wasm.encode_etching(retptr, ptr0);
        var r0 = getInt32Memory0()[retptr / 4 + 0];
        var r1 = getInt32Memory0()[retptr / 4 + 1];
        var r2 = getInt32Memory0()[retptr / 4 + 2];
        var r3 = getInt32Memory0()[retptr / 4 + 3];
        if (r3) {
            throw takeObject(r2);
        }
        var v2 = getArrayU8FromWasm0(r0, r1).slice();
        wasm.__wbindgen_free(r0, r1 * 1, 1);
        return v2;
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}

function handleError(f, args) {
    try {
        return f.apply(this, args);
    } catch (e) {
        wasm.__wbindgen_exn_store(addHeapObject(e));
    }
}

const ArtifactFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_artifact_free(ptr >>> 0));
/**
*/
export class Artifact {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Artifact.prototype);
        obj.__wbg_ptr = ptr;
        ArtifactFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    toJSON() {
        return {
        };
    }

    toString() {
        return JSON.stringify(this);
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        ArtifactFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_artifact_free(ptr);
    }
    /**
    * @param {Uint8Array} tx
    * @returns {Artifact}
    */
    static from_transaction(tx) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.artifact_from_transaction(retptr, addHeapObject(tx));
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var r2 = getInt32Memory0()[retptr / 4 + 2];
            if (r2) {
                throw takeObject(r1);
            }
            return Artifact.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {boolean}
    */
    is_cenotaph() {
        const ret = wasm.artifact_is_cenotaph(this.__wbg_ptr);
        return ret !== 0;
    }
    /**
    * @returns {boolean}
    */
    is_runestone() {
        const ret = wasm.artifact_is_runestone(this.__wbg_ptr);
        return ret !== 0;
    }
    /**
    * @returns {Runestone | undefined}
    */
    to_runestone() {
        const ret = wasm.artifact_to_runestone(this.__wbg_ptr);
        return ret === 0 ? undefined : Runestone.__wrap(ret);
    }
    /**
    * @returns {Cenotaph | undefined}
    */
    to_cenotaph() {
        const ret = wasm.artifact_to_cenotaph(this.__wbg_ptr);
        return ret === 0 ? undefined : Cenotaph.__wrap(ret);
    }
}

const CenotaphFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_cenotaph_free(ptr >>> 0));
/**
*/
export class Cenotaph {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Cenotaph.prototype);
        obj.__wbg_ptr = ptr;
        CenotaphFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    toJSON() {
        return {
            etching: this.etching,
            flaws: this.flaws,
            mint: this.mint,
        };
    }

    toString() {
        return JSON.stringify(this);
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        CenotaphFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_cenotaph_free(ptr);
    }
    /**
    * @returns {Rune | undefined}
    */
    get etching() {
        const ret = wasm.__wbg_get_cenotaph_etching(this.__wbg_ptr);
        return ret === 0 ? undefined : Rune.__wrap(ret);
    }
    /**
    * @param {Rune | undefined} [arg0]
    */
    set etching(arg0) {
        let ptr0 = 0;
        if (!isLikeNone(arg0)) {
            _assertClass(arg0, Rune);
            ptr0 = arg0.__destroy_into_raw();
        }
        wasm.__wbg_set_cenotaph_etching(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {number}
    */
    get flaws() {
        const ret = wasm.__wbg_get_cenotaph_flaws(this.__wbg_ptr);
        return ret >>> 0;
    }
    /**
    * @param {number} arg0
    */
    set flaws(arg0) {
        wasm.__wbg_set_cenotaph_flaws(this.__wbg_ptr, arg0);
    }
    /**
    * @returns {RuneId | undefined}
    */
    get mint() {
        const ret = wasm.__wbg_get_cenotaph_mint(this.__wbg_ptr);
        return ret === 0 ? undefined : RuneId.__wrap(ret);
    }
    /**
    * @param {RuneId | undefined} [arg0]
    */
    set mint(arg0) {
        let ptr0 = 0;
        if (!isLikeNone(arg0)) {
            _assertClass(arg0, RuneId);
            ptr0 = arg0.__destroy_into_raw();
        }
        wasm.__wbg_set_cenotaph_mint(this.__wbg_ptr, ptr0);
    }
}

const EdictFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_edict_free(ptr >>> 0));
/**
*/
export class Edict {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Edict.prototype);
        obj.__wbg_ptr = ptr;
        EdictFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    static __unwrap(jsValue) {
        if (!(jsValue instanceof Edict)) {
            return 0;
        }
        return jsValue.__destroy_into_raw();
    }

    toJSON() {
        return {
            id: this.id,
            amount: this.amount,
            output: this.output,
        };
    }

    toString() {
        return JSON.stringify(this);
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EdictFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_edict_free(ptr);
    }
    /**
    * @returns {RuneId}
    */
    get id() {
        const ret = wasm.__wbg_get_edict_id(this.__wbg_ptr);
        return RuneId.__wrap(ret);
    }
    /**
    * @param {RuneId} arg0
    */
    set id(arg0) {
        _assertClass(arg0, RuneId);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_edict_id(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {U128}
    */
    get amount() {
        const ret = wasm.__wbg_get_edict_amount(this.__wbg_ptr);
        return U128.__wrap(ret);
    }
    /**
    * @param {U128} arg0
    */
    set amount(arg0) {
        _assertClass(arg0, U128);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_edict_amount(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {number}
    */
    get output() {
        const ret = wasm.__wbg_get_edict_output(this.__wbg_ptr);
        return ret >>> 0;
    }
    /**
    * @param {number} arg0
    */
    set output(arg0) {
        wasm.__wbg_set_edict_output(this.__wbg_ptr, arg0);
    }
}

const EtchingFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_etching_free(ptr >>> 0));
/**
*/
export class Etching {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Etching.prototype);
        obj.__wbg_ptr = ptr;
        EtchingFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    toJSON() {
        return {
            divisibility: this.divisibility,
            premine: this.premine,
            rune: this.rune,
            spacers: this.spacers,
            symbol: this.symbol,
            terms: this.terms,
            turbo: this.turbo,
        };
    }

    toString() {
        return JSON.stringify(this);
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EtchingFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_etching_free(ptr);
    }
    /**
    * @returns {number | undefined}
    */
    get divisibility() {
        const ret = wasm.__wbg_get_etching_divisibility(this.__wbg_ptr);
        return ret === 0xFFFFFF ? undefined : ret;
    }
    /**
    * @param {number | undefined} [arg0]
    */
    set divisibility(arg0) {
        wasm.__wbg_set_etching_divisibility(this.__wbg_ptr, isLikeNone(arg0) ? 0xFFFFFF : arg0);
    }
    /**
    * @returns {U128 | undefined}
    */
    get premine() {
        const ret = wasm.__wbg_get_etching_premine(this.__wbg_ptr);
        return ret === 0 ? undefined : U128.__wrap(ret);
    }
    /**
    * @param {U128 | undefined} [arg0]
    */
    set premine(arg0) {
        let ptr0 = 0;
        if (!isLikeNone(arg0)) {
            _assertClass(arg0, U128);
            ptr0 = arg0.__destroy_into_raw();
        }
        wasm.__wbg_set_etching_premine(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {Rune | undefined}
    */
    get rune() {
        const ret = wasm.__wbg_get_etching_rune(this.__wbg_ptr);
        return ret === 0 ? undefined : Rune.__wrap(ret);
    }
    /**
    * @param {Rune | undefined} [arg0]
    */
    set rune(arg0) {
        let ptr0 = 0;
        if (!isLikeNone(arg0)) {
            _assertClass(arg0, Rune);
            ptr0 = arg0.__destroy_into_raw();
        }
        wasm.__wbg_set_etching_rune(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {number | undefined}
    */
    get spacers() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.__wbg_get_etching_spacers(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            return r0 === 0 ? undefined : r1 >>> 0;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @param {number | undefined} [arg0]
    */
    set spacers(arg0) {
        wasm.__wbg_set_etching_spacers(this.__wbg_ptr, !isLikeNone(arg0), isLikeNone(arg0) ? 0 : arg0);
    }
    /**
    * @returns {string | undefined}
    */
    get symbol() {
        const ret = wasm.__wbg_get_etching_symbol(this.__wbg_ptr);
        return ret === 0xFFFFFF ? undefined : String.fromCodePoint(ret);
    }
    /**
    * @param {string | undefined} [arg0]
    */
    set symbol(arg0) {
        const char0 = isLikeNone(arg0) ? 0xFFFFFF : arg0.codePointAt(0);
    if (char0 !== 0xFFFFFF) { _assertChar(char0); }
    wasm.__wbg_set_etching_symbol(this.__wbg_ptr, char0);
}
/**
* @returns {Terms | undefined}
*/
get terms() {
    const ret = wasm.__wbg_get_etching_terms(this.__wbg_ptr);
    return ret === 0 ? undefined : Terms.__wrap(ret);
}
/**
* @param {Terms | undefined} [arg0]
*/
set terms(arg0) {
    let ptr0 = 0;
    if (!isLikeNone(arg0)) {
        _assertClass(arg0, Terms);
        ptr0 = arg0.__destroy_into_raw();
    }
    wasm.__wbg_set_etching_terms(this.__wbg_ptr, ptr0);
}
/**
* @returns {boolean}
*/
get turbo() {
    const ret = wasm.__wbg_get_etching_turbo(this.__wbg_ptr);
    return ret !== 0;
}
/**
* @param {boolean} arg0
*/
set turbo(arg0) {
    wasm.__wbg_set_etching_turbo(this.__wbg_ptr, arg0);
}
}

const EtchingParamsFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_etchingparams_free(ptr >>> 0));
/**
*/
export class EtchingParams {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EtchingParamsFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_etchingparams_free(ptr);
    }
    /**
    * @returns {number}
    */
    get divisibility() {
        const ret = wasm.__wbg_get_etchingparams_divisibility(this.__wbg_ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set divisibility(arg0) {
        wasm.__wbg_set_etchingparams_divisibility(this.__wbg_ptr, arg0);
    }
    /**
    * @returns {U128}
    */
    get premine() {
        const ret = wasm.__wbg_get_etchingparams_premine(this.__wbg_ptr);
        return U128.__wrap(ret);
    }
    /**
    * @param {U128} arg0
    */
    set premine(arg0) {
        _assertClass(arg0, U128);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_etchingparams_premine(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {number | undefined}
    */
    get premine_vout() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.__wbg_get_etchingparams_premine_vout(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            return r0 === 0 ? undefined : r1 >>> 0;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @param {number | undefined} [arg0]
    */
    set premine_vout(arg0) {
        wasm.__wbg_set_etchingparams_premine_vout(this.__wbg_ptr, !isLikeNone(arg0), isLikeNone(arg0) ? 0 : arg0);
    }
    /**
    * @returns {SpacedRune | undefined}
    */
    get rune() {
        const ret = wasm.__wbg_get_etchingparams_rune(this.__wbg_ptr);
        return ret === 0 ? undefined : SpacedRune.__wrap(ret);
    }
    /**
    * @param {SpacedRune | undefined} [arg0]
    */
    set rune(arg0) {
        let ptr0 = 0;
        if (!isLikeNone(arg0)) {
            _assertClass(arg0, SpacedRune);
            ptr0 = arg0.__destroy_into_raw();
        }
        wasm.__wbg_set_etchingparams_rune(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {string | undefined}
    */
    get symbol() {
        const ret = wasm.__wbg_get_etching_symbol(this.__wbg_ptr);
        return ret === 0xFFFFFF ? undefined : String.fromCodePoint(ret);
    }
    /**
    * @param {string | undefined} [arg0]
    */
    set symbol(arg0) {
        const char0 = isLikeNone(arg0) ? 0xFFFFFF : arg0.codePointAt(0);
    if (char0 !== 0xFFFFFF) { _assertChar(char0); }
    wasm.__wbg_set_etching_symbol(this.__wbg_ptr, char0);
}
/**
* @returns {Terms | undefined}
*/
get terms() {
    const ret = wasm.__wbg_get_etchingparams_terms(this.__wbg_ptr);
    return ret === 0 ? undefined : Terms.__wrap(ret);
}
/**
* @param {Terms | undefined} [arg0]
*/
set terms(arg0) {
    let ptr0 = 0;
    if (!isLikeNone(arg0)) {
        _assertClass(arg0, Terms);
        ptr0 = arg0.__destroy_into_raw();
    }
    wasm.__wbg_set_etchingparams_terms(this.__wbg_ptr, ptr0);
}
/**
* @returns {boolean}
*/
get turbo() {
    const ret = wasm.__wbg_get_etchingparams_turbo(this.__wbg_ptr);
    return ret !== 0;
}
/**
* @param {boolean} arg0
*/
set turbo(arg0) {
    wasm.__wbg_set_etchingparams_turbo(this.__wbg_ptr, arg0);
}
/**
*/
constructor() {
    const ret = wasm.etchingparams_new();
    this.__wbg_ptr = ret >>> 0;
    return this;
}
}

const RuneFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_rune_free(ptr >>> 0));
/**
*/
export class Rune {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Rune.prototype);
        obj.__wbg_ptr = ptr;
        RuneFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    toJSON() {
        return {
        };
    }

    toString() {
        return JSON.stringify(this);
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        RuneFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_rune_free(ptr);
    }
    /**
    * @param {string} network
    * @param {number} height
    * @returns {U128}
    */
    static minimum_at_height(network, height) {
        const ptr0 = passStringToWasm0(network, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.rune_minimum_at_height(ptr0, len0, height);
        return U128.__wrap(ret);
    }
    /**
    * @param {string} name
    * @param {string} network
    * @param {number} height
    * @returns {boolean}
    */
    static is_name_valid_at_height(name, network, height) {
        const ptr0 = passStringToWasm0(name, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ptr1 = passStringToWasm0(network, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len1 = WASM_VECTOR_LEN;
        const ret = wasm.rune_is_name_valid_at_height(ptr0, len0, ptr1, len1, height);
        return ret !== 0;
    }
    /**
    * @param {string} network
    * @returns {number}
    */
    static first_rune_height(network) {
        const ptr0 = passStringToWasm0(network, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.rune_first_rune_height(ptr0, len0);
        return ret >>> 0;
    }
    /**
    * @param {U128} n
    * @returns {Rune}
    */
    static from_num(n) {
        _assertClass(n, U128);
        var ptr0 = n.__destroy_into_raw();
        const ret = wasm.rune_from_num(ptr0);
        return Rune.__wrap(ret);
    }
    /**
    * @param {bigint} n
    * @returns {Rune}
    */
    static from_bigint(n) {
        const ret = wasm.rune_from_bigint(addHeapObject(n));
        return Rune.__wrap(ret);
    }
    /**
    * @param {string} s
    * @returns {Rune}
    */
    static from_string(s) {
        const ptr0 = passStringToWasm0(s, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.rune_from_string(ptr0, len0);
        return Rune.__wrap(ret);
    }
    /**
    * @returns {U128}
    */
    to_num() {
        const ret = wasm.rune_to_num(this.__wbg_ptr);
        return U128.__wrap(ret);
    }
    /**
    * @returns {bigint}
    */
    to_bigint() {
        const ret = wasm.rune_to_bigint(this.__wbg_ptr);
        return takeObject(ret);
    }
    /**
    * @returns {string}
    */
    to_string() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.rune_to_string(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @returns {boolean}
    */
    is_reserved() {
        const ret = wasm.rune_is_reserved(this.__wbg_ptr);
        return ret !== 0;
    }
}

const RuneIdFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_runeid_free(ptr >>> 0));
/**
*/
export class RuneId {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(RuneId.prototype);
        obj.__wbg_ptr = ptr;
        RuneIdFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    toJSON() {
        return {
            block: this.block,
            tx: this.tx,
        };
    }

    toString() {
        return JSON.stringify(this);
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        RuneIdFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_runeid_free(ptr);
    }
    /**
    * @param {string} id
    */
    constructor(id) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(id, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.runeid_new(retptr, ptr0, len0);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var r2 = getInt32Memory0()[retptr / 4 + 2];
            if (r2) {
                throw takeObject(r1);
            }
            this.__wbg_ptr = r0 >>> 0;
            return this;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {bigint}
    */
    get block() {
        const ret = wasm.runeid_block(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
    * @returns {number}
    */
    get tx() {
        const ret = wasm.runeid_tx(this.__wbg_ptr);
        return ret >>> 0;
    }
    /**
    * @param {bigint} block
    */
    set block(block) {
        wasm.runeid_set_block(this.__wbg_ptr, block);
    }
    /**
    * @param {number} tx
    */
    set tx(tx) {
        wasm.runeid_set_tx(this.__wbg_ptr, tx);
    }
    /**
    * @returns {string}
    */
    to_string() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.runeid_to_string(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @param {string} id
    * @returns {RuneId}
    */
    static from_string(id) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(id, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.runeid_from_string(retptr, ptr0, len0);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var r2 = getInt32Memory0()[retptr / 4 + 2];
            if (r2) {
                throw takeObject(r1);
            }
            return RuneId.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const RunestoneFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_runestone_free(ptr >>> 0));
/**
*/
export class Runestone {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Runestone.prototype);
        obj.__wbg_ptr = ptr;
        RunestoneFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    toJSON() {
        return {
            etching: this.etching,
            mint: this.mint,
            pointer: this.pointer,
            edicts: this.edicts,
        };
    }

    toString() {
        return JSON.stringify(this);
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        RunestoneFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_runestone_free(ptr);
    }
    /**
    * @returns {Etching | undefined}
    */
    get etching() {
        const ret = wasm.__wbg_get_runestone_etching(this.__wbg_ptr);
        return ret === 0 ? undefined : Etching.__wrap(ret);
    }
    /**
    * @param {Etching | undefined} [arg0]
    */
    set etching(arg0) {
        let ptr0 = 0;
        if (!isLikeNone(arg0)) {
            _assertClass(arg0, Etching);
            ptr0 = arg0.__destroy_into_raw();
        }
        wasm.__wbg_set_runestone_etching(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {RuneId | undefined}
    */
    get mint() {
        const ret = wasm.__wbg_get_runestone_mint(this.__wbg_ptr);
        return ret === 0 ? undefined : RuneId.__wrap(ret);
    }
    /**
    * @param {RuneId | undefined} [arg0]
    */
    set mint(arg0) {
        let ptr0 = 0;
        if (!isLikeNone(arg0)) {
            _assertClass(arg0, RuneId);
            ptr0 = arg0.__destroy_into_raw();
        }
        wasm.__wbg_set_runestone_mint(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {number | undefined}
    */
    get pointer() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.__wbg_get_runestone_pointer(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            return r0 === 0 ? undefined : r1 >>> 0;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @param {number | undefined} [arg0]
    */
    set pointer(arg0) {
        wasm.__wbg_set_runestone_pointer(this.__wbg_ptr, !isLikeNone(arg0), isLikeNone(arg0) ? 0 : arg0);
    }
    /**
    * @returns {(Edict)[]}
    */
    get edicts() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.runestone_edicts(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var v1 = getArrayJsValueFromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 4, 4);
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @param {(Edict)[]} edicts
    */
    set edicts(edicts) {
        const ptr0 = passArrayJsValueToWasm0(edicts, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.runestone_set_edicts(this.__wbg_ptr, ptr0, len0);
    }
}

const SpacedRuneFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_spacedrune_free(ptr >>> 0));
/**
*/
export class SpacedRune {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(SpacedRune.prototype);
        obj.__wbg_ptr = ptr;
        SpacedRuneFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    toJSON() {
        return {
        };
    }

    toString() {
        return JSON.stringify(this);
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        SpacedRuneFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_spacedrune_free(ptr);
    }
    /**
    * @param {U128} n
    * @returns {SpacedRune}
    */
    static from_num(n) {
        _assertClass(n, U128);
        var ptr0 = n.__destroy_into_raw();
        const ret = wasm.spacedrune_from_num(ptr0);
        return SpacedRune.__wrap(ret);
    }
    /**
    * @param {bigint} n
    * @returns {SpacedRune}
    */
    static from_bigint(n) {
        const ret = wasm.spacedrune_from_bigint(addHeapObject(n));
        return SpacedRune.__wrap(ret);
    }
    /**
    * @returns {bigint}
    */
    to_bigint() {
        const ret = wasm.spacedrune_to_bigint(this.__wbg_ptr);
        return takeObject(ret);
    }
    /**
    * @returns {string}
    */
    to_string() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.spacedrune_to_string(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @param {string} s
    * @returns {SpacedRune}
    */
    static from_string(s) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(s, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.spacedrune_from_string(retptr, ptr0, len0);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var r2 = getInt32Memory0()[retptr / 4 + 2];
            if (r2) {
                throw takeObject(r1);
            }
            return SpacedRune.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {boolean}
    */
    is_reserved() {
        const ret = wasm.spacedrune_is_reserved(this.__wbg_ptr);
        return ret !== 0;
    }
    /**
    * @param {string} name
    * @param {string} network
    * @param {number} height
    * @returns {boolean}
    */
    static is_name_valid_at_height(name, network, height) {
        const ptr0 = passStringToWasm0(name, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ptr1 = passStringToWasm0(network, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len1 = WASM_VECTOR_LEN;
        const ret = wasm.spacedrune_is_name_valid_at_height(ptr0, len0, ptr1, len1, height);
        return ret !== 0;
    }
}

const TermsFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_terms_free(ptr >>> 0));
/**
*/
export class Terms {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Terms.prototype);
        obj.__wbg_ptr = ptr;
        TermsFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    toJSON() {
        return {
            cap: this.cap,
            amount: this.amount,
            absolute_open_height: this.absolute_open_height,
            absolute_close_height: this.absolute_close_height,
            relative_open_height: this.relative_open_height,
            relative_close_height: this.relative_close_height,
        };
    }

    toString() {
        return JSON.stringify(this);
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        TermsFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_terms_free(ptr);
    }
    /**
    * @returns {U128 | undefined}
    */
    get cap() {
        const ret = wasm.__wbg_get_terms_cap(this.__wbg_ptr);
        return ret === 0 ? undefined : U128.__wrap(ret);
    }
    /**
    * @param {U128 | undefined} [arg0]
    */
    set cap(arg0) {
        let ptr0 = 0;
        if (!isLikeNone(arg0)) {
            _assertClass(arg0, U128);
            ptr0 = arg0.__destroy_into_raw();
        }
        wasm.__wbg_set_terms_cap(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {U128 | undefined}
    */
    get amount() {
        const ret = wasm.__wbg_get_terms_amount(this.__wbg_ptr);
        return ret === 0 ? undefined : U128.__wrap(ret);
    }
    /**
    * @param {U128 | undefined} [arg0]
    */
    set amount(arg0) {
        let ptr0 = 0;
        if (!isLikeNone(arg0)) {
            _assertClass(arg0, U128);
            ptr0 = arg0.__destroy_into_raw();
        }
        wasm.__wbg_set_terms_amount(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {bigint | undefined}
    */
    get absolute_open_height() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.__wbg_get_terms_absolute_open_height(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r2 = getBigInt64Memory0()[retptr / 8 + 1];
            return r0 === 0 ? undefined : BigInt.asUintN(64, r2);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @param {bigint | undefined} [arg0]
    */
    set absolute_open_height(arg0) {
        wasm.__wbg_set_terms_absolute_open_height(this.__wbg_ptr, !isLikeNone(arg0), isLikeNone(arg0) ? BigInt(0) : arg0);
    }
    /**
    * @returns {bigint | undefined}
    */
    get absolute_close_height() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.__wbg_get_terms_absolute_close_height(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r2 = getBigInt64Memory0()[retptr / 8 + 1];
            return r0 === 0 ? undefined : BigInt.asUintN(64, r2);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @param {bigint | undefined} [arg0]
    */
    set absolute_close_height(arg0) {
        wasm.__wbg_set_terms_absolute_close_height(this.__wbg_ptr, !isLikeNone(arg0), isLikeNone(arg0) ? BigInt(0) : arg0);
    }
    /**
    * @returns {bigint | undefined}
    */
    get relative_open_height() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.__wbg_get_terms_relative_open_height(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r2 = getBigInt64Memory0()[retptr / 8 + 1];
            return r0 === 0 ? undefined : BigInt.asUintN(64, r2);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @param {bigint | undefined} [arg0]
    */
    set relative_open_height(arg0) {
        wasm.__wbg_set_terms_relative_open_height(this.__wbg_ptr, !isLikeNone(arg0), isLikeNone(arg0) ? BigInt(0) : arg0);
    }
    /**
    * @returns {bigint | undefined}
    */
    get relative_close_height() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.__wbg_get_terms_relative_close_height(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r2 = getBigInt64Memory0()[retptr / 8 + 1];
            return r0 === 0 ? undefined : BigInt.asUintN(64, r2);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @param {bigint | undefined} [arg0]
    */
    set relative_close_height(arg0) {
        wasm.__wbg_set_terms_relative_close_height(this.__wbg_ptr, !isLikeNone(arg0), isLikeNone(arg0) ? BigInt(0) : arg0);
    }
    /**
    */
    constructor() {
        const ret = wasm.terms_new();
        this.__wbg_ptr = ret >>> 0;
        return this;
    }
}

const U128Finalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_u128_free(ptr >>> 0));
/**
*/
export class U128 {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(U128.prototype);
        obj.__wbg_ptr = ptr;
        U128Finalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    toJSON() {
        return {
        };
    }

    toString() {
        return JSON.stringify(this);
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        U128Finalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_u128_free(ptr);
    }
    /**
    * @returns {bigint}
    */
    to_bigint() {
        const ret = wasm.u128_to_bigint(this.__wbg_ptr);
        return takeObject(ret);
    }
    /**
    * @param {bigint} n
    * @returns {U128}
    */
    static from_bigint(n) {
        const ret = wasm.u128_from_bigint(addHeapObject(n));
        return U128.__wrap(ret);
    }
    /**
    * @returns {string}
    */
    to_string() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.u128_to_string(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @param {string} str
    * @returns {U128}
    */
    static from_string(str) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(str, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.u128_from_string(retptr, ptr0, len0);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var r2 = getInt32Memory0()[retptr / 4 + 2];
            if (r2) {
                throw takeObject(r1);
            }
            return U128.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

export function __wbg_edict_new(arg0) {
    const ret = Edict.__wrap(arg0);
    return addHeapObject(ret);
};

export function __wbg_edict_unwrap(arg0) {
    const ret = Edict.__unwrap(takeObject(arg0));
    return ret;
};

export function __wbindgen_object_drop_ref(arg0) {
    takeObject(arg0);
};

export function __wbindgen_bigint_from_u128(arg0, arg1) {
    const ret = BigInt.asUintN(64, arg0) << BigInt(64) | BigInt.asUintN(64, arg1);
    return addHeapObject(ret);
};

export function __wbindgen_string_get(arg0, arg1) {
    const obj = getObject(arg1);
    const ret = typeof(obj) === 'string' ? obj : undefined;
    var ptr1 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
};

export function __wbindgen_string_new(arg0, arg1) {
    const ret = getStringFromWasm0(arg0, arg1);
    return addHeapObject(ret);
};

export function __wbg_new_abda76e883ba8a5f() {
    const ret = new Error();
    return addHeapObject(ret);
};

export function __wbg_stack_658279fe44541cf6(arg0, arg1) {
    const ret = getObject(arg1).stack;
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
};

export function __wbg_error_f851667af71bcfc6(arg0, arg1) {
    let deferred0_0;
    let deferred0_1;
    try {
        deferred0_0 = arg0;
        deferred0_1 = arg1;
        console.error(getStringFromWasm0(arg0, arg1));
    } finally {
        wasm.__wbindgen_free(deferred0_0, deferred0_1, 1);
    }
};

export function __wbg_toString_66be3c8e1c6a7c76() { return handleError(function (arg0, arg1) {
    const ret = getObject(arg0).toString(arg1);
    return addHeapObject(ret);
}, arguments) };

export function __wbg_buffer_12d079cc21e14bdb(arg0) {
    const ret = getObject(arg0).buffer;
    return addHeapObject(ret);
};

export function __wbg_newwithbyteoffsetandlength_aa4a17c33a06e5cb(arg0, arg1, arg2) {
    const ret = new Uint8Array(getObject(arg0), arg1 >>> 0, arg2 >>> 0);
    return addHeapObject(ret);
};

export function __wbg_new_63b92bc8671ed464(arg0) {
    const ret = new Uint8Array(getObject(arg0));
    return addHeapObject(ret);
};

export function __wbg_set_a47bac70306a19a7(arg0, arg1, arg2) {
    getObject(arg0).set(getObject(arg1), arg2 >>> 0);
};

export function __wbg_length_c20a40f15020d68a(arg0) {
    const ret = getObject(arg0).length;
    return ret;
};

export function __wbindgen_debug_string(arg0, arg1) {
    const ret = debugString(getObject(arg1));
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
};

export function __wbindgen_throw(arg0, arg1) {
    throw new Error(getStringFromWasm0(arg0, arg1));
};

export function __wbindgen_memory() {
    const ret = wasm.memory;
    return addHeapObject(ret);
};

