import Alert from '@mui/material/Alert'
import { styled } from 'styled-components'

import { COLORS, FONTS } from '../constants'

interface Props {
  message: string
  className?: string
}
export function ErrorMessage({ message, className }: Props) {
  return (
    <AlertWrapper severity='error' className={className}>
      {message}
    </AlertWrapper>
  )
}

const AlertWrapper = styled(Alert)`
  background-color: ${COLORS.negative};
  font-weight: 500;
  font-family: ${FONTS.text};
  svg {
    color: ${COLORS.white};
  }
`
