import JSONbig from '@cardanosolutions/json-bigint'

export function writeItemToLocalStorage(key: string, value: any) {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(key, JSONbig.stringify(value))
  }
}

export function readItemFromLocalStorage(key: string, initialValue: any) {
  // Get from local storage by key
  const item = window.localStorage.getItem(key)
  // Parse stored json or if none return initialValue
  return item ? JSONbig.parse(item) : initialValue
}

export function deleteItemFromLocalStorage(key: string) {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem(key)
  }
}
