import { TIMEFRAMES } from '@packages/constants'

export const TIMEFRAME_LABELS: {
  [timeframe in TIMEFRAMES]: { label: string; valueHours: number; timeframe: TIMEFRAMES }
} = {
  '1h': { label: '1H', valueHours: 1, timeframe: '1h' },
  '24h': { label: '1D', valueHours: 24, timeframe: '24h' },
  '7d': { label: '1W', valueHours: 24 * 7, timeframe: '7d' },
  // ONE_MONTH: { label: '1M', valueHours: 24 * 30 },
}
