export function normalizePercent(
  minVal: number,
  maxVal: number,
  newMin: number = 5,
  newMax: number = 100,
) {
  return function (val: number) {
    return newMin + ((val - minVal) * (newMax - newMin)) / (maxVal - minVal)
  }
}
