export const WALLET_NAME = {
  xverse: 'Xverse',
  magicEden: 'Magic Eden',
  unisat: 'Unisat',
  leather: 'Leather',
  okx: 'OKX',
  default: 'default',
} as const

export type WalletName = (typeof WALLET_NAME)[keyof typeof WALLET_NAME]
