import { styled } from 'styled-components'
import { useEffect, useState } from 'react'

import { normalizeRuneName } from '@packages/utils'

import { ErrorMessage, LargeButtonGroup, LoadingSkeleton } from 'src/shared/components'
import {
  OrderHistoryTable,
  OpenSellOrdersTable,
  RuneSwapper,
  OpenOrdersByAddress,
  OrderFooterMenu,
  OrderHistoryByAddress,
  useOrderContext,
  RuneOutpointsTable,
} from 'src/orders'
import { useWalletContext } from 'src/wallet'
import { buildMarketDetailsUrl } from 'src/pages'
import { BREAKPOINTS } from 'src/shared/constants'
import { useIsMobile } from 'src/shared/hooks'

import { MarketOverviewSection } from './MarketOverviewSection'

export const MARKET_DETAILS_TABS = {
  myRuneOutpoints: 'My Lots',
  orderbook: 'Orderbook',
  orderHistory: 'Trade History',
  myOpenOrders: 'My Open Orders',
  myOrderHistory: 'My Order History',
}

const defaultTabOptions = Object.values(MARKET_DETAILS_TABS).map((value) => ({
  value: value,
  label: value,
}))

interface Props {
  runeName: string
}

export function MarketDetailsPageContent({ runeName }: Props) {
  const {
    isBuying,
    selectedRune,
    selectedRuneName,
    selectedRuneDetails,
    marketFetchError,
    detailsFetchError,

    setSelectedRuneName,
    isLoadingNewRune,
  } = useOrderContext()
  const isMobile = useIsMobile()

  const [tabOptions, setTabOptions] = useState(defaultTabOptions)
  const [selectedTab, setSelectedTab] = useState(MARKET_DETAILS_TABS.orderbook)

  const { runesAddress } = useWalletContext()

  useEffect(() => {
    setSelectedRuneName(runeName)
  }, [runeName])

  useEffect(() => {
    if (isBuying) {
      setSelectedTab(MARKET_DETAILS_TABS.orderbook)
    } else {
      setSelectedTab(MARKET_DETAILS_TABS.myRuneOutpoints)
    }
  }, [isBuying])

  useEffect(() => {
    if (selectedRuneName && (!runeName || normalizeRuneName(selectedRuneName) !== runeName)) {
      // Update the URL with the selected rune name without causing a page reload
      window.history.replaceState(
        null,
        'Mystic',
        buildMarketDetailsUrl(isMobile ? normalizeRuneName(selectedRuneName) : selectedRuneName)
      )
    }
  }, [selectedRuneName])

  useEffect(() => {
    setTabOptions(
      defaultTabOptions.filter((option) => {
        if (!runesAddress) {
          if (
            option.value === MARKET_DETAILS_TABS.myOpenOrders ||
            option.value === MARKET_DETAILS_TABS.myOrderHistory ||
            option.value === MARKET_DETAILS_TABS.myRuneOutpoints
          ) {
            setSelectedTab(MARKET_DETAILS_TABS.orderbook)
            return false
          }
        }

        return true
      })
    )
  }, [runesAddress?.addrString])

  function handleTabOnChange(value: string) {
    setSelectedTab(value)
  }

  if (
    isLoadingNewRune ||
    !selectedRune ||
    !selectedRuneDetails ||
    !!marketFetchError ||
    !!detailsFetchError
  ) {
    return (
      <Container>
        <RuneSwapper />
        {marketFetchError && (
          <ErrorContainer>
            <ErrorMessage message={marketFetchError} />
          </ErrorContainer>
        )}
        {detailsFetchError && (
          <ErrorContainer>
            <ErrorMessage message={detailsFetchError} />
          </ErrorContainer>
        )}
        <MarketOverviewSection />
        <LargeButtonGroupWrapper
          options={tabOptions}
          value={selectedTab}
          onChange={handleTabOnChange}
          exclusive
          updateSearchParams
        />
        <TabbedContentContainer>
          {isLoadingNewRune && <LoadingTabbedContentSkeleton height={600} />}
        </TabbedContentContainer>
      </Container>
    )
  }

  let tabbedContent = <OpenSellOrdersTable />

  if (selectedTab === MARKET_DETAILS_TABS.orderHistory) {
    tabbedContent = <OrderHistoryTable rune={selectedRuneDetails} />
  } else if (selectedTab === MARKET_DETAILS_TABS.myOpenOrders) {
    tabbedContent = (
      <OpenOrdersByAddress
        address={runesAddress?.addrString as string}
        runeId={selectedRune.runeId}
      />
    )
  } else if (selectedTab === MARKET_DETAILS_TABS.myOrderHistory) {
    tabbedContent = (
      <OrderHistoryByAddress
        address={runesAddress?.addrString as string}
        runeId={selectedRune.runeId}
      />
    )
  } else if (selectedTab === MARKET_DETAILS_TABS.myRuneOutpoints) {
    tabbedContent = <RuneOutpointsTable rune={selectedRune} />
  }

  return (
    <Container>
      <RuneSwapper />
      <MarketOverviewSection />
      <LargeButtonGroupWrapper
        options={tabOptions}
        value={selectedTab}
        onChange={handleTabOnChange}
        exclusive
        updateSearchParams
      />
      <TabbedContentContainer>{tabbedContent}</TabbedContentContainer>
      <OrderFooterMenu />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    gap: 15px;
  }
`

const LargeButtonGroupWrapper = styled(LargeButtonGroup)`
  button {
    @media (max-width: ${BREAKPOINTS.small}) {
      flex: 45%;
    }
  }
`

const TabbedContentContainer = styled.div``

const LoadingSkeletonWrapper = styled(LoadingSkeleton)`
  transform: none;
`

const LoadingTabbedContentSkeleton = styled(LoadingSkeletonWrapper)``

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
`
