import { Unstable_NumberInput as BaseNumberInput } from '@mui/base/Unstable_NumberInput'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import { styled } from 'styled-components'

import { COLORS, FONTS } from 'src/shared/constants'
import { hexToRgb } from 'src/shared/utils'

import { BUTTON_HOVER_STYLES } from '../buttons'

interface Props {
  onChange: (val: number) => void
  value: number
  minValue?: number
  maxValue?: number
  className?: string
}

export function NumberInputWithButtons({
  onChange,
  value,
  minValue = 0,
  maxValue,
  className,
}: Props) {
  function handleOnInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    let parsedValue: number | undefined
    if (event.target.value.trim().length != 0) {
      parsedValue = parseInt(event.target.value)
    }
    if (parsedValue && !isNaN(parsedValue)) {
      onChange(parsedValue)
    }
  }

  function handleChange(
    _event: React.FocusEvent<HTMLInputElement> | React.PointerEvent | React.KeyboardEvent,
    newValue: number | null,
  ) {
    if (newValue !== null) {
      onChange(newValue)
    }
  }

  return (
    <BaseNumberInput
      className={className}
      onInputChange={handleOnInputChange}
      onChange={handleChange}
      value={value}
      min={minValue}
      max={maxValue}
      slots={{
        root: StyledInputRoot,
        input: StyledInput,
        incrementButton: StyledButton,
        decrementButton: StyledButton,
      }}
      slotProps={{
        incrementButton: {
          children: <AddIcon fontSize='small' />,
          className: 'increment',
        },
        decrementButton: {
          children: <RemoveIcon fontSize='small' />,
        },
      }}
    />
  )
}

const blue = '#3399ff'

const StyledInputRoot = styled.div`
  font-family: ${FONTS.text};
  font-weight: 400;
  color: ${COLORS.black};
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`

const StyledInput = styled.input`
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.375;
  color: ${COLORS.black};
  background: ${COLORS.white};
  border: 1px solid ${COLORS.white};
  border-radius: 8px;
  margin: 0 8px;
  padding: 10px 12px;
  outline: 0;
  min-width: 0;
  width: 4rem;
  text-align: center;

  &:focus {
    border: 1px solid ${blue};
  }

  &:focus-visible {
    outline: 0;
  }
`

const StyledButton = styled.button`
  font-family: ${FONTS.text};
  font-size: 0.875rem;
  box-sizing: border-box;
  line-height: 1.5;
  border: 1px solid;
  border-radius: 999px;
  border-color: none;
  background: ${COLORS.white};
  color: ${COLORS.black};
  width: 32px;
  height: 32px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  ${BUTTON_HOVER_STYLES}
  &:hover {
    background: ${COLORS.link};
  }

  &:disabled {
    background: ${hexToRgb(COLORS.white, 0.6)};
    cursor: default;
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
  }
`
