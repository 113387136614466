export function formatRuneName(rune: {
  runeName: string
  runeNameSpacerIndices?: bigint[]
}): string {
  return `${
    rune.runeNameSpacerIndices
      ? insertSpacers(rune.runeName, rune.runeNameSpacerIndices)
      : rune.runeName
  }`
}

function insertSpacers(runeName: string, spacerIndices: bigint[]) {
  let result = runeName
  let offset = 0
  for (const bigIntIndex of spacerIndices) {
    const index = Number(bigIntIndex)
    result = result.substring(0, index + offset) + '•' + result.substring(index + offset)
    offset++
  }
  return result
}

export function normalizeRuneName(runeName: string): string {
  return runeName.toUpperCase().replace(/[^A-Z]/g, '')
}
