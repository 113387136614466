import { TextInput, TextInputProps } from 'src/shared/components'

const RUNE_NAME_REGEX = /^[A-Za-z •.]+$/

export function RuneNameInput({ value, onChange, ...props }: TextInputProps) {
  function handleOnChange(value: string) {
    if (RUNE_NAME_REGEX.test(value) || value.trim() === '') {
      onChange(value)
    }
  }
  return <TextInput value={value} onChange={handleOnChange} {...props} />
}
