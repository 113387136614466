/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Address,
  AddressPurpose,
  BitcoinProvider,
  Params,
  Requests,
  RpcResponse,
  WalletEvent,
} from 'sats-connect'
import { getAddressInfo } from 'bitcoin-address-validation'

import { hexToBase64 } from 'src/shared/utils'

import { CustomBitcoinProvider } from './CustomBitcoinProvider'
import {
  decodeSendBitcoinRequest,
  decodeSignMessageRequest,
  decodeSignMultipleTransactionRequest,
  decodeSignTransactionRequest,
} from '../utils'
import { AccountChangeEvent, addListener, EventListeners } from '../interfaces'

declare global {
  interface Window {
    unisat: UnisatAPI
  }
}

interface UnisatSignPsbtOptions {
  autoFinalized: boolean
  toSignInputs?: {
    index: number
    address?: string
    publicKey?: string
    sighashTypes?: number[]
    disableTweakSigner?: boolean
  }[]
}

declare global {
  interface Window {
    unisat: UnisatAPI
  }
}

interface UnisatSignPsbtOptions {
  autoFinalized: boolean
  toSignInputs?: {
    index: number
    address?: string
    publicKey?: string
    sighashTypes?: number[]
    disableTweakSigner?: boolean
  }[]
}

type UnisatSupportedChains =
  | 'BITCOIN_MAINNET'
  | 'BITCOIN_TESTNET'
  | 'FRACTAL_BITCOIN_MAINNET'
  | 'FRACTAL_BITCOIN_TESTNET'

export interface UnisatAPI {
  getAccounts: () => Promise<string[]>
  requestAccounts: () => Promise<string[]>
  getNetwork: () => Promise<string>
  getChain: () => Promise<{
    enum: UnisatSupportedChains
    name: string
    network: 'livenet' | 'testnet'
  }>
  getPublicKey: () => Promise<string>
  getBalance: () => Promise<{ confirmed: number; unconfirmed: number; total: number }>
  signMessage: (message: string, type?: 'ecdsa' | 'bip322-simple') => Promise<string>
  signPsbt: (psbtHex: string, options?: UnisatSignPsbtOptions) => Promise<string>
  signPsbts: (psbtHexs: string[], options?: UnisatSignPsbtOptions[]) => Promise<string[]>
  pushPsbt: (psbtHex: string) => Promise<string>
  getBitcoinUtxos: () => Promise<
    { txid: string; vout: number; satoshis: number; scriptPk: string }[]
  >
  sendBitcoin: (
    toAddress: string,
    satoshis: number,
    options?: { feeRate: number }
  ) => Promise<string>

  switchChain: (chain: UnisatSupportedChains) => Promise<any>
  on: (event: string, callback: (...args: any[]) => void) => void
  removeListener: (event: string, callback: (...args: any[]) => void) => void // Added removeListener method
  request<Method extends keyof Requests>(
    method: Method,
    options: Params<Method>,
    providerId: string | undefined
  ): Promise<RpcResponse<Method>>
}

export class UnisatBitcoinProvider implements BitcoinProvider, CustomBitcoinProvider {
  private unisat!: UnisatAPI

  private listeners: EventListeners = {
    accountChange: [],
    networkChange: [],
    disconnect: [],
  }

  public addListener(eventName: WalletEvent['type'], cb: (event: any) => void) {
    return addListener(this.listeners, eventName, cb)
  }

  public isAvailable() {
    this.setLib()

    return !!this.unisat
  }

  public setLib() {
    if (!this.unisat && typeof (window as any).unisat !== 'undefined') {
      this.unisat = window.unisat
      this.registerListeners()
    }
  }

  public async connect(_request: string) {
    const addresses: string[] = await this.unisat!.requestAccounts()
    return { addresses: await this.formatAddresses(addresses) }
  }

  private async formatAddresses(addresses: string[]): Promise<Address[]> {
    const formattedAddresses: Address[] = []

    // only the public key for the active account
    const publicKey = await this.unisat!.getPublicKey()

    addresses.forEach((address: string, index) => {
      // unisat uses the same address for ordinals and payments
      formattedAddresses.push(
        this.formatAddress(address, AddressPurpose.Payment, index === 0 ? publicKey : '')
      )
      formattedAddresses.push(
        this.formatAddress(address, AddressPurpose.Ordinals, index === 0 ? publicKey : '')
      )
    })

    return formattedAddresses
  }

  private registerListeners() {
    const handleAccountsChanged = async (accounts: string[]) => {
      const accountChangeEvent: AccountChangeEvent = {
        type: 'accountChange',
        addresses: await this.formatAddresses(accounts),
      }
      // Call all listeners for 'accountChange'
      this.listeners.accountChange.forEach((listener) => listener(accountChangeEvent))
    }

    this.unisat!.on('accountsChanged', handleAccountsChanged)
  }

  private formatAddress(address: string, purpose: AddressPurpose, publicKey?: string) {
    const addressInfo = getAddressInfo(address)
    return {
      address,
      publicKey: publicKey ?? '',
      purpose,
      addressType: addressInfo.type,
    }
  }

  public async disconnect() {}

  public async getCurrentAddress() {
    const accounts = await this.unisat.getAccounts()
    return {
      addresses: [
        this.formatAddress(accounts[0], AddressPurpose.Ordinals),
        this.formatAddress(accounts[0], AddressPurpose.Payment),
      ],
    }
  }

  public async signMessage(request: string) {
    return this.unisat!.signMessage(decodeSignMessageRequest(request))
  }

  // https://docs.unisat.io/dev/unisat-developer-service/unisat-wallet#signpsbt
  public async signTransaction(request: string) {
    const { psbtHex, broadcast } = decodeSignTransactionRequest(request)
    const hex = await this.unisat!.signPsbt(psbtHex, {
      autoFinalized: broadcast,
    })

    let txId
    if (broadcast) {
      txId = await this.unisat!.pushPsbt(hex)
    }

    return {
      psbtBase64: hexToBase64(hex),
      txId,
    }
  }

  public async sendBtcTransaction(request: string) {
    const { amount, address } = decodeSendBitcoinRequest(request)
    const trxId = await this.unisat!.sendBitcoin(address, amount)
    return trxId
  }

  public async call(_request: string) {
    throw new Error('Not implemented')
    return {}
  }

  public async createInscription(_request: string) {
    throw new Error('Not implemented')
    return { txId: '' }
  }

  public async createRepeatInscriptions(_request: string) {
    throw new Error('Not implemented')
    return { txId: '' }
  }

  public async signMultipleTransactions(request: string) {
    console.log('ENTERED unisat')
    try {
      const decodedRequests = decodeSignMultipleTransactionRequest(request)
      console.log('decoded res ', decodedRequests)
      const resultHexs = await this.unisat!.signPsbts(
        decodedRequests.psbts.map((decodedRequest) => decodedRequest.psbtHex),
        decodedRequests.psbts.map((decodedRequest) => {
          return {
            toSignInputs: decodedRequest.inputsToSign.map((input, index) => {
              return {
                index,
                address: input.address,
                sighashTypes: [input.sigHash],
              }
            }),
            autoFinalized: false,
          }
        })
      )

      const results = []

      for (const resultHex of resultHexs) {
        results.push({
          psbtBase64: hexToBase64(resultHex),
          txId: undefined,
        })
      }

      return results
    } catch (e) {
      console.log('error with multi sign', e)
      throw new Error('error with multi sign')
    }
  }

  request<Method extends keyof Requests>(
    method: Method,
    options: Params<Method>,
    providerId: string | undefined
  ): Promise<RpcResponse<Method>> {
    throw new Error('Not implemented')
  }
}
