import { ROUTES } from '../constants'

export const ROUTE_REGEX = /^\/([^/:]+)/

export const ROUTE_PARAM_REGEX = /^\/[^/]+\/([^/:]+)/

export function isOnRoute(locationPathname: string, route: string) {
  const current = getCurrentRoute(locationPathname)
  return current === route
}

export function getCurrentRoute(locationPathname: string) {
  const route = Object.values(ROUTES).find((key) => {
    const match = key.match(ROUTE_REGEX)
    return match && locationPathname.includes(match[1])
  })

  return route
}

export function getCurrentRouteParam(locationPathname: string) {
  const routeParam = locationPathname.match(ROUTE_PARAM_REGEX)?.[1]

  return routeParam
}
