import { LRUCache } from 'lru-cache'
import JSONbig from '@cardanosolutions/json-bigint'

import { FetchOptions, apiFetch } from './apiFetch'
import { formatQueryParams } from './queryParams'

const apiCache = new LRUCache<string, any>({
  max: 100,
  ttl: 60000,
})

function createCacheKey(
  endpoint: string,
  queryParams?: { [key: string]: string | number | bigint | boolean },
  body?: string
): string {
  const paramsStr = queryParams ? formatQueryParams(queryParams, endpoint) : ''
  return `${endpoint}${paramsStr}-${body}`
}

const jsonBig = JSONbig({ useNativeBigInt: true, alwaysParseAsBig: true })

export async function apiFetchWithCache<T>(
  endpoint: string,
  queryParams?: { [key: string]: string | number | bigint | boolean },
  options?: FetchOptions,
  ttl: number = 30000
): Promise<T> {
  const cacheKey = createCacheKey(
    endpoint,
    queryParams,
    options?.body
      ? typeof options.body === 'object' && !options.disableJsonBigParsing
        ? jsonBig.stringify(options?.body)
        : JSON.stringify(options.body)
      : ''
  )

  if (apiCache.has(cacheKey)) {
    return apiCache.get(cacheKey) as T
  }

  const response = await apiFetch<T>(endpoint, queryParams, options)

  apiCache.set(cacheKey, response, { ttl })

  return response
}
