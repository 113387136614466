import Tooltip from '@mui/material/Tooltip'
import { createGlobalStyle, styled } from 'styled-components'
import InfoIcon from '@mui/icons-material/Info'

import helpIcon from 'src/shared/assets/help.svg'

import { COLORS, FONTS, Z_INDEX } from '../constants'

interface Props {
  content: string
  placement?: 'top' | 'bottom' | 'left' | 'right'
  icon?: 'help' | 'info'
}

export function HelpTooltip({ content, placement, icon = 'help' }: Props) {
  return (
    <>
      <TooltipStyles />
      <TooltipWrapper
        title={content}
        arrow
        placement={placement}
        disableFocusListener
        enterTouchDelay={30}
      >
        {icon === 'help' ? <img src={helpIcon} width={20} /> : <InfoIconWrapper />}
      </TooltipWrapper>
    </>
  )
}

const TooltipStyles = createGlobalStyle`  
  .MuiTooltip-popper {
    z-index: ${Z_INDEX.tooltip} !important;
  }
  .MuiTooltip-tooltip {
    font-size: 12px;
    text-align: center;
    font-family: ${FONTS.text};
    background-color: ${COLORS.white};
    color: ${COLORS.black};

  }
  .MuiTooltip-arrow{
    color: ${COLORS.white} !important;
  }
`

const TooltipWrapper = styled(Tooltip)`
  cursor: pointer !important;
`

const InfoIconWrapper = styled(InfoIcon)``
