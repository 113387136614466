import { styled } from 'styled-components'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import { COLORS } from 'src/shared/constants'

import { ToastContainer } from './styles'
import { ToastOptions } from './ToastOptions'

interface WarningToastProps extends ToastOptions {
  warningMessage: string
  className?: string
}

export function WarningToast({ className, warningMessage }: WarningToastProps) {
  return (
    <ToastWrapper className={className}>
      <ErrorOutlineIcon />
      {warningMessage}
    </ToastWrapper>
  )
}

const ToastWrapper = styled(ToastContainer)`
  display: flex;
  align-items: center;
  flex-direction: row;
  svg {
    color: ${COLORS.warning} !important;
    height: 20px;
  }
  font-weight: 500;
`
