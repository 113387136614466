import { formatUnits, parseUnits } from 'ethers'
import { Decimal } from 'decimal.js-light'

import { NUM_SATS_IN_BTC, USD_DECIMALS } from '@packages/constants'
import { bigIntToDecimal } from './bigInt'
import { parseToFloat } from './float'

const compactUsdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  compactDisplay: 'short',
  notation: 'compact',
})

export function formatUsd({
  usd,
  shortFormat = false,
}: {
  usd: number | string | bigint
  shortFormat?: boolean
}) {
  let usdAmount: number
  if (typeof usd === 'string') {
    usdAmount = parseToFloat(usd)
  } else if (typeof usd === 'bigint') {
    usdAmount = parseFloat(formatUnits(usd, USD_DECIMALS))
  } else {
    usdAmount = usd
  }
  if (shortFormat) return compactUsdFormatter.format(usdAmount)

  let formattedPrice
  if (usdAmount >= 1) {
    formattedPrice = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
    }).format(usdAmount)
  } else {
    const significantDigits = usdAmount.toPrecision(2)
    formattedPrice = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      minimumSignificantDigits: 2,
    }).format(Number(significantDigits))
  }
  return formattedPrice
}

export function bigIntUsd(usdNum: number) {
  return parseUnits(`${usdNum}`, USD_DECIMALS)
}

export function bigIntUsdToFloat(bigInt: bigint) {
  return parseFloat(formatUnits(bigInt, USD_DECIMALS))
}

export function usdToSats({
  usdAmount,
  satPriceUsd,
}: {
  usdAmount: number
  satPriceUsd: bigint | number
}) {
  const usd = new Decimal(usdAmount)
  const satPrice = new Decimal(`${satPriceUsd}`)
  let sats: Decimal
  if (typeof satPriceUsd === 'number') {
    sats = usd.div(satPrice)
  } else {
    sats = usd.mul(10 ** USD_DECIMALS).div(satPrice)
  }

  return BigInt(sats.toInteger().toString())
}

export function satsToUsd({
  satPriceUsd,
  satsAmount,
  format = false,
  shortFormat = false,
}: {
  satsAmount: bigint | number
  satPriceUsd: bigint | number
  format?: boolean
  shortFormat?: boolean
}): number | string {
  const sats = new Decimal(`${satsAmount}`)
  const satPrice = new Decimal(`${satPriceUsd}`)
  if (typeof satPriceUsd === 'number') {
    const usd = sats.mul(satPrice).toNumber()
    if (format) {
      return formatUsd({ usd, shortFormat })
    } else {
      return usd
    }
  } else {
    const usd = sats
      .mul(satPrice)
      .div(10 ** USD_DECIMALS)
      .toNumber()
    if (format) {
      return formatUsd({ usd, shortFormat })
    } else {
      return usd
    }
  }
}

export function satsToUsdWithBtcPrice(satsAmount: bigint, btcPriceUsd: bigint) {
  const sats = new Decimal(`${satsAmount}`)
  const satPriceUsd = bigIntToDecimal(btcPriceUsd).div(NUM_SATS_IN_BTC)
  return formatUsd({
    usd: sats
      .mul(satPriceUsd)
      .div(10 ** USD_DECIMALS)
      .toNumber(),
  })
}
