import { css } from 'styled-components'

import { hexToRgb } from '../utils'
import { BREAKPOINTS, COLORS } from '../constants'
import { DEFAULT_CSS_TRANSITION } from './transition'

export function applyOnlyBorderGlow({
  color,
  hoverColor,
  useBackgroundColor = false,
  borderWidth = 1,
}: {
  color?: string
  hoverColor?: string
  useBackgroundColor?: boolean
  borderWidth?: number
}) {
  const glowColor = color ?? COLORS.white
  return css`
    ${DEFAULT_CSS_TRANSITION}
    --color: ${glowColor};
    --hover-color: ${COLORS.hover};
    --blur: 20px;
    @media (max-width: ${BREAKPOINTS.medium}) {
      --blur: 10px;
    }
    --box-blur: calc(0.2 * var(--blur));
    border: ${borderWidth}px solid ${hexToRgb(glowColor, 0.8)};
    background-color: ${useBackgroundColor ? hexToRgb(glowColor, 0.05) : 'transparent'};
    box-shadow:   
      /* --- glow --- */ /* inside */
      inset 0 0 var(--box-blur) var(--color),
      /* outside */ 0 0 var(--box-blur) var(--color);

    ${hoverColor
      ? `
        &:hover {
          border: 1px solid ${hexToRgb(hoverColor, 0.8)};
          box-shadow:   
            /* --- glow --- */ /* inside */
            inset 0 0 var(--box-blur) var(--hover-color),
            /* outside */ 0 0 var(--box-blur) var(--hover-color);
        }
      `
      : ''}
  `
}

export function applyGlowFilter({
  color,
  hoverColor,
  addBorder = false,
  useBackgroundColor = false,
  useMoreGlow = false,
}: {
  color?: string
  hoverColor?: string
  addBorder?: boolean
  useBackgroundColor?: boolean
  useMoreGlow?: boolean
}) {
  const glowColor = color ?? COLORS.white
  return css`
    ${DEFAULT_CSS_TRANSITION}
    color: ${glowColor};
    filter: drop-shadow(0px 0px ${useMoreGlow ? 10 : 6}px ${hexToRgb(glowColor, 0.6)})
      drop-shadow(0px 0px ${useMoreGlow ? 6 : 2}px ${hexToRgb(glowColor, 0.6)});
    @media (max-width: ${BREAKPOINTS.medium}) {
      filter: drop-shadow(0px 0px ${useMoreGlow ? 6 : 3}px ${hexToRgb(glowColor, 0.6)})
        drop-shadow(0px 0px ${useMoreGlow ? 4 : 2}px ${hexToRgb(glowColor, 0.6)});
    }
    ${hoverColor &&
    `
    &:hover {
      color: ${hexToRgb(hoverColor, 0.9)};
      filter: drop-shadow(0px 0px 5px ${hexToRgb(hoverColor, 0.6)})
        drop-shadow(0px 0px 2px ${hexToRgb(hoverColor, 0.6)});

      div {
        color: ${hexToRgb(hoverColor, 0.8)};
      }
      svg {
        fill: ${hexToRgb(hoverColor, 0.8)};
      }
    }
    `}

    ${addBorder ? applyOnlyBorderGlow({ useBackgroundColor, color, hoverColor }) : ''}
  `
}
