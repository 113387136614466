import { styled } from 'styled-components'
import CheckIcon from '@mui/icons-material/Check'

import { COLORS } from 'src/shared/constants'

import { ToastContainer } from './styles'
import { ToastOptions } from './ToastOptions'

export interface SuccessToastProps extends ToastOptions {
  message: string
  className?: string
}

export function SuccessToast({ className, message }: SuccessToastProps) {
  return (
    <ToastWrapper className={className}>
      <CheckIcon /> {message}
    </ToastWrapper>
  )
}

const ToastWrapper = styled(ToastContainer)`
  display: flex;
  align-items: center;
  flex-direction: row;
  svg {
    color: ${COLORS.positive} !important;
    height: 20px;
  }

  font-weight: 500;
`
