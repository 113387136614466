import { Block as MempoolBlock } from '@mempool/mempool.js/lib/interfaces/bitcoin/blocks'

import { API_ENDPOINTS } from '@packages/constants'
import { Block } from '@packages/interfaces'

import { API_URL } from 'src/settings'
import { mempoolApiInstance } from 'src/web3'

export async function getBlockTip({
  setBlockTip,
  currentBlockTip,
}: {
  setBlockTip: (block: Block) => void
  currentBlockTip?: Block
}) {
  try {
    const response = await fetch(`${API_URL}${API_ENDPOINTS.GET.chain.blockTip}`)
    if (response.ok) {
      const blockTip = (await response.json()) as Block
      if ((currentBlockTip?.height ?? 0) !== blockTip.height) {
        // only update if different height
        setBlockTip(blockTip)
      }
    }
  } catch (error) {
    console.error(error)
    const {
      bitcoin: { blocks },
    } = mempoolApiInstance

    const b = (await blocks.getBlocks({})) as any as MempoolBlock[] // mempool api response is not typed correctly
    if (b.length > 0) setBlockTip(b[0])
  }
}
