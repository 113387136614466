import { styled } from 'styled-components'

import { COLORS, FONTS } from 'src/shared/constants'

export const ToastContainer = styled.div<{ color?: string }>`
  background-color: ${({ color }) => color || COLORS.background.primary};
  font-family: ${FONTS.text};
  font-weight: 400;
  font-size: 14px;
  flex-direction: column;
  display: flex;
  padding: 10px;
  svg {
    color: ${COLORS.white};
  }
`
