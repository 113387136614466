import { Popup } from 'src/shared/components'

interface Props {
  isOpen: boolean
  onClose: () => void
}

export function IncorrectAccountPopup({ isOpen, onClose }: Props) {
  return (
    <Popup isOpen={isOpen} onClose={onClose} title='Incorrect Active Account'>
      <p>
        There’s a mismatch between the address you are attempting to sign with and the address
        you’re connected with. Please switch to the correct account.
      </p>
    </Popup>
  )
}
