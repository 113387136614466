import { styled } from 'styled-components'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import { COLORS } from 'src/shared/constants'

import { ToastContainer } from './styles'
import { ToastOptions } from './ToastOptions'

interface ErrorToastProps extends ToastOptions {
  errorMessage: string
  className?: string
}

export function ErrorToast({ className, errorMessage }: ErrorToastProps) {
  return (
    <ToastWrapper className={className}>
      <ErrorOutlineIcon /> {errorMessage}
    </ToastWrapper>
  )
}

const ToastWrapper = styled(ToastContainer)`
  flex-direction: row;
  align-items: center;
  svg {
    color: ${COLORS.negative} !important;
    height: 20px;
  }
  font-weight: 500;
`
