import { styled } from 'styled-components'

import { DEFAULT_RUNE_SYMBOL } from '@packages/interfaces'

import { COLORS } from 'src/shared/constants'
import { hexToRgb } from 'src/shared/utils'

interface Props {
  runeSymbol?: string
  className?: string
  color?: string
}

const EMOJI_REGEX = /^\p{Emoji}$/u

export function RuneSymbolDisplay({ runeSymbol, className, color }: Props) {
  const symbol = runeSymbol ? `${runeSymbol} ` : DEFAULT_RUNE_SYMBOL
  const symbolColor = color || COLORS.white
  return (
    <Container
      color={EMOJI_REGEX.test(symbol.trim()) ? symbolColor : hexToRgb(symbolColor, 0.6)}
      className={className}
    >
      {runeSymbol ? `${runeSymbol} ` : DEFAULT_RUNE_SYMBOL}
    </Container>
  )
}

const Container = styled.span<{ color?: string }>`
  color: ${({ color }) => color};
`
