import { styled } from 'styled-components'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { Button, ErrorToast, PaginatedTable } from 'src/shared/components'
import {
  readItemFromLocalStorage,
  deleteItemFromLocalStorage,
  writeItemToLocalStorage,
  unixMsTimestampToDateString,
} from 'src/shared/utils'
import { SuccessfulTransactionToast, TransactionExternalLink, mempoolApiInstance } from 'src/web3'
import { WalletPickerMenu, useWalletContext } from 'src/wallet'

import {
  ETCH_COMMITS_TRANSACTION_IDS,
  ETCH_COMMIT_REQUEST_LOCAL_STORAGE_KEY_PREFIX,
  ETCH_REVEALS_TRANSACTION_IDS,
  ETCH_REVEAL_REQUEST_LOCAL_STORAGE_KEY_PREFIX,
  RUNE_NAME_REGEX,
} from '../constants'
import { EtchRunePayload, RevealedEtchedRune } from '../interfaces'
import { useCommittedEtchings } from '../hooks'

const ETCH_REVEAL_BLOCK_WAIT = 6

function calculateBlocksToReveal(blockTip: number, blockHeight: number) {
  if (blockTip > blockHeight + 6) return 0
  return ETCH_REVEAL_BLOCK_WAIT - (blockTip - blockHeight)
}

interface EtchCommitState {
  blockHeight: number
  confirmed: boolean
  blocksToReveal: number
}

export function EtchRevealTable() {
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false)
  const { isConnected, revealEtchRune, blockTip } = useWalletContext()

  const [etchStatuses, setEtchStatuses] = useState<Record<string, EtchCommitState>>({})

  const { committedEtchings, updateCommittedEtching } = useCommittedEtchings()
  const [loadingRuneTrxId, setLoadingRuneTrxId] = useState<string>()

  useEffect(() => {
    async function getTrxStatuses() {
      if (blockTip) {
        const statuses: Record<string, EtchCommitState> = {}
        for (const rune of committedEtchings) {
          if (rune.etchingCommit.confirmed) {
            const blockHeight = rune.etchingCommit.blockHeight ?? 0
            statuses[rune.etchingCommit.txId] = {
              blockHeight,
              confirmed: rune.etchingCommit.confirmed,
              blocksToReveal: calculateBlocksToReveal(blockTip.height, blockHeight),
            }
            continue
          }

          try {
            if (
              !etchStatuses[rune.etchingCommit.txId] ||
              !etchStatuses[rune.etchingCommit.txId].confirmed
            ) {
              console.debug('Fetching etch reveal status for', rune.etchingCommit.txId)
              const {
                bitcoin: { transactions },
              } = mempoolApiInstance
              const status = await transactions.getTxStatus({
                txid: rune.etchingCommit.txId,
              })
              statuses[rune.etchingCommit.txId] = {
                blockHeight: status.block_height,
                confirmed: status.confirmed,
                blocksToReveal: calculateBlocksToReveal(blockTip.height, status.block_height),
              }
              updateCommittedEtching({
                ...rune,
                etchingCommit: { ...rune.etchingCommit, ...statuses[rune.etchingCommit.txId] },
              })
            }
          } catch (error) {
            console.error(error)
          }
        }

        setEtchStatuses(statuses)
      }
    }

    getTrxStatuses()
  }, [committedEtchings, blockTip])

  async function onEtchRevealClick(payload: EtchRunePayload) {
    if (!isConnected) {
      setIsWalletModalOpen(true)
      return
    }

    setLoadingRuneTrxId(payload.etchingCommit.txId)

    try {
      console.log('Revealing etch rune', payload)
      const result = await revealEtchRune({
        etchParams: payload.etchingParams,
        fee: 20n,
        etchingCommit: { ...payload.etchingCommit, txid: payload.etchingCommit.txId },
      })

      if (result.success) {
        const revealTransactionId = result.txId ?? ''
        const etchedRune: RevealedEtchedRune = {
          ...payload,
          revealTransactionId,
          revealedAtTimestamp: Date.now(),
        }

        // save etched rune to local storage
        writeItemToLocalStorage(
          ETCH_REVEAL_REQUEST_LOCAL_STORAGE_KEY_PREFIX + revealTransactionId,
          etchedRune
        )
        writeItemToLocalStorage(ETCH_REVEALS_TRANSACTION_IDS, [
          ...readItemFromLocalStorage(ETCH_REVEALS_TRANSACTION_IDS, []),
          revealTransactionId,
        ])

        // cleanup commit transaction from local storage
        deleteItemFromLocalStorage(
          ETCH_COMMIT_REQUEST_LOCAL_STORAGE_KEY_PREFIX + payload.etchingCommit.txId
        )
        writeItemToLocalStorage(
          ETCH_COMMITS_TRANSACTION_IDS,
          committedEtchings
            .map((rune) => rune.etchingCommit.txId)
            .filter((txId) => txId !== payload.etchingCommit.txId)
        )

        toast(
          <SuccessfulTransactionToast
            message='Etching Reveal Successful!'
            transactionId={result.txId ?? ''}
          />,
          { toastId: result.txId ?? `${Date.now()}` }
        )
      } else {
        toast(<ErrorToast errorMessage={`Error: ${result.error}`} />, {
          toastId: result.error + Date.now(),
        })
      }
    } catch (error: any) {
      toast(<ErrorToast errorMessage={`Error: ${error.message}`} />, {
        toastId: error.message + Date.now(),
      })
    } finally {
      setLoadingRuneTrxId(undefined)
    }
  }

  return (
    <>
      <PaginatedTable
        columns={[
          {
            dataKey: 'rune',
            label: 'Name',
            formatter: ({ data: payload }) =>
              payload.etchingParams.rune?.replace(RUNE_NAME_REGEX.replaceSpacersWithBullet, '•'),
          },
          {
            dataKey: 'commitTransactionId',
            label: 'Commited At',
            formatter: ({ data: payload }) => (
              <TransactionExternalLink transactionId={payload.etchingCommit.txId}>
                {unixMsTimestampToDateString(BigInt(payload.etchingCommit.committedAtTimestamp))}
              </TransactionExternalLink>
            ),
          },
          {
            dataKey: 'reveal',
            label: 'Reveal',
            formatter: ({ data: payload }) => {
              const blocksToReveal =
                etchStatuses[payload.etchingCommit.txId]?.blocksToReveal ?? 'N/A'
              if (etchStatuses[payload.etchingCommit.txId]?.blocksToReveal <= 0) {
                return (
                  <RevealButton
                    onClick={() => onEtchRevealClick(payload)}
                    loading={loadingRuneTrxId === payload.etchingCommit.txId}
                  >
                    {loadingRuneTrxId === payload.etchingCommit.txId ? 'See Wallet' : 'Reveal'}
                  </RevealButton>
                )
              } else if (!etchStatuses[payload.etchingCommit.txId]?.confirmed) {
                return <BlockCountdown>Commit trx not confirmed</BlockCountdown>
              } else {
                return <BlockCountdown>{blocksToReveal} Blocks until you can reveal</BlockCountdown>
              }
            },
          },
        ]}
        paginatedData={[committedEtchings]}
        fetchPage={async () => {}}
        loading={false}
        hasNextPage={false}
      />
      <WalletPickerMenu isOpen={isWalletModalOpen} onClose={() => setIsWalletModalOpen(false)} />
    </>
  )
}

const RevealButton = styled(Button)``

const BlockCountdown = styled.div``
