import { useState } from 'react'

import { API_ENDPOINTS } from '@packages/constants'
import { RuneBalance } from '@packages/interfaces'

import { apiFetch, useUpdateOnBlock } from 'src/api'
import { replaceUrlParams } from 'src/shared/utils'
import { useWalletContext } from 'src/wallet'

export function useAvailableBalanceMinusOrders({ runeId }: { runeId?: string }) {
  const { runesAddress } = useWalletContext()

  const [available, setAvailable] = useState<bigint>(0n)

  async function fetchAvailableBalance() {
    if (runesAddress?.addrString && runeId) {
      const balance = await apiFetch<RuneBalance>(
        `${replaceUrlParams(API_ENDPOINTS.GET.runes.balances.runeBalanceByAccount, {
          runeId,
          address: runesAddress.addrString,
        })}?hideOrderBalances=true`
      )

      setAvailable(balance.runesAmount)
    } else {
      setAvailable(0n)
    }
  }

  useUpdateOnBlock(fetchAvailableBalance, [runeId, runesAddress?.addrString], true)

  return { availableBalance: available, forceRefresh: fetchAvailableBalance }
}
