import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import styled from 'styled-components'

import { OKX_URLS } from '@packages/utils'

import { A } from 'src/shared/components'
import { IS_TESTNET_DEPLOYMENT } from 'src/settings'

import { formatShortTransactionId, mempoolUrls } from '../utils'

interface Props {
  transactionId: string
  displayShortId?: boolean
  children?: React.ReactNode
  className?: string
  displayIcon?: boolean
}

export function TransactionExternalLink({
  transactionId,
  displayShortId = true,
  children,
  className,
  displayIcon = false,
}: Props) {
  return (
    <Link
      href={IS_TESTNET_DEPLOYMENT ? mempoolUrls.tx(transactionId) : OKX_URLS.tx(transactionId)}
      className={className}
    >
      {children ?? (displayShortId ? formatShortTransactionId(transactionId) : transactionId)}
      {displayIcon && <OpenInNewIcon />}
    </Link>
  )
}

const Link = styled(A)`
  display: flex;
  align-items: center;
  svg {
    height: 15px;
  }
`
