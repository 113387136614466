import { css, styled } from 'styled-components'
import { useEffect, useRef, useState } from 'react'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded'
import SwapCallsIcon from '@mui/icons-material/SwapCalls'
import { useNavigate } from 'react-router-dom'
import { useAutoAnimate } from '@formkit/auto-animate/react'

import {
  BTC_PRICE_SYMBOL,
  Order,
  OrderStatus,
  PaginationResponse,
  Rune,
  RuneOutpointDetails,
} from '@packages/interfaces'
import { API_ENDPOINTS, BTC_DECIMALS, SATOSHI_DUST_THRESHOLD } from '@packages/constants'
import { formatBtc, formatRuneName } from '@packages/utils'

import {
  BUTTON_HOVER_STYLES,
  BigIntInput,
  Button,
  ErrorMessage,
  NumberInputWithSuffix,
  Select,
  WarningMessage,
} from 'src/shared/components'
import { BREAKPOINTS, COLORS, FONTS, Z_INDEX } from 'src/shared/constants'
import { RuneSymbolDisplay, formatRunesAmount } from 'src/runes'
import { hexToRgb, replaceUrlParams } from 'src/shared/utils'
import { apiFetch } from 'src/api'
import { WalletPickerMenu, useWalletContext } from 'src/wallet'
import { useDebouncedValue, useIsMobile } from 'src/shared/hooks'
import { buildMarketDetailsUrl } from 'src/pages'
import { DEFAULT_CSS_TRANSITION } from 'src/shared/styles'

import { SelectRuneModal } from './SelectRuneModal'
import { calculateTotalSatsFromDesiredPrice, formatPriceSymbol, parsePriceStr } from '../utils'
import { PriceSatsDisplay } from './PriceSatsDisplay'
import { PriceUsdDisplay } from './PriceUsdDisplay'
import { useOrderContext } from '../OrderContext'

const RUNES_INPUT_DEBOUNCE_DELAY_MS = 500
const BTC_INPUT_DEBOUNCE_DELAY_MS = 1500

const OPEN_ORDER_STATUS: OrderStatus = 'PlacedActive'

function getSellOrdersEndpoint(runeName: string, cursor?: string) {
  return (
    `${replaceUrlParams(API_ENDPOINTS.GET.runes.orders.byStatus, {
      runeName: runeName.toUpperCase(),
    })}?status=${OPEN_ORDER_STATUS}&sortOrderBy=price&sortOrder=asc` +
    (cursor ? `&cursor=${cursor}` : '')
  )
}

export function RuneSwapper() {
  const {
    isLoadingNewRune,
    isBuying,
    setIsBuying,
    selectedRune,
    selectedRuneName,
    selectedRuneMarket,
    selectedRuneAvailableBalance,
    setSelectedRune,
    orderSummary,
    selectedRuneOutpoints,
    setSelectedRuneOutpoints,
    selectedSellOrders,
    setSelectedSellOrders,
    desiredBtcSellAmount,
    setDesiredBtcSellAmount,
    desiredRunesSellAmount,
    setDesiredRunesSellAmount,
    openBuyDrawer,
    openSellDrawer,
    isSellDrawerOpen,
  } = useOrderContext()

  const {
    runesAddress,
    paymentAddress,
    getRunesOutpointsForSale,
    btcBalances,
    recommendedNetworkFees,
  } = useWalletContext()

  const [animateRefParent] = useAutoAnimate()

  const isMobile = useIsMobile()
  const navigate = useNavigate()

  const btcInputRef = useRef<HTMLInputElement>(null)
  const runeInputRef = useRef<HTMLInputElement>(null)

  const [isSelectRuneModalOpen, setIsSelectRuneModalOpen] = useState(false)

  const [previouslySelectedSellOrders, setPreviouslySelectedSellOrders] = useState<Order[]>([])
  const [userEditedRunesAmount, setUserEditedRunesAmount] = useState(false)
  const [userEditedBtcAmount, setUserEditedBtcAmount] = useState(false)

  const [debouncedRunesAmount, runesCounter] = useDebouncedValue(
    desiredRunesSellAmount,
    RUNES_INPUT_DEBOUNCE_DELAY_MS
  )

  const [disableRunesAmount, setDisableRunesAmount] = useState<boolean>(false)

  const [debouncedBtcAmount, btcCounter] = useDebouncedValue(
    desiredBtcSellAmount,
    BTC_INPUT_DEBOUNCE_DELAY_MS
  )

  const [disableBtcAmount, setDisableBtcAmount] = useState<boolean>(false)

  const [desiredSatsPrice, setDesiredSatsPrice] = useState<number>()
  const [isUsingBtcTotal, setIsUsingBtcTotal] = useState<boolean>(true)

  const [isWalletMenuOpen, setIsWalletMenuOpen] = useState(false)
  const [isSwapButtonHovered, setIsSwapButtonHovered] = useState(false)

  const [loadingText, setLoadingText] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [buyWarningMessage, setBuyWarningMessage] = useState<string>()

  const [isUsingFloorPrice, setIsUsingFloorPrice] = useState<boolean>(false)

  const [runesAmountIsNotOutpointAmount, setRunesAmountIsNotOutpointAmount] =
    useState<boolean>(false)

  useEffect(() => {
    if (!selectedRuneMarket || !selectedRune) return

    if (isUsingFloorPrice && !isBuying && !isSellDrawerOpen) {
      if (isUsingBtcTotal) {
        updateBtcAmountFromSatsPrice({
          desiredPriceSats: selectedRuneMarket.floorPriceSats,
          desiredRunesAmount: debouncedRunesAmount,
        })
      } else {
        onSatsAmountChange(parseFloat(selectedRuneMarket.floorPriceSats))
      }
    }
  }, [
    selectedRuneMarket,
    isUsingFloorPrice,
    debouncedRunesAmount,
    isBuying,
    isUsingBtcTotal,
    selectedRuneMarket?.floorPriceSats,
  ])

  // useEffect(() => {
  //   console.log(selectedRuneOutpoints)
  //   if (selectedRuneOutpoints.length === 0 && !!desiredSatsPrice) {
  //     setDesiredSatsPrice(undefined)
  //   }
  // }, [desiredSatsPrice, selectedRuneOutpoints, selectedRuneOutpoints.length, userEditedBtcAmount])

  useEffect(() => {
    if (showBtcTotal || !desiredBtcSellAmount) {
      console.log('setting the sats', showBtcTotal, !!desiredBtcSellAmount)
      setDesiredSatsPrice(parseFloat(orderSummary?.priceSats ?? '0'))
    }
  }, [orderSummary?.priceSats])

  useEffect(() => {
    if (selectedSellOrders.length === previouslySelectedSellOrders.length) {
      return
    }
    if (isBuying && selectedSellOrders.length != 0) {
      setBuyWarningMessage(undefined)
    }

    setPreviouslySelectedSellOrders(selectedSellOrders)
  }, [selectedSellOrders])

  async function onBuyOrderChange({
    desiredAmount = 0n,
    desiredAmountField,
    callCounter,
  }: {
    desiredAmount?: bigint
    desiredAmountField: 'runesAmount' | 'satsAmount'
    callCounter: number
  }) {
    if (!selectedRune || !runesAddress) return
    if (desiredAmount === 0n) return

    function isCurrentCounter() {
      return desiredAmountField === 'satsAmount'
        ? callCounter === btcCounter.current
        : callCounter === runesCounter.current
    }

    if (!isCurrentCounter()) {
      return // Ignore outdated calls
    }

    reset()
    setLoadingText('Loading orders')

    let total = 0n
    let nextCursor: string | undefined = undefined
    let response: PaginationResponse<Order> | undefined
    const selectedOrders: Order[] = []
    try {
      do {
        response = await apiFetch<PaginationResponse<Order>>(
          getSellOrdersEndpoint(selectedRune.runeName, nextCursor)
        )
        nextCursor = response.nextCursor as string

        for (const item of response.data) {
          if (item.placedByAddress === runesAddress.addrString) {
            continue
          }
          if (total >= desiredAmount) {
            break
          }
          // only allow <= desiredAmount
          if (total + item[desiredAmountField] > desiredAmount) {
            if (desiredAmountField === 'satsAmount') {
              break
            } else {
              continue
            }
          }
          total += item[desiredAmountField]
          selectedOrders.push(item)
        }
      } while (total < desiredAmount && nextCursor && response.data.length > 0)
    } catch (error) {
      console.error('Error getting sell orders', error)
      if (isCurrentCounter()) {
        setErrorMessage((error as Error).message)
      }
      return
    } finally {
      setLoadingText(undefined)
    }

    if (!isCurrentCounter()) {
      return
    }

    const newOrderSatsAmount = selectedOrders.reduce((acc, item) => acc + item.satsAmount, 0n)
    const newOrderRunesAmount = selectedOrders.reduce((acc, item) => acc + item.runesAmount, 0n)
    if (newOrderSatsAmount !== 0n) {
      setDesiredBtcSellAmount(newOrderSatsAmount)
    } else {
      // if no orders, set to desired amount
      if (desiredAmountField === 'satsAmount') {
        setDesiredBtcSellAmount(desiredAmount)
      }
      setBuyWarningMessage('No orders found for this BTC amount')
    }
    setDesiredRunesSellAmount(newOrderRunesAmount)

    setSelectedSellOrders(selectedOrders)

    setErrorMessage(undefined)
    setLoadingText(undefined)
  }

  async function onSellOrderChange({
    runesAmount = 0n,
    callCounter,
  }: {
    runesAmount?: bigint
    callCounter: number
  }) {
    if (!selectedRune || !runesAddress) return
    if (runesAmount === 0n) return

    function isCurrentCounter() {
      return callCounter === runesCounter.current
    }

    if (!isCurrentCounter()) {
      return // Ignore outdated calls
    }

    reset()
    setLoadingText('Loading rune balance')

    let selectedOutpoints: RuneOutpointDetails[] = []
    try {
      selectedOutpoints = await getRunesOutpointsForSale({
        runeId: selectedRune.runeId,
        sellAmount: runesAmount,
      })
    } catch (error) {
      console.error('Error getting runes outpoints for sale', error)
      if (isCurrentCounter()) {
        setErrorMessage((error as Error).message)
      }
      return
    } finally {
      setLoadingText(undefined)
    }

    if (!isCurrentCounter()) {
      return
    }

    setErrorMessage(undefined)

    if (desiredSatsPrice && desiredSatsPrice !== 0) {
      updateBtcAmountFromSatsPrice({
        desiredPriceSats: desiredSatsPrice,
        desiredRunesAmount: runesAmount,
      })
    }

    setSelectedRuneOutpoints(selectedOutpoints)
    setErrorMessage(undefined)
    setLoadingText(undefined)
  }

  // on runes amount or selected rune change
  useEffect(() => {
    async function update() {
      try {
        if (!selectedRune || !userEditedRunesAmount) return

        setUserEditedRunesAmount(false)
        if (isBuying) {
          await onBuyOrderChange({
            desiredAmount: debouncedRunesAmount,
            desiredAmountField: 'runesAmount',
            callCounter: runesCounter.current,
          })
        } else {
          await onSellOrderChange({
            runesAmount: debouncedRunesAmount,
            callCounter: runesCounter.current,
          })
        }
      } catch (error) {
        console.error('Error updating runes amount', error)
      } finally {
        // if disabled will override their desired runes amount if less than what is in a utxo
        setDisableBtcAmount(false)
        setLoadingText(undefined)
      }
    }

    update()
  }, [debouncedRunesAmount, runesCounter])

  // on btc amount change
  useEffect(() => {
    async function update() {
      try {
        if (!selectedRune || !userEditedBtcAmount) return

        setUserEditedBtcAmount(false)
        if (isBuying) {
          await onBuyOrderChange({
            desiredAmount: desiredBtcSellAmount,
            desiredAmountField: 'satsAmount',
            callCounter: btcCounter.current,
          })
        } else {
          // do not need to do anything - since we are selling
        }
      } catch (error) {
        console.error('Error updating BTC amount', error)
      } finally {
        setDisableRunesAmount(false)
        setLoadingText(undefined)
      }
    }

    update()
  }, [debouncedBtcAmount, btcCounter])

  useEffect(() => {
    async function update() {
      if (!selectedRune) return

      if (isBuying) {
        await onBuyOrderChange({
          desiredAmount: desiredBtcSellAmount,
          desiredAmountField: 'satsAmount',
          callCounter: btcCounter.current,
        })
      } else {
        await onSellOrderChange({
          runesAmount: desiredRunesSellAmount,
          callCounter: runesCounter.current,
        })
      }
    }
    update()
  }, [selectedRune])

  function reset(resetMessages: boolean = true) {
    if (resetMessages) {
      setBuyWarningMessage(undefined)
      setErrorMessage(undefined)
    }

    setLoadingText(undefined)
  }

  function onBtcAmountChange(amount: bigint) {
    setLoadingText(undefined)

    setDesiredBtcSellAmount(amount)
    if (!selectedRune || amount === 0n || amount === desiredBtcSellAmount) return

    setUserEditedBtcAmount(true)
    if (isBuying) {
      // will not auto update the runes amount if selling, but buys are restricted to the amount of runes on orders
      setDisableRunesAmount(true)
      setLoadingText('Loading')
    }
  }

  function onSatsAmountChange(amount?: number) {
    setLoadingText(undefined)
    setDesiredSatsPrice(amount)

    if (!selectedRune || amount === 0 || amount === desiredSatsPrice) return

    setDisableRunesAmount(true)
    setLoadingText('Loading')

    updateBtcAmountFromSatsPrice({
      desiredPriceSats: amount,
      desiredRunesAmount: desiredRunesSellAmount,
    })
  }

  function updateBtcAmountFromSatsPrice({
    desiredPriceSats,
    desiredRunesAmount,
  }: {
    desiredPriceSats?: number | string
    desiredRunesAmount?: bigint
  }) {
    try {
      if (!selectedRune) return

      if (!desiredPriceSats || !desiredRunesAmount) {
        setDesiredBtcSellAmount(undefined)
        return
      }

      const totalBtc: bigint = calculateTotalSatsFromDesiredPrice({
        desiredPriceSats,
        desiredRunesAmount,
        rune: selectedRune,
      })
      setDesiredBtcSellAmount(totalBtc)
    } catch (error) {
      console.error('Error updating BTC amount from sats price', error)
    } finally {
      setDisableRunesAmount(false)
      setLoadingText(undefined)
    }
  }

  function onRunesAmountChange(amount: bigint) {
    setLoadingText(undefined)
    setDesiredRunesSellAmount(amount)

    if (amount === 0n) {
      setSelectedRuneOutpoints([])
    }

    if (!selectedRune || amount === 0n || amount === desiredRunesSellAmount) return

    setUserEditedRunesAmount(true)
    setDisableBtcAmount(true)
    setLoadingText('Loading')
  }

  async function handleSwap() {
    if (!selectedRune) {
      return
    }

    if (isBuying) {
      openBuyDrawer()
    } else {
      openSellDrawer()
    }
  }

  async function onBuySellSwitch() {
    const newIsBuying = !isBuying

    reset()
    setIsBuying(newIsBuying)

    if (newIsBuying) {
      await onBuyOrderChange({
        desiredAmount: desiredRunesSellAmount,
        desiredAmountField: 'runesAmount',
        callCounter: runesCounter.current,
      })
    } else {
      // preserves the runes amount if the user switches back to selling
      setUserEditedRunesAmount(true)
      await onSellOrderChange({
        runesAmount: desiredRunesSellAmount,
        callCounter: runesCounter.current,
      })
    }
  }

  async function onRuneMaxButtonClick() {
    if (selectedRuneAvailableBalance) {
      if (desiredRunesSellAmount !== selectedRuneAvailableBalance) {
        onRunesAmountChange(selectedRuneAvailableBalance)
      }
    }
  }

  async function onBtcMaxButtonClick() {
    if (btcBalances) {
      onBtcAmountChange(btcBalances.chainBalanceSats)
    }
  }

  async function onUseFloorPriceClick() {
    setIsUsingFloorPrice(!isUsingFloorPrice)
  }

  function handleOnSelectedRuneChange(rune: Rune) {
    setSelectedRune(rune)
    setIsUsingFloorPrice(false)
    setDesiredSatsPrice(undefined)

    setDesiredRunesSellAmount(undefined)
    setDesiredBtcSellAmount(undefined)

    setBuyWarningMessage(undefined)
    setErrorMessage(undefined)
  }

  function onSelectRuneModalClose(rune: Rune | undefined) {
    setIsSelectRuneModalOpen(false)
    if (rune) {
      const runeName = isMobile
        ? rune.runeName
        : formatRuneName({
            runeName: rune.runeName,
            runeNameSpacerIndices: rune.runeNameSpacerIndices,
          })
      navigate(buildMarketDetailsUrl(runeName, isBuying ? 'buy' : 'sell'))
      setDesiredRunesSellAmount(undefined)
      setDesiredBtcSellAmount(undefined)
    }
  }

  const submitButtonText = loadingText ?? isBuying ? 'Swap' : 'Place Order'
  const sellAmountIsBelowDustThreshold = !!(
    desiredBtcSellAmount && desiredBtcSellAmount < SATOSHI_DUST_THRESHOLD
  )
  const btcTotalIsZero = desiredBtcSellAmount === 0n
  const isSubmitLoading = disableBtcAmount || disableRunesAmount || !!loadingText
  const isSubmitDisabled =
    sellAmountIsBelowDustThreshold ||
    runesAmountIsNotOutpointAmount ||
    !runesAddress ||
    !paymentAddress ||
    !!errorMessage ||
    !selectedRune ||
    btcTotalIsZero ||
    !desiredBtcSellAmount ||
    desiredRunesSellAmount === 0n ||
    !desiredRunesSellAmount ||
    (isBuying && selectedSellOrders.length === 0) ||
    (!isBuying && selectedRuneOutpoints.length === 0)

  const disableInputs = !runesAddress || !paymentAddress
  const showBtcTotal = isUsingBtcTotal || isBuying

  useEffect(() => {
    setRunesAmountIsNotOutpointAmount(
      !!(
        //Runes amount is set (not 0)
        (
          !isBuying &&
          !!debouncedRunesAmount &&
          //Rune has been selected
          !!selectedRune &&
          //Rune amount is equal to outpoints total
          debouncedRunesAmount !== selectedRuneOutpoints.reduce((acc, val) => acc + val.amount, 0n)
        )
      )
    )
  }, [
    debouncedRunesAmount,
    isSubmitDisabled,
    isSubmitLoading,
    previouslySelectedSellOrders.length,
    selectedRune,
    selectedRuneOutpoints,
    selectedSellOrders.length,
  ])

  return (
    <Container ref={animateRefParent}>
      <Inputs $isBuying={isBuying}>
        <Input
          onClick={() => {
            btcInputRef?.current?.focus()
          }}
        >
          <InputContainer>
            <InputLabel>{isBuying ? 'Sell' : isUsingBtcTotal ? 'Buy' : 'Price'}</InputLabel>
            {showBtcTotal ? (
              <BtcInput
                value={desiredBtcSellAmount}
                disabled={disableBtcAmount || disableInputs || (!isBuying && isUsingFloorPrice)}
                onChange={onBtcAmountChange}
                decimals={BTC_DECIMALS}
                inputRef={btcInputRef}
              />
            ) : (
              // Price
              <SatsInput
                value={desiredSatsPrice}
                disabled={disableBtcAmount || disableInputs || (!isBuying && isUsingFloorPrice)}
                placeholder='0'
                onChange={onSatsAmountChange}
                inputRef={btcInputRef}
                suffix={formatPriceSymbol({ runeSymbol: selectedRune?.runeSymbolChar })}
              />
            )}
            {!isBuying && (
              <FloorPriceContainer>
                <FloorPriceButton
                  onClick={onUseFloorPriceClick}
                  selected={isUsingFloorPrice}
                  disabled={
                    parsePriceStr(selectedRuneMarket?.floorPriceSats ?? '0') === 0 ||
                    (desiredRunesSellAmount === 0n && isUsingBtcTotal) ||
                    disableInputs ||
                    disableBtcAmount ||
                    isLoadingNewRune
                  }
                >
                  {isUsingFloorPrice ? 'Using Floor Price' : 'Use Floor Price'}
                </FloorPriceButton>
                {isUsingFloorPrice && <FloorPriceCloseX>×</FloorPriceCloseX>}
              </FloorPriceContainer>
            )}
          </InputContainer>
          <BtcDetailsContainer>
            {isBuying ? (
              <BtcButton>BTC</BtcButton>
            ) : (
              <SelectBtcButton
                label={''}
                options={[
                  { value: 'btc', label: 'Total BTC' },
                  {
                    value: 'sats',
                    label: 'Price',
                  },
                ]}
                value={showBtcTotal ? 'btc' : 'sats'}
                onChange={function (value: string): void {
                  setIsUsingBtcTotal(value === 'btc')
                }}
              />
            )}

            {btcBalances && (
              <DetailsInfo>
                Available: {btcBalances && formatBtc(btcBalances.chainBalanceSats)}
                {isBuying && (
                  <DetailsButton
                    onClick={onBtcMaxButtonClick}
                    disabled={btcBalances.chainBalanceSats === 0n || isLoadingNewRune}
                  >
                    Max
                  </DetailsButton>
                )}
              </DetailsInfo>
            )}
          </BtcDetailsContainer>
        </Input>
        <ArrowIconContainer
          onClick={onBuySellSwitch}
          onMouseEnter={() => setIsSwapButtonHovered(true)}
          onMouseLeave={() => setIsSwapButtonHovered(false)}
        >
          {isSwapButtonHovered ? <SwapIcon width={15} /> : <ArrowIcon width={15} />}
        </ArrowIconContainer>
        <Input onClick={() => runeInputRef?.current?.focus()}>
          <InputContainer>
            <InputLabel>{isBuying ? 'Buy' : 'Sell'}</InputLabel>
            <RuneInput
              value={desiredRunesSellAmount}
              disabled={disableRunesAmount || disableInputs}
              onChange={onRunesAmountChange}
              decimals={selectedRune?.runeDecimals ?? 0}
              inputRef={runeInputRef}
            />
          </InputContainer>
          <DetailsContainer>
            <RuneButton
              onClick={() => setIsSelectRuneModalOpen(true)}
              runeName={selectedRune && !isLoadingNewRune ? formatRuneName(selectedRune) : ''}
            >
              {selectedRuneName ? (
                <>
                  <RuneSymbolDisplay
                    runeSymbol={selectedRune?.runeSymbolChar}
                    color={COLORS.black}
                  />{' '}
                  {selectedRune ? formatRuneName(selectedRune) : selectedRuneName}
                </>
              ) : (
                'Select a Rune'
              )}
              <ArrowDropDownRoundedIcon />
            </RuneButton>

            <DetailsInfo>
              Available:{' '}
              {selectedRune && !isLoadingNewRune
                ? formatRunesAmount({
                    rune: selectedRune,
                    runesAmount: selectedRuneAvailableBalance ?? 0n,
                  })
                : 0}
              {!isBuying && (
                <DetailsButton
                  onClick={onRuneMaxButtonClick}
                  disabled={
                    !selectedRuneAvailableBalance ||
                    selectedRuneAvailableBalance === 0n ||
                    isLoadingNewRune
                  }
                >
                  Max
                </DetailsButton>
              )}
            </DetailsInfo>
          </DetailsContainer>
        </Input>
      </Inputs>

      <PriceDetails>
        {showBtcTotal ? (
          <PriceDetailsText>
            <PriceSatsDisplay
              priceSats={orderSummary?.priceSats}
              runeSymbol={selectedRune?.runeSymbolChar}
            />{' '}
            <PriceDetailsLabel>
              (<PriceUsdDisplay priceSats={orderSummary?.priceSats} />)
            </PriceDetailsLabel>
          </PriceDetailsText>
        ) : (
          <PriceDetailsText>
            <RuneSymbolDisplay runeSymbol={BTC_PRICE_SYMBOL} />{' '}
            {formatBtc(orderSummary?.totalSats ?? 0n)} <PriceDetailsLabel>Total</PriceDetailsLabel>
          </PriceDetailsText>
        )}
        <FeeRate>
          {recommendedNetworkFees?.fastestFee} sat/vB{' '}
          <PriceDetailsLabel>Fee Rate</PriceDetailsLabel>
        </FeeRate>
      </PriceDetails>

      {runesAmountIsNotOutpointAmount && <ErrorMessage message='Unable to partially list runes' />}

      {sellAmountIsBelowDustThreshold && (
        <ErrorMessage message='Sell amount is below dust threshold' />
      )}
      {btcTotalIsZero && !isBuying && <ErrorMessage message={'BTC sell total is zero'} />}
      {errorMessage && <ErrorMessage message={errorMessage} />}
      {buyWarningMessage && <WarningMessage message={buyWarningMessage} />}
      {/* {infoAlert && <InfoMessage message={infoAlert} />} */}
      {runesAddress && paymentAddress ? (
        <SubmitButton onClick={handleSwap} disabled={isSubmitDisabled} loading={isSubmitLoading}>
          {submitButtonText}
        </SubmitButton>
      ) : (
        <ConnectButton onClick={() => setIsWalletMenuOpen(true)}>
          Connect Wallet to Swap
        </ConnectButton>
      )}

      <SelectRuneModal
        isOpen={isSelectRuneModalOpen}
        onSelectRune={handleOnSelectedRuneChange}
        onClose={onSelectRuneModalClose}
      />
      <WalletPickerMenu isOpen={isWalletMenuOpen} onClose={() => setIsWalletMenuOpen(false)} />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

const MAX_WIDTH_INPUTS = 600

const Inputs = styled.div<{ $isBuying: boolean }>`
  font-weight: 700;
  width: 100%;
  max-width: ${MAX_WIDTH_INPUTS}px;
  border-radius: 15px;
  gap: 5px;
  display: flex;
  flex-direction: ${(props) => (props.$isBuying ? 'column' : 'column-reverse')};
`

const Input = styled.div`
  cursor: text;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid ${COLORS.white};
  border-radius: 15px;
  padding: 10px 15px;
  padding-right: 20px;
  background-color: ${COLORS.background.container} !important;
  @media (max-width: ${BREAKPOINTS.medium}) {
    padding: 13px;
    padding-right: 15px;
  }
`

const INPUT_WIDTH = 500

const InputContainer = styled.div`
  background-color: transparent;
  width: 100%;
  width: ${INPUT_WIDTH}px;
`

const InputLabel = styled.div`
  font-weight: 500;
  padding-left: 10px;
  font-size: 14px;
  color: ${hexToRgb(COLORS.white, 0.7)};
  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-left: 5px;
  }
`

const InputStyles = css`
  color: ${COLORS.white};

  &:focus {
    border: none;
  }
  input {
    width: 100%;
    &:focus {
      outline: none !important;
      border: none;
    }
    border: none !important;
    background-color: transparent !important;
    color: ${COLORS.white};
    font-weight: 700;
    font-family: ${FONTS.text};
    font-size: 20px;
    padding: 10px;
    padding-top: 0px;
    padding-bottom: 15px;

    @media (max-width: ${BREAKPOINTS.medium}) {
      font-size: 16px;
      padding: 10px 5px;
    }
  }

  div {
    p {
      color: ${hexToRgb(COLORS.white, 0.6)};
    }
  }
`

const BigIntInputWrapper = styled(BigIntInput)`
  ${InputStyles}
`

const BtcInput = styled(BigIntInputWrapper)`
  span {
    margin-left: 10px;
    height: 100%;
    min-height: 50px;
  }
`

const SatsInput = styled(NumberInputWithSuffix)`
  ${InputStyles}
  span {
    padding-top: 0px;
    padding-bottom: 15px;
    @media (max-width: ${BREAKPOINTS.medium}) {
      padding-left: 0px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  fieldset {
    border: none;
  }
`

const RuneInput = styled(BigIntInputWrapper)`
  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-bottom: 10px;
  }
`

const ArrowIconContainer = styled.div`
  border-radius: 100px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  background-color: ${COLORS.buttons.primary};
  outline: solid 2px ${COLORS.background.overlay};
  position: relative;
  top: -6px;
  left: 48%;
  top: -16px;
  margin-bottom: -30px;
  z-index: ${Z_INDEX.priorityContent};
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.link};
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    left: 46%;
  }
`

const ArrowIcon = styled(ArrowDownwardRoundedIcon)`
  width: 20px;
  color: ${COLORS.black};
`

const SwapIcon = styled(SwapCallsIcon)`
  width: 20px;
  color: ${COLORS.black};
`

const SELECT_BUTTON_HEIGHT = 36

const RuneButton = styled(Button)<{ runeName: string }>`
  height: ${SELECT_BUTTON_HEIGHT}px;
  color: ${COLORS.black};
  font-weight: 700;
  padding: 5px 15px;
  padding-right: 6px;
  padding-left: 12px;
  border-radius: 5px;
  background-color: ${COLORS.buttons.primary};
  white-space: nowrap;

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-left: 15px;
    padding-right: 5px;
  }
  &:hover {
    background-color: ${hexToRgb(COLORS.white, 0.5)};
  }
  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: ${({ runeName }) =>
      runeName.length > 20 ? '10' : runeName.length > 15 ? '12' : '13'}px;
  }
`

const BtcButton = styled.div`
  height: ${SELECT_BUTTON_HEIGHT}px;
  padding: 5px 10px;
  border-radius: 5px;
  color: ${COLORS.white};
  background-color: ${hexToRgb(COLORS.white, 0.3)};
  width: fit-content;
  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: 13px;
  }
`

const SelectBtcButton = styled(Select)`
  height: ${SELECT_BUTTON_HEIGHT}px;
  div {
    @media (max-width: ${BREAKPOINTS.medium}) {
      font-size: 13px;
    }
  }
`

const DetailsContainer = styled.div`
  min-width: 200px;
  max-width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;
  height: 100%;
  padding-top: 5px;
  margin: 0px;
  justify-content: start;
`

const BtcDetailsContainer = styled(DetailsContainer)`
  min-width: auto;
`

const DetailsInfo = styled.div`
  color: ${hexToRgb(COLORS.white, 0.7)};
  font-weight: 500;
  font-size: 14px;
  display: flex;
  gap: 5px;
  justify-content: end;
  padding-top: 10px;
  width: 100%;
  white-space: nowrap;
`

const FloorPriceCloseX = styled.div`
  position: absolute;
  background-color: ${COLORS.negative};
  border-radius: 100px;
  margin-left: -17px;
  color: ${COLORS.white};
  font-size: 20px;
  line-height: 1;
  width: 20px;
  text-align: center;
  opacity: 0;
  ${DEFAULT_CSS_TRANSITION}
`

const FloorPriceContainer = styled(DetailsInfo)`
  margin-top: -20px;
  justify-content: flex-start;
  padding-left: 10px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-left: 5px;
  }
`

const DetailsButton = styled.div<{ disabled?: boolean }>`
  ${BUTTON_HOVER_STYLES}
  color: ${COLORS.hover};
  font-weight: 700;
  width: fit-content;
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      cursor: none;
      color: ${hexToRgb(COLORS.white, 0.7)};
      &:hover {
        opacity: 1;
      }
    `}
`

const FloorPriceButton = styled(DetailsButton)<{ selected: boolean }>`
  ${DEFAULT_CSS_TRANSITION}
  border-radius: 3px;
  color: ${({ selected }) => (selected ? COLORS.white : COLORS.hover)};
  ${(props) =>
    props.selected &&
    css`
      background-color: ${COLORS.gradient};
      color: ${COLORS.white};
      padding: 0px 5px;
    `}
  ${(props) =>
    props.disabled &&
    css`
      color: ${hexToRgb(COLORS.white, 0.7)};
    `}

  &:hover {
    & + ${FloorPriceCloseX} {
      cursor: pointer;
      opacity: 1;
    }
  }
`

const SubmitButton = styled(Button)`
  width: 100%;
  max-width: 250px;
`

const ConnectButton = styled(Button)`
  width: 100%;
  max-width: 250px;
`

const PriceDetails = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  max-width: ${MAX_WIDTH_INPUTS}px;
  font-size: 15px;
  justify-content: space-between;
  @media (max-width: ${BREAKPOINTS.medium}) {
    justify-content: center;
  }
`

const PriceDetailsText = styled.div`
  display: flex;
  gap: 5px;
`

const FeeRate = styled.div`
  display: flex;
  gap: 5px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    display: none;
  }
`

const PriceDetailsLabel = styled.div`
  color: ${hexToRgb(COLORS.white, 0.7)};
`
