import _Checkbox from '@mui/material/Checkbox'
import { styled } from 'styled-components'

import { COLORS } from 'src/shared/constants'
import { hexToRgb } from 'src/shared/utils'

interface Props {
  checked: boolean
  onChange: (checked: boolean) => void
  defaultChecked?: boolean
  disabled?: boolean
  className?: string
}

export function Checkbox({ defaultChecked, onChange, checked, disabled, className }: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked)
  }

  return (
    <CheckboxWrapper
      size='large'
      className={className}
      defaultChecked={defaultChecked}
      onChange={handleChange}
      checked={checked}
      disabled={disabled}
      sx={{
        color: COLORS.link,
        '&.Mui-checked': {
          color: COLORS.link,
        },
      }}
    />
  )
}

const CheckboxWrapper = styled(_Checkbox)`
  input {
    width: 50px;
  }

  svg {
    width: 50px;
    ${({ disabled }) =>
      disabled &&
      `
    color: ${hexToRgb(COLORS.white, 0.6)};
  `}
  }
`
