import { useParams } from 'react-router-dom'

import { MarketDetailsPageContent } from 'src/markets'

import { PageContainer } from './PageContainer'

export function MarketDetailsPage() {
  const { runeName } = useParams()

  if (typeof runeName == 'undefined') {
    return <PageContainer>Invalid Rune Name: {runeName}</PageContainer>
  }

  return (
    <PageContainer>
      <MarketDetailsPageContent runeName={runeName} />
    </PageContainer>
  )
}
