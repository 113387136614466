export function combineSearchParams(
  currentSearchParams: URLSearchParams,
  newParams: { [key: string]: string }
) {
  const existingParams: { [key: string]: string } = {}
  for (const key of currentSearchParams.keys()) {
    const value = currentSearchParams.get(key)
    if (value) existingParams[key] = value
  }

  return { ...existingParams, ...newParams }
}
