import { SparkLineChart as _SparkLineChart } from '@mui/x-charts/SparkLineChart'
import { styled } from 'styled-components'

interface Props {
  dataPoints: number[]
  height?: number
  className?: string
}

export function SparklineChart({ dataPoints, height = 100, className }: Props) {
  return (
    <Container className={className}>
      <_SparkLineChart data={dataPoints} height={height} />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
`
