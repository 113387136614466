import RefreshIcon from '@mui/icons-material/Refresh'
import { css, styled } from 'styled-components'
import { useState } from 'react'

import { COLORS } from 'src/shared/constants'

import { Button } from './Button'
import { BUTTON_HOVER_STYLES } from './styles'

interface Props {
  onClick: () => void
  className?: string
}

export function RefreshButton({ onClick, className }: Props) {
  const [animate, setAnimate] = useState(false)

  function handleClick() {
    onClick()
    setAnimate(true)
    setTimeout(() => setAnimate(false), 500)
  }
  return (
    <ButtonWrapper onClick={handleClick} animate={animate} className={className}>
      Refresh <RefreshIcon />
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled(Button)<{ animate?: boolean }>`
  border: none;
  background-color: ${COLORS.black};
  color: ${COLORS.white};
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  svg {
    ${(props) =>
      props.animate &&
      css`
        animation: spin 500ms ease-in-out 1;
      `}

    width: 25px;
    fill: ${COLORS.white};
  }
  &:hover {
    background-color: transparent;
    color: ${COLORS.hover};
    ${BUTTON_HOVER_STYLES};
    svg {
      ${BUTTON_HOVER_STYLES};
      fill: ${COLORS.hover};
    }
  }
`
