import { useEffect, useState } from 'react'
import { css, styled } from 'styled-components'

import { BTC_PRICE_SYMBOL } from '@packages/interfaces'
import { formatBtc } from '@packages/utils'

import { Button } from 'src/shared/components'
import { BREAKPOINTS, COLORS, Z_INDEX } from 'src/shared/constants'
import { applyOnlyBorderGlow } from 'src/shared/styles'
import { hexToRgb } from 'src/shared/utils'
import { WalletPickerMenu, useWalletContext } from 'src/wallet'
import { RuneSymbolDisplay, formatRunesAmount } from 'src/runes'

import { PriceSatsDisplay } from './PriceSatsDisplay'
import { PriceUsdDisplay } from './PriceUsdDisplay'
import { useOrderContext } from '../OrderContext'

const SCROLL_THRESHOLD_PX = 20

export function OrderFooterMenu() {
  const {
    orderSummary,
    isBuying,
    selectedRune,
    selectedRuneName,
    openBuyDrawer,
    selectedSellOrders,
  } = useOrderContext()
  const { runesAddress, paymentAddress } = useWalletContext()
  const [scrolledTo, setScrolledTo] = useState<number>()
  const [isFooterMenuShown, setIsFooterMenuShown] = useState(false)
  const [isWalletMenuOpen, setIsWalletMenuOpen] = useState(false)
  const [$hasScrolled, setHasScrolled] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const scroll = window.scrollY
      setScrolledTo(scroll)
    })
  }, [])

  useEffect(() => {
    if (scrolledTo && scrolledTo >= SCROLL_THRESHOLD_PX) {
      setHasScrolled(true)
      setIsFooterMenuShown(true)
    } else if (scrolledTo === 0 && $hasScrolled) {
      setIsFooterMenuShown(false)
    }
  }, [scrolledTo])

  if (!selectedRuneName || !runesAddress || !isBuying) return null

  const shouldDisplay = isBuying && selectedSellOrders.length > 0

  return (
    <FooterMenu
      $showAnimation={isFooterMenuShown && shouldDisplay}
      $hasScrolled={$hasScrolled}
      $display={shouldDisplay}
    >
      {runesAddress && paymentAddress ? (
        <BuySellButton onClick={openBuyDrawer}>
          {orderSummary && (
            <>
              <PriceDetails>
                <Quantity>
                  {isBuying ? 'Buy' : 'Sell'}{' '}
                  {selectedRune && (
                    <BoldDetails>
                      {formatRunesAmount({
                        runesAmount: orderSummary.totalRunes,
                        rune: selectedRune,
                      })}{' '}
                    </BoldDetails>
                  )}
                </Quantity>{' '}
                <Price>
                  @{' '}
                  <BoldDetails>
                    <PriceSatsDisplay
                      priceSats={orderSummary.priceSats}
                      runeSymbol={selectedRune?.runeSymbolChar}
                    />
                  </BoldDetails>
                  <UsdPrice>
                    (<PriceUsdDisplay priceSats={orderSummary?.priceSats} />)
                  </UsdPrice>
                </Price>
              </PriceDetails>
              <TotalDetails>
                Order Cost:
                <RuneSymbolDisplay runeSymbol={BTC_PRICE_SYMBOL} />{' '}
                <BoldDetails>{formatBtc(orderSummary.totalSats)}</BoldDetails>
              </TotalDetails>
            </>
          )}
        </BuySellButton>
      ) : (
        <ConnectButton onClick={() => setIsWalletMenuOpen(true)}>Connect Wallet</ConnectButton>
      )}
      <WalletPickerMenu isOpen={isWalletMenuOpen} onClose={() => setIsWalletMenuOpen(false)} />
    </FooterMenu>
  )
}

const FooterMenu = styled.div<{
  $showAnimation: boolean
  $display: boolean
  $hasScrolled: boolean
}>`
  z-index: ${Z_INDEX.navBar};
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 10px;
  left: 0;
  height: fit-content;
  width: 100%;
  display: flex;
  padding: 0 10px;
  opacity: 0;
  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: 14px;
    div {
      font-size: 16px;
    }
  }

  @keyframes scaleUp {
    0% {
      transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  @keyframes scaleDown {
    0% {
      transform: translateY(0px);
      opacity: 1;
    }
    100% {
      transform: translateY(100%);
      opacity: 0;
    }
  }

  ${({ $display }) =>
    !$display &&
    css`
      display: none;
    `}

  ${({ $showAnimation }) =>
    $showAnimation &&
    css`
      animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    `}
  ${({ $showAnimation, $hasScrolled }) =>
    !$showAnimation &&
    $hasScrolled &&
    css`
      animation: scaleDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    `}
`

const BuySellButton = styled(Button)`
  ${applyOnlyBorderGlow({ color: COLORS.hover, borderWidth: 2 })}
  background-color: ${COLORS.black};
  color: white;
  display: flex;
  flex-direction: column;

  &:hover {
    background-color: #3d3d3d;
  }

  ${(props) =>
    props.disabled &&
    css`
      ${applyOnlyBorderGlow({ color: hexToRgb(COLORS.white, 0.4) })}
      border: none;
      background-color: ${COLORS.black};
      color: ${hexToRgb(COLORS.white, 0.7)};
      div {
        color: ${hexToRgb(COLORS.white, 0.7)};
      }
    `};
`

const ConnectButton = styled(BuySellButton)``

const BoldDetails = styled.span`
  font-weight: 700;
`

const PriceDetails = styled.div`
  display: flex;
  gap: 5px;
  text-transform: none;
  flex-wrap: wrap;
  justify-content: center;
`

const Quantity = styled.div``
const Price = styled.div`
  display: flex;
  gap: 5px;
`

const UsdPrice = styled.div`
  color: ${hexToRgb(COLORS.white, 0.7)};
`

const TotalDetails = styled.div`
  display: flex;
  gap: 5px;
  text-transform: none;
  @media (max-width: ${BREAKPOINTS.medium}) {
    display: none;
  }
`
