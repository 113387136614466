import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { scrollToHash } from 'src/shared/utils'

export function ScrollToTop() {
  const { pathname, hash } = useLocation()

  useEffect(() => {
    if (hash) {
      scrollToHash(hash)
    } else {
      window.scrollTo(0, 0)
    }
  }, [pathname, hash])

  return null
}
