import { styled } from 'styled-components'

import { BREAKPOINTS, FONTS } from '../constants'

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 20px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-top: 10px;
    flex-direction: row;
    align-items: baseline;
  }
`

export const HeaderText = styled.h1<{ $wordBreak?: string }>`
  word-break: ${(props) => props.$wordBreak ?? 'break-word'};
  font-family: ${FONTS.headers};
  font-weight: 400;
  text-transform: uppercase;
  line-height: 30px;
  text-align: left;

  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: 23px;
    text-align: left;
  }
`
