import { useEffect, useState, useRef } from 'react'
import { css, styled } from 'styled-components'

import { hexToRgb } from 'src/shared/utils'

import { BREAKPOINTS, COLORS, FONTS, NUMBER_REGEX } from '../../constants'

interface Props {
  value?: number
  onChange: (val?: number) => void
  onBlur?: () => void
  disabled?: boolean
  error?: boolean
  className?: string
  helperText?: string
  placeholder?: string
  inputRef?: any
  suffix?: string
}

export function NumberInputWithSuffix({
  value,
  onChange,
  onBlur,
  disabled = false,
  className,
  placeholder,
  inputRef,
  suffix,
}: Props) {
  const [inputValue, setInputValue] = useState<string>(value ? floatToPlainString(value) : '')
  const contentEditableRef = useRef<HTMLSpanElement>(null)
  const [isEmpty, setIsEmpty] = useState<boolean>(!inputValue)
  const [caretPositionFromEnd, setCaretPositionFromEnd] = useState<number>(0)
  const [renderTrigger, setRenderTrigger] = useState<number>(0)

  useEffect(() => {
    if (!value) {
      setInputValue('')
    } else if (value !== parseFloat(inputValue)) {
      setInputValue(floatToPlainString(value))
    }
  }, [value])

  useEffect(() => {
    const currentRef = inputRef ? inputRef.current : contentEditableRef.current
    if (currentRef) {
      currentRef.innerText = inputValue
      setIsEmpty(!inputValue)
      if (document.activeElement === currentRef) {
        updateDOMCaretPositionFromEnd(currentRef, caretPositionFromEnd)
      }
    }
  }, [inputValue, renderTrigger])

  function handleChange(event: any) {
    const currentRef = inputRef ? inputRef.current : contentEditableRef.current
    const strValue = event.target.innerText
    const newCaretPositionFromEnd = getCaretPositionFromEnd(currentRef)

    const startsWithDot = strValue.startsWith('.')
    const isEmptyString = strValue === ''

    if (isEmptyString || startsWithDot || NUMBER_REGEX.test(strValue)) {
      setCaretPositionFromEnd(newCaretPositionFromEnd)
      setInputValue(strValue)
      setIsEmpty(!strValue)
      if (isEmptyString) {
        onChange(undefined)
      } else {
        onChange(parseFloat(startsWithDot ? `0${strValue}` : strValue))
      }
    } else {
      // If the input is invalid, revert to the previous valid state
      setCaretPositionFromEnd(0)
      setRenderTrigger(renderTrigger + 1)
      if (currentRef) {
        currentRef.innerText = inputValue
      }
    }
  }

  function getCaretPositionFromEnd(ref?: HTMLSpanElement) {
    const selection = document.getSelection()
    if (!selection || !ref) return 0
    selection.collapseToEnd()
    const range = selection.getRangeAt(0)
    const clone = range.cloneRange()
    clone.selectNodeContents(ref)
    clone.setEnd(range.startContainer, range.startOffset)
    const position = clone.toString().length

    const fullRange = ref.ownerDocument.createRange()
    fullRange.selectNodeContents(ref)
    const totalLength = fullRange.toString().length

    return totalLength - position
  }

  function updateDOMCaretPositionFromEnd(ref: HTMLSpanElement, positionFromEnd: number) {
    const fullRange = ref.ownerDocument.createRange()
    fullRange.selectNodeContents(ref)
    const totalLength = fullRange.toString().length
    const position = totalLength - positionFromEnd

    const selection = document.getSelection()
    if (!selection || !ref || ref.firstChild === null) return
    selection.collapseToEnd()
    const range = selection.getRangeAt(0)
    range.setStart(ref.firstChild!, position)
    range.setEnd(ref.firstChild!, position)
    selection.removeAllRanges()
    selection.addRange(range)
  }

  function handleBlur() {
    if (onBlur) {
      onBlur()
    }
  }

  return (
    <Container className={className}>
      {isEmpty && placeholder ? <Placeholder>{placeholder}</Placeholder> : null}
      <TextFieldWrapper
        contentEditable={!disabled}
        onInput={handleChange}
        onBlur={handleBlur}
        ref={inputRef || contentEditableRef}
        suppressContentEditableWarning={true}
        disabled={disabled}
      ></TextFieldWrapper>
      <Suffix>{suffix}</Suffix>
    </Container>
  )
}

function floatToPlainString(num: number): string {
  let plainString = num.toString()
  if (plainString.indexOf('e') !== -1) {
    const parts = plainString.split('e')
    const exponent = parseInt(parts[1], 10)
    let [integerPart, fractionalPart] = parts[0].split('.')
    fractionalPart = fractionalPart || ''
    if (exponent > 0) {
      plainString = integerPart + fractionalPart.padEnd(exponent + fractionalPart.length, '0')
    } else {
      const absExponent = Math.abs(exponent)
      plainString = '0.' + '0'.repeat(absExponent - 1) + integerPart + fractionalPart
    }
  }
  return plainString
}

const Container = styled.div`
  white-space: nowrap;
`

const TextFieldWrapper = styled.span<{ disabled: boolean; contentEditable: boolean }>`
  display: inline-block;
  min-width: 29px;
  padding: 8px;
  padding-left: 9px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 20px;
  font-family: ${FONTS.text};
  &:focus {
    outline: none;
    border-color: ${COLORS.white};
  }
  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: 16px;
    padding-left: 5px;
  }

  ${(props) =>
    props.disabled &&
    css`
      color: ${hexToRgb(COLORS.white, 0.6)};
    `}
`

const Placeholder = styled.span`
  font-weight: 700;
  font-size: 20px;
  color: ${COLORS.white};
  pointer-events: none;
  user-select: none;
  opacity: 0.4;
  position: relative;
  left: 8px;
  width: 0;
  display: inline-block;

  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: 16px;
    left: 5px;
  }
`

const Suffix = styled.span`
  opacity: 0.7;
`
