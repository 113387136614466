import Alert from '@mui/material/Alert'
import { styled } from 'styled-components'

import { COLORS, FONTS } from '../constants'

interface Props {
  message: string
}
export function WarningMessage({ message }: Props) {
  return <AlertWrapper severity='warning'>{message}</AlertWrapper>
}

const AlertWrapper = styled(Alert)`
  background-color: ${COLORS.warning};
  font-weight: 500;
  font-family: ${FONTS.text};
  svg {
    color: ${COLORS.white};
  }
  .MuiAlert-icon {
    min-width: 25px;
    align-items: center;
  }
`
