import styled from 'styled-components'

import { COLORS } from '../constants'
import { BUTTON_HOVER_STYLES } from './buttons'

interface Props {
  href: string
  children?: React.ReactNode
  className?: string
}

export function A({ href, children, className }: Props) {
  return (
    <Link target='_blank' rel='noopener noreferrer' href={href} className={className}>
      {children}
    </Link>
  )
}

const Link = styled.a`
  color: ${COLORS.link};
  font-weight: 700;
  ${BUTTON_HOVER_STYLES}
`
