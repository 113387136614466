import { styled } from 'styled-components'

import { RuneDetails } from '@packages/interfaces'

import { COLORS } from 'src/shared/constants'

import { useMintDetails } from '../hooks'

interface Props {
  runeDetails: RuneDetails
}

export function MintedPercentBadge({ runeDetails }: Props) {
  const mintDetails = useMintDetails({ runeDetails })

  if (!mintDetails) return

  let color = COLORS.white
  if (mintDetails.mintedPercent >= 90) {
    color = COLORS.negative
  } else if (mintDetails.mintedPercent >= 70) {
    color = COLORS.warning
  }

  return <MintedPercent color={color}>{mintDetails.formattedMintedPercent}</MintedPercent>
}

const MintedPercent = styled.div<{ color: string }>`
  width: fit-content;
  border-radius: 5px;
  color: ${({ color }) => color} !important;
`
