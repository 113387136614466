import { styled } from 'styled-components'
import { useState } from 'react'
import { toast } from 'react-toastify'

import { RuneBalance } from '@packages/interfaces'
import { formatRuneName } from '@packages/utils'

import {
  BUTTON_HOVER_STYLES,
  BigIntInput,
  Drawer,
  DrawerBoldColumn,
  DrawerButtons,
  DrawerCancelButton,
  DrawerCard,
  DrawerColumn,
  DrawerConfirmButton,
  DrawerRow,
  DrawerTitle,
  ErrorMessage,
  HelpTooltip,
} from 'src/shared/components'
import { COLORS } from 'src/shared/constants'
import { AddressInput, NetworkFeeSelector, SuccessfulTransactionToast } from 'src/web3'
import { RunesAmountDisplay } from 'src/runes'
import { useWalletContext } from 'src/wallet'
import { useAvailableBalanceMinusOrders } from 'src/orders'
import { DISABLE_WRITES } from 'src/settings'
import { useGoogleAnalyticsContext } from 'src/analytics'

interface Props {
  isOpen: boolean
  onClose: () => void
  runeBalance: RuneBalance
}

export function TransferRunesDrawer({ isOpen, onClose, runeBalance }: Props) {
  const { runesAddress, paymentAddress, transferRunes } = useWalletContext()
  const [loadingText, setLoadingText] = useState<string>()

  const [error, setError] = useState<string>()
  const [runesAmountError, setRunesAmountError] = useState<string>()

  const [selectedNetworkFee, setSelectedNetworkFee] = useState(0)
  const [runesAmount, setRunesAmount] = useState(0n)
  const [recipientAddress, setRecipientAddress] = useState('')
  const [isValidRecipientAddress, setIsValidRecipientAddress] = useState(true)

  const { availableBalance, forceRefresh: fetchAvailableBalance } = useAvailableBalanceMinusOrders({
    runeId: runeBalance.runeId,
  })

  const { confirmEvent, executeEvent, abandonEvent } = useGoogleAnalyticsContext()

  function resetForm() {
    setError(undefined)
    setRunesAmount(0n)
    setRecipientAddress('')
    setSelectedNetworkFee(0)
  }

  async function onConfirmClick() {
    confirmEvent('transfer', {
      token_name: runeBalance.runeName,
      token_type: 'rune',
      transfer_amount: runesAmount,
      fee: selectedNetworkFee,
    })
    if (runesAddress === undefined || paymentAddress === undefined) {
      setError('Rune wallet is not connected')
      return
    }
    if (runesAmount <= 0) {
      setError('Invalid amount')
    }
    if (recipientAddress === '') {
      setError('Recipient address is required')
      return
    }

    try {
      setError(undefined)

      const signTxResult = await transferRunes({
        recipientAddress,
        transferAmount: runesAmount,
        fee: BigInt(selectedNetworkFee),
        rune: runeBalance,
        onStatusChange: (status) => {
          switch (status) {
            case 'build':
              setLoadingText('Building tx')
              break
            case 'wallet-prompt':
              setLoadingText('See Wallet')
              break
          }
        },
      })

      if (signTxResult.success) {
        toast(
          <SuccessfulTransactionToast
            message='Transfer successful!'
            transactionId={signTxResult.txId ?? ''}
          />,
          { toastId: 'transfer-successful-' + signTxResult.txId }
        )
        executeEvent('transfer', {
          token_name: runeBalance.runeName,
          token_type: 'rune',
          transfer_amount: runesAmount,
          fee: selectedNetworkFee,
        })
        resetForm()
        onClose()
        await fetchAvailableBalance()
      } else {
        setError(signTxResult.error)
      }
    } catch (error: any) {
      console.error(error)
      abandonEvent(false, 'transfer', {
        token_name: runeBalance.runeName,
        token_type: 'rune',
        transfer_amount: runesAmount,
        fee: selectedNetworkFee,
      })

      setError(error.message)
    } finally {
      setLoadingText(undefined)
    }
  }

  function onRunesAmountChange(value: bigint) {
    setRunesAmount(value)
    if (value > (availableBalance ?? runeBalance.runesAmount)) {
      setRunesAmountError('Insufficient balance')
    } else {
      setRunesAmountError(undefined)
    }
  }

  function onMaxButtonClick() {
    setRunesAmount(availableBalance ?? runeBalance.runesAmount)
  }

  function handleOnClose() {
    abandonEvent(true, 'transfer', {
      token_name: runeBalance.runeName,
      token_type: 'rune',
      transfer_amount: runesAmount,
      fee: selectedNetworkFee,
    })

    setError(undefined)
    onClose()
  }

  function handleOnCancel() {
    abandonEvent(false, 'transfer', {
      token_name: runeBalance.runeName,
      token_type: 'rune',
      transfer_amount: runesAmount,
      fee: selectedNetworkFee,
    })

    setLoadingText(undefined)
  }

  const buttonText = loadingText ?? 'Confirm Transfer'
  const disabled =
    !runesAddress ||
    !paymentAddress ||
    !runesAmount ||
    !recipientAddress ||
    !!runesAmountError ||
    !isValidRecipientAddress

  return (
    <Drawer isOpen={isOpen} onClose={handleOnClose}>
      <DrawerCard>
        <DrawerTitle>Transfer {formatRuneName(runeBalance)}</DrawerTitle>

        <BalanceRow>
          <DrawerBoldColumn>Total Balance</DrawerBoldColumn>
          <DrawerColumn>
            <RunesAmountDisplay rune={runeBalance} runesAmount={runeBalance.runesAmount} />
          </DrawerColumn>
        </BalanceRow>
        <BalanceRow>
          <DrawerBoldColumn>
            Available Balance
            <HelpTooltip
              content={
                'Your total runes balance minus any runes that are currently locked in open orders.'
              }
              icon='info'
            />
          </DrawerBoldColumn>
          <DrawerColumn>
            <RunesAmountDisplay
              rune={runeBalance}
              runesAmount={availableBalance ?? runeBalance.runesAmount}
            />
            <MaxButton onClick={onMaxButtonClick}>Max</MaxButton>
          </DrawerColumn>
        </BalanceRow>
        <DrawerRow>
          <DrawerBoldColumn>Quantity</DrawerBoldColumn>
        </DrawerRow>
        <DrawerRow>
          <BigIntInput
            value={runesAmount}
            onChange={onRunesAmountChange}
            decimals={runeBalance.runeDecimals}
            helperText={runesAmount === 0n ? 'Required' : runesAmountError ?? undefined}
          />
        </DrawerRow>
      </DrawerCard>
      <DrawerCard>
        <DrawerRow>
          <DrawerBoldColumn>Recipient Address</DrawerBoldColumn>
        </DrawerRow>
        <DrawerRow>
          <AddressInput
            value={recipientAddress}
            onChange={setRecipientAddress}
            helperText={!recipientAddress ? 'Required' : undefined}
            onIsValidChange={setIsValidRecipientAddress}
            isValid={isValidRecipientAddress}
          />
        </DrawerRow>
      </DrawerCard>
      <DrawerCard>
        <DrawerRow>
          <NetworkFeeSelector
            selectedFee={selectedNetworkFee}
            onChange={(fee) => setSelectedNetworkFee(fee)}
          />
        </DrawerRow>
      </DrawerCard>
      {error && <ErrorMessage message={error} />}
      <DrawerButtons>
        {loadingText ? (
          <DrawerCancelButton onClick={() => handleOnCancel()}>Cancel Tx</DrawerCancelButton>
        ) : (
          <DrawerCancelButton onClick={handleOnClose}>Exit</DrawerCancelButton>
        )}
        <DrawerConfirmButton
          onClick={onConfirmClick}
          disabled={disabled || DISABLE_WRITES}
          loading={!!loadingText}
        >
          {buttonText}
        </DrawerConfirmButton>
      </DrawerButtons>
    </Drawer>
  )
}

const BalanceRow = styled(DrawerRow)`
  svg {
    height: 17px;
  }
`

const MaxButton = styled.div<{ disabled?: boolean }>`
  ${BUTTON_HOVER_STYLES}
  color: ${COLORS.hover};
  font-weight: 700;
`
