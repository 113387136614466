import { styled } from 'styled-components'

import { DotsLoader, PaginatedTable } from 'src/shared/components'
import { RuneDetailsLink, RuneNameInput } from 'src/runes'
import { MintStateBadge, MintedPercentBadge } from 'src/minting'
import { BREAKPOINTS } from 'src/shared/constants'

import { useMintsMarketSearch } from '../hooks'

export function MintSearch() {
  const { handleOnChange, loading, hasSearched, searchResults, searchQuery } = useMintsMarketSearch(
    {
      orderBy: 'holderCount',
    }
  )

  return (
    <Container>
      <InputSection>
        <SearchInput value={searchQuery} onChange={handleOnChange} label='Search by Rune Name' />
      </InputSection>
      {loading && searchQuery && <DotsLoader />}
      {hasSearched && searchQuery && (
        <TableSection>
          <PaginatedTable
            columns={[
              {
                dataKey: 'runeName',
                label: 'Token',
                width: 50,
                formatter: ({ data: mint }) => {
                  return <RuneDetailsLink rune={mint} />
                },
              },
              {
                dataKey: 'mintedPercent',
                label: 'Percent Minted',
                width: 25,
                disableSort: true,
                formatter: ({ data: mint }) => <MintedPercentBadge runeDetails={mint} />,
              },
              {
                dataKey: 'mintState',
                label: 'Mint State',
                disableSort: true,
                width: 25,
                formatter: ({ data: mint }) => <MintStateBadge runeDetails={mint} />,
              },
            ]}
            paginatedData={[searchResults]}
            fetchPage={async () => {}}
            loading={loading}
            hasNextPage={false}
            emptyDataMessage='No results found.'
            rowHeight={30}
            rowsPerPage={50}
            paginationDisabled
          />
        </TableSection>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: center;
`
const SearchInput = styled(RuneNameInput)`
  width: 100%;
  max-width: 500px;
  text-transform: uppercase;

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`

const InputSection = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-top: 0px;
  }
`

const TableSection = styled.div`
  width: 100%;
  max-width: 800px;
`
