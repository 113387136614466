import { PopularMintsTable, MintSearch } from 'src/minting'

import { PageContainer } from './PageContainer'

export function MintPage() {
  return (
    <PageContainer>
      <MintSearch />
      <PopularMintsTable />
    </PageContainer>
  )
}
