import { API_ENDPOINTS } from '@packages/constants'
import { RecommendedFees } from '@packages/interfaces'

import { API_URL } from 'src/settings'
import { mempoolApiInstance } from 'src/web3'

export async function getRecommendedNetworkFees({
  setRecommendedNetworkFees,
}: {
  setRecommendedNetworkFees: (fees: RecommendedFees) => void
}) {
  try {
    const response = await fetch(`${API_URL}${API_ENDPOINTS.GET.chain.recommendedFees}`)
    if (response.ok) {
      const recommendedFees = await response.json()
      setRecommendedNetworkFees(recommendedFees)
    }
  } catch (error) {
    console.error(error)
    const {
      bitcoin: { fees },
    } = mempoolApiInstance

    const recommendedFees = await fees.getFeesRecommended()
    setRecommendedNetworkFees(recommendedFees)
  }
}
