import { styled } from 'styled-components'

import { OKX_URLS } from '@packages/utils'

import { A, ToastContainer, ToastOptions } from 'src/shared/components'
import { IS_TESTNET_DEPLOYMENT } from 'src/settings'

import { mempoolUrls } from '../utils'

interface SuccessfulTransactionToastProps extends ToastOptions {
  message?: string
  transactionId: string
  className?: string
}

export function SuccessfulTransactionToast({
  className,
  transactionId,
  message,
}: SuccessfulTransactionToastProps) {
  return (
    <ToastContainer className={className}>
      <Message>{message ?? 'Transaction successful!'} </Message>
      <A href={IS_TESTNET_DEPLOYMENT ? mempoolUrls.tx(transactionId) : OKX_URLS.tx(transactionId)}>
        View on Explorer
      </A>
    </ToastContainer>
  )
}

const Message = styled.div``
