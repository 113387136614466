import Decimal from 'decimal.js-light'

import { Rune } from '@packages/interfaces'
import { MAX_SATS_PRICE_DECIMALS, bigIntToDecimal, formatNumber } from '@packages/utils'

export function parsePriceStr(price: string): number {
  return parseFloat(price.replace(/,/g, ''))
}

export function calculateTotalSatsFromDesiredPrice({
  desiredPriceSats,
  desiredRunesAmount = 0n,
  rune,
}: {
  desiredPriceSats: string | number
  desiredRunesAmount?: bigint
  rune: Rune
}): bigint {
  const totalSats = BigInt(
    new Decimal(desiredPriceSats)
      .mul(`${desiredRunesAmount}`)
      .div(10 ** Number(rune.runeDecimals))
      .toInteger()
      .toString()
  )
  return totalSats
}

export function calculatePrice({
  satsAmount,
  runesAmount,
  rune,
  format = true,
}: {
  satsAmount: bigint
  runesAmount: bigint
  rune: Rune
  format?: boolean
}) {
  const formattedRunesAmount = bigIntToDecimal(runesAmount).div(`${10n ** rune.runeDecimals}`)
  const numStr = formattedRunesAmount.gt(new Decimal(0))
    ? bigIntToDecimal(satsAmount).div(formattedRunesAmount).toFixed(MAX_SATS_PRICE_DECIMALS)
    : '0'

  if (format) {
    return formatNumber({
      numStr,
      showAllDecimals: true,
    })
  } else {
    return numStr
  }
}
