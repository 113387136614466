import { styled } from 'styled-components'
import { useEffect } from 'react'

import { formatUsd } from '@packages/utils'
import { API_ENDPOINTS } from '@packages/constants'
import { Order, ANY_ORDER_STATUS } from '@packages/interfaces'

import { Badge, HelpTooltip, TieredTableCell, VirtualizedTable } from 'src/shared/components'
import { hexToRgb, replaceUrlParams, unixTimestampToDateString } from 'src/shared/utils'
import { RunesAmountDisplay } from 'src/runes'
import { useUpdateOnBlockPaginationApi } from 'src/api'
import { useIsMobile } from 'src/shared/hooks'
import { BREAKPOINTS, COLORS, FONTS } from 'src/shared/constants'
import { useWalletContext } from 'src/wallet'
import { MarketDetailsLink } from 'src/markets'
import { TransactionExternalLink } from 'src/web3'

import { useOrderContext } from '../OrderContext'
import { BtcAmountDisplay } from './BtcAmountDisplay'

interface Props {
  address: string
  runeId?: string
}

export function OrderHistoryByAddress({ address, runeId }: Props) {
  const { isStaleOrderDataTimestamp } = useOrderContext()
  const { runesAddress } = useWalletContext()
  const isMobile = useIsMobile()
  const {
    paginatedData: orderHistory,
    loading,
    fetchPage,
    hasNextPage,
    forceRefresh,
  } = useUpdateOnBlockPaginationApi<Order>({
    endpoint: `${
      runeId
        ? replaceUrlParams(API_ENDPOINTS.GET.runes.orders.byRuneForAccount, {
            address,
            runeId,
          })
        : replaceUrlParams(API_ENDPOINTS.GET.runes.orders.byAccount, {
            address,
          })
    }?status=${ANY_ORDER_STATUS}${runesAddress ? `&runesAddress=${runesAddress?.addrString}` : ''}`,
  })

  useEffect(() => {
    if (isStaleOrderDataTimestamp) {
      forceRefresh()
    }
  }, [isStaleOrderDataTimestamp])

  const hideRuneName = !!runeId // do not need to show runeName if on the market details page

  return (
    <Container>
      <VirtualizedTable
        columns={[
          // {
          //   dataKey: 'id',
          //   label: 'ID',
          //   width: 5,
          //   hideOnMobile: true,
          // },
          ...(hideRuneName
            ? []
            : [
                {
                  dataKey: 'runeName',
                  label: 'Rune',
                  width: isMobile ? 25 : 20,
                  formatter: ({ data: order }: { data: Order }) => (
                    <MarketDetailsLinkWrapper rune={order} />
                  ),
                },
              ]),
          // {
          //   dataKey: 'placedByAddress',
          //   label: 'Side',
          //   width: 10,
          //   hideOnMobile: true,
          //   formatter: ({ data: order }) => {
          //     if (order.placedByAddress === address) {
          //       return <SideContainer side='sell'>Sell</SideContainer>
          //     } else {
          //       return <SideContainer side='buy'>Buy</SideContainer>
          //     }
          //   },
          // },
          {
            dataKey: 'side',
            label: 'Side',
            width: isMobile ? 15 : 10,
            formatter: ({ data: order }) => {
              const isSellOrder = order.placedByAddress === address
              return (
                <BadgeWrapper color={isSellOrder ? COLORS.negative : COLORS.positive}>
                  {isSellOrder ? 'Sell' : 'Buy'}
                </BadgeWrapper>
              )
            },
          },
          {
            dataKey: 'priceSats',
            width: hideRuneName ? 20 : 15,
            label: 'Price (sats)',
            hideOnMobile: !hideRuneName,
            formatter: ({ data: order }) => (
              <TieredTableCell header={order.priceSats} subHeader={order.priceUsd} />
            ),
          },
          {
            dataKey: 'runesAmount',
            width: isMobile ? 15 : 20,
            label: isMobile ? 'Qty' : 'Quantity',
            formatter: ({ data: order }) => {
              return (
                <RunesAmountDisplay
                  rune={order}
                  runesAmount={order.runesAmount}
                  shortFormat={isMobile}
                />
              )
            },
          },
          {
            dataKey: 'satsAmount',
            label: 'Total',
            width: 20,
            hideOnMobile: true,
            formatter: ({ data: order }) => {
              return (
                <TieredTableCell
                  header={<BtcAmountDisplay btcAmount={order.satsAmount} showPriceSymbol />}
                  subHeader={formatUsd({ usd: order.valueUsd, shortFormat: isMobile })}
                />
              )
            },
          },
          {
            dataKey: 'placedAtTimestamp',
            label: 'Placed',
            width: isMobile ? 25 : 15,
            hideOnMobile: true,
            content: (
              <ThContainer>
                Placed{' '}
                <HelpTooltip
                  content={
                    'If applicable, this column links to the tx used to split or combine your runes in preparation for your sell order.'
                  }
                  icon='info'
                />
              </ThContainer>
            ),
            formatter: ({ data: order }) => {
              if (order.boxTransactionId) {
                return (
                  <TransactionExternalLink transactionId={order.boxTransactionId}>
                    {unixTimestampToDateString(
                      order.boxedAtTimestamp ?? order.placedAtTimestamp,
                      true
                    )}
                  </TransactionExternalLink>
                )
              } else {
                return unixTimestampToDateString(order.placedAtTimestamp, true)
              }
            },
          },
          {
            dataKey: 'fillBroadcastAtTimestamp',
            label: 'Filled',
            width: 15,
            hideOnMobile: true,
            content: (
              <ThContainer>
                Filled{' '}
                <HelpTooltip content={'This column links to the final order tx.'} icon='info' />
              </ThContainer>
            ),
            formatter: ({ data: order }) => {
              if (
                order.fillTransactionId &&
                (order.fillBroadcastAtTimestamp || order.filledAtTimestamp)
              ) {
                return (
                  <TransactionExternalLink transactionId={order.fillTransactionId}>
                    {unixTimestampToDateString(
                      (order.filledAtTimestamp ?? order.fillBroadcastAtTimestamp) as bigint,
                      true
                    )}
                  </TransactionExternalLink>
                )
              }
            },
          },
          {
            dataKey: 'status',
            label: 'Status',
            width: isMobile ? 20 : 15,
            formatter: ({ data: order }) => {
              if (order.isActive) {
                return <BadgeWrapper color={COLORS.positive}>Active</BadgeWrapper>
              } else if (order.status === 'Cancelled') {
                return (
                  <BadgeWrapper color={COLORS.negative}>
                    {isMobile ? 'Canc.' : 'Cancelled'}
                  </BadgeWrapper>
                )
              } else if (order.status === 'Voided') {
                return <BadgeWrapper color={COLORS.negative}>{order.status}</BadgeWrapper>
              } else if (order.status === 'Filled') {
                return (
                  <BadgeWrapper color={hexToRgb(COLORS.white, 0.3)}>{order.status}</BadgeWrapper>
                )
              } else if (order.status === 'FilledPending') {
                return (
                  <BadgeWrapper color={hexToRgb(COLORS.hover)}>
                    {isMobile ? 'FP' : 'Fill Pending'}
                  </BadgeWrapper>
                )
              } else {
                // Pending
                return <BadgeWrapper color={COLORS.warning}>Pending</BadgeWrapper>
              }
            },
          },
        ]}
        paginatedData={orderHistory}
        fetchPage={fetchPage}
        loading={loading}
        hasNextPage={hasNextPage}
        rowHeight={45}
        viewableRows={25}
        emptyDataMessage='No order history'
      />
    </Container>
  )
}

const Container = styled.div``

const MarketDetailsLinkWrapper = styled(MarketDetailsLink)`
  @media (max-width: ${BREAKPOINTS.medium}) {
    div {
      font-size: 10px !important;
    }
  }
`

const BadgeWrapper = styled(Badge)<{ color: string }>`
  background-color: ${(props) => props.color} !important;
  border-radius: 5px !important;
  text-transform: uppercase;
  font-family: ${FONTS.text};
  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: 10px;
  }
`

const ThContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    height: 15px;
  }
`
