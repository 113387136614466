import { useState, useEffect } from 'react'

import { useWindowDimensions } from '.'
import { BREAKPOINTS } from '../constants'

export function useIsMobile() {
  const { width } = useWindowDimensions()
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(width < parseInt(BREAKPOINTS.medium))
  }, [width])

  return isMobile
}
