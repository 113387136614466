import { styled } from 'styled-components'

import closeIcon from 'src/shared/assets/close.svg'

import { BREAKPOINTS } from '../../constants'

interface Props {
  onClose: () => void
  canClose?: boolean
  className?: string
}

export function CloseButton({ onClose, canClose = true, className }: Props) {
  return (
    <Button onClick={onClose} disabled={!canClose} className={className}>
      <ButtonIcon src={closeIcon} />
    </Button>
  )
}

const Button = styled.button`
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0px;
`

const ButtonIcon = styled.img`
  height: 35px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    height: 30px;
  }
`
