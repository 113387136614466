import { RuneOutpointDetails } from '@packages/interfaces'

import { A } from 'src/shared/components'
import { formatShortTransactionId, mempoolUrls } from 'src/web3'

interface Props {
  runeOutpoint: RuneOutpointDetails
  displayShortId?: boolean
  children?: React.ReactNode
  className?: string
}

export function RuneOutpointExternalLink({
  runeOutpoint,
  displayShortId = true,
  children,
  className,
}: Props) {
  const [transactionId, vout] = runeOutpoint.outpointId.split(':')
  return (
    <A href={mempoolUrls.utxo(transactionId, Number(vout))} className={className}>
      {children ?? (displayShortId ? formatShortTransactionId(transactionId) : transactionId)}
    </A>
  )
}
