/* eslint-disable @typescript-eslint/no-unused-vars */
import { AddressPurpose, BitcoinProvider, Params, Requests, RpcResponse } from 'sats-connect'
import { getAddressInfo } from 'bitcoin-address-validation'

import { hexToBase64 } from 'src/shared/utils'
import { IS_TESTNET_DEPLOYMENT } from 'src/settings'

import { CustomBitcoinProvider } from './CustomBitcoinProvider'
import {
  decodeSendBitcoinRequest,
  decodeSignMessageRequest,
  decodeSignMultipleTransactionRequest,
  decodeSignTransactionRequest,
} from '../utils'

declare global {
  interface Window {
    okxwallet: {
      bitcoin: OkxAPI
      bitcoinTestnet: OkxAPI
    }
  }
}

interface OkxSignPsbtOptions {
  autoFinalized: boolean
  toSignInputs?: {
    index: number
    address?: string
    publicKey?: string
    sighashTypes?: number[]
    disableTweakSigner?: boolean
  }[]
}

export interface OkxAPI {
  connect: () => Promise<ConnectResponse>
  getAccounts: () => Promise<string[]>
  signMessage: (message: string, type?: 'ecdsa' | 'bip322-simple') => Promise<string>
  signPsbt: (psbtHex: string, options?: OkxSignPsbtOptions) => Promise<string>
  signPsbts: (psbtHexs: string[], options?: OkxSignPsbtOptions[]) => Promise<string[]>
  pushPsbt: (psbtHex: string) => Promise<string>
  sendBitcoin: (address: string, amount: number) => string
  request<Method extends keyof Requests>(
    method: Method,
    options: Params<Method>,
    providerId: string | undefined
  ): Promise<RpcResponse<Method>>
}

interface ConnectResponse {
  address: string
  publicKey: string
}

export class OkxBitcoinProvider implements BitcoinProvider, CustomBitcoinProvider {
  private okx!: OkxAPI

  public addListener(eventName: any, cb: (event: any) => void) {
    return () => {}
  }

  public isAvailable() {
    this.setLib()

    return !!this.okx
  }

  public setLib() {
    if (!this.okx && typeof (window as any).okxwallet !== 'undefined') {
      if (IS_TESTNET_DEPLOYMENT) {
        this.okx = (window as any).okxwallet?.bitcoinTestnet
      } else {
        this.okx = (window as any).okxwallet?.bitcoin
      }
    }
  }

  public async connect(_request: string) {
    const response: ConnectResponse = await this.okx.connect()

    const formattedAddresses = []
    // okx uses the same address for ordinals and payments
    formattedAddresses.push(
      this.formatAddress(response.address, AddressPurpose.Payment, response.publicKey)
    )
    formattedAddresses.push(
      this.formatAddress(response.address, AddressPurpose.Ordinals, response.publicKey)
    )

    return { addresses: formattedAddresses }
  }

  private formatAddress(address: string, purpose: AddressPurpose, publicKey?: string) {
    const addressInfo = getAddressInfo(address)
    return {
      address,
      publicKey: publicKey ?? '',
      purpose,
      addressType: addressInfo.type,
    }
  }

  public async disconnect() {}

  public async getCurrentAddress() {
    const accounts = await this.okx.getAccounts()
    return {
      addresses: [
        this.formatAddress(accounts[0], AddressPurpose.Ordinals),
        this.formatAddress(accounts[0], AddressPurpose.Payment),
      ],
    }
  }

  public async signMessage(request: string) {
    return await this.okx.signMessage(decodeSignMessageRequest(request), 'ecdsa')
  }

  // https://www.okx.com/web3/build/docs/sdks/chains/bitcoin/provider#signpsbt
  public async signTransaction(request: string) {
    const { psbtHex, broadcast } = decodeSignTransactionRequest(request)
    const resultHex = await this.okx.signPsbt(psbtHex, {
      autoFinalized: broadcast,
    })
    let transactionHash
    if (broadcast && !IS_TESTNET_DEPLOYMENT) {
      // broadcasting doesn't work for OKX on testnet - getting the error `sequence too long`
      // i think this is due to okx not updating their keys for testnet in the wallet - only offline signing works
      transactionHash = await this.okx.pushPsbt(resultHex)
    }

    return {
      psbtBase64: hexToBase64(resultHex),
      txId: transactionHash,
    }
  }

  public async signMultipleTransactions(request: string) {
    try {
      const decodedRequests = decodeSignMultipleTransactionRequest(request)
      const signBody = decodedRequests.psbts.map((decodedRequest) => decodedRequest.psbtHex)
      const resultHexs = await this.okx.signPsbts(
        signBody,
        decodedRequests.psbts.map((decodedRequest) => {
          return {
            toSignInputs: decodedRequest.inputsToSign.map((input, index) => {
              return { index, ...input }
            }),
            autoFinalized: false,
          }
        })
      )

      return resultHexs.map((resultHex) => {
        return { psbtBase64: hexToBase64(resultHex), txId: undefined }
      })
    } catch (e) {
      console.log('error with multi sign', e)
      throw new Error('error with multi sign')
    }
  }

  public async sendBtcTransaction(request: string) {
    const { amount, address } = decodeSendBitcoinRequest(request)
    const transactionHash = this.okx.sendBitcoin(address, amount)
    return transactionHash
  }

  public async call(_request: string) {
    throw new Error('Not implemented')
    return {}
  }

  public async createInscription(_request: string) {
    throw new Error('Not implemented')
    return { txId: '' }
  }

  public async createRepeatInscriptions(_request: string) {
    throw new Error('Not implemented')
    return { txId: '' }
  }

  request<Method extends keyof Requests>(
    method: Method,
    options: Params<Method>,
    providerId: string | undefined
  ): Promise<RpcResponse<Method>> {
    return this.okx.request(method, options, providerId)
  }
}
