import { css } from 'styled-components'

import { COLORS } from '../constants'

export const GRADIENT_BG_CSS = css`
  background: repeating-linear-gradient(
    to right,
    ${COLORS.negative} 0%,
    ${COLORS.gradient} 50%,
    ${COLORS.negative} 100%
  );
  width: 100%;
  background-size: 200% auto;
  background-position: 0 100%;
  animation: gradient 2s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;

  @keyframes gradient {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
`
