import { styled } from 'styled-components'
import { useNavigate } from 'react-router-dom'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import { COLORS, FONTS } from 'src/shared/constants'
import { DEFAULT_CSS_TRANSITION } from 'src/shared/styles'

import { ToastContainer } from './styles'
import { BUTTON_HOVER_STYLES } from '../buttons'
import { ToastOptions } from './ToastOptions'

interface LinkPageToastProps extends ToastOptions {
  message: string
  styleVariant?: string
  buttonMessage: string
  reactRouterPathname: string
  reactRouterSearch?: string
  className?: string
}

export function LinkPageToast({
  styleVariant,
  className,
  message,
  buttonMessage,
  reactRouterPathname,
  reactRouterSearch,
  closeToast,
}: LinkPageToastProps) {
  const navigate = useNavigate()

  function handleClick() {
    if (closeToast) closeToast()
    navigate({ pathname: reactRouterPathname, search: reactRouterSearch })
  }

  return (
    <ToastWrapper className={className} styleVariant={styleVariant}>
      <Message>
        {styleVariant === 'error' || (styleVariant === 'warning' && <ErrorOutlineIcon />)}
        {message}
      </Message>
      <LinkButton onClick={handleClick}>{buttonMessage}</LinkButton>
    </ToastWrapper>
  )
}

const ToastWrapper = styled(ToastContainer)<{ styleVariant?: string }>`
  svg {
    color: ${({ styleVariant }) =>
      styleVariant === 'error' ? COLORS.negative : COLORS.warning} !important;
    height: 20px;
  }
  font-weight: 500;
`

const Message = styled.span``

const LinkButton = styled.div`
  ${DEFAULT_CSS_TRANSITION}
  color: ${COLORS.link};
  font-weight: 700;
  font-family: ${FONTS.text};
  ${BUTTON_HOVER_STYLES}
`
