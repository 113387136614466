import { styled } from 'styled-components'
import { Network, validate } from 'bitcoin-address-validation'

import { TextInput } from 'src/shared/components'
import { IS_TESTNET_DEPLOYMENT } from 'src/settings'

interface Props {
  value: string
  onChange: (value: string) => void
  onIsValidChange: (isValid: boolean) => void
  isValid: boolean
  helperText?: string
  className?: string
}

export function AddressInput({
  value,
  onChange,
  isValid,
  onIsValidChange,
  helperText,
  className,
}: Props) {
  function handleOnChange(value: string) {
    onIsValidChange(validate(value, IS_TESTNET_DEPLOYMENT ? Network.testnet : Network.mainnet))

    onChange(value)
  }

  return (
    <TextInputWrapper
      value={value}
      onChange={handleOnChange}
      error={!isValid}
      helperText={helperText || (isValid ? undefined : 'Invalid address')}
      className={className}
    />
  )
}

const TextInputWrapper = styled(TextInput)``
