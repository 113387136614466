import { PopularMarketsTable } from 'src/markets'
import { RuneSwapper } from 'src/orders'

import { PageContainer } from './PageContainer'

export function MarketsPage() {
  return (
    <PageContainer>
      <RuneSwapper />
      <PopularMarketsTable />
    </PageContainer>
  )
}
