import { styled } from 'styled-components'

import { COLORS, FONTS } from 'src/shared/constants'
import { DEFAULT_CSS_TRANSITION } from 'src/shared/styles'

import { ToastContainer } from './styles'
import { BUTTON_HOVER_STYLES } from '../buttons'
import { ToastOptions } from './ToastOptions'

interface RefreshPageToastProps extends ToastOptions {
  message?: string
  buttonMessage?: string
  onClick: () => void
  className?: string
}

export function RefreshPageToast({
  className,
  message,
  buttonMessage,
  onClick,
  closeToast,
}: RefreshPageToastProps) {
  function handleRefresh() {
    if (closeToast) closeToast()
    onClick()
  }

  return (
    <ToastContainer className={className}>
      <Message>{message ?? 'Refresh to get the latest data.'} </Message>
      <RefreshButton onClick={handleRefresh}>{buttonMessage ?? 'Refresh'}</RefreshButton>
    </ToastContainer>
  )
}

const Message = styled.div``

const RefreshButton = styled.div`
  ${DEFAULT_CSS_TRANSITION}
  color: ${COLORS.link};
  font-weight: 700;
  font-family: ${FONTS.text};
  ${BUTTON_HOVER_STYLES}
`
