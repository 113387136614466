import styled from 'styled-components'

import { BREAKPOINTS, Z_INDEX } from 'src/shared/constants'

import { ExternalLinks } from './NavLinks'
import { CONTENT_PADDING, MOBILE_CONTENT_PADDING } from '../constants'

export function Footer() {
  return (
    <Container>
      <Content>
        <ExternalLinks />
      </Content>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  background-color: transparent;
  z-index: ${Z_INDEX.content};
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  gap: 10px;

  padding-top: 50px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-top: 25px;
  }
`

const Content = styled.div`
  padding: ${CONTENT_PADDING} 0px;
  width: 100%;

  color: rgba(256, 256, 256, 0.2);
  max-width: ${BREAKPOINTS.large};
  @media (max-width: ${BREAKPOINTS.medium}) {
    padding: ${MOBILE_CONTENT_PADDING};
  }
`
