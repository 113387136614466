import * as React from 'react'
import Box from '@mui/material/Box'
import _Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import LastPageIcon from '@mui/icons-material/LastPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { styled } from 'styled-components'
import { useEffect } from 'react'
import TableFooter from '@mui/material/TableFooter'
import Skeleton from '@mui/material/Skeleton'

import { SORT_ORDER } from '@packages/constants'

import { useIsMobile } from 'src/shared/hooks'
import { hexToRgb } from 'src/shared/utils'

import { COLORS } from '../../constants'
import { getComparator, stableSort } from './utils'
import {
  HeaderTableCellWrapper,
  SortDescription,
  TableCellWrapper,
  TableLabel,
  TablePaper,
  TableSortLabelWrapper,
} from './styles'
import { ColumnData } from './interfaces'

const ROW_PADDING = 14
const MOBILE_ROW_PADDING = 10

interface HeaderProps<T> {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  order: SORT_ORDER
  orderBy?: string
  columns: ColumnData<T>[]
}

function TableHeader<T>({ order, orderBy, onRequestSort, columns }: HeaderProps<T>) {
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <HeaderTableCellWrapper
            key={`${String(column.dataKey)}`}
            sortDirection={orderBy === column.dataKey ? order : false}
            $hideOnMobile={column.hideOnMobile ?? false}
            $hideOnDesktop={column.hideOnDesktop ?? false}
            width={column.width}
          >
            {column.disableSort ? (
              <TableLabel> {column.content ? column.content : column.label}</TableLabel>
            ) : (
              <TableSortLabelWrapper
                active={orderBy === column.dataKey}
                direction={orderBy === column.dataKey ? order : 'asc'}
                onClick={createSortHandler(column.dataKey)}
              >
                {column.label}
                {orderBy === column.dataKey ? <SortDescription order={order} /> : null}
              </TableSortLabelWrapper>
            )}
          </HeaderTableCellWrapper>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface Props<T> {
  columns: ColumnData<T>[]
  paginatedData: T[][]
  rowHeight?: number
  rowsPerPage?: number
  className?: string
  newPageLoading?: boolean
  loading?: boolean
  fetchPage: (page: number) => Promise<void>
  hasNextPage?: boolean
  emptyDataMessage?: string
  paginationDisabled?: boolean
}

export function PaginatedTable<T>({
  columns,
  paginatedData,
  rowsPerPage = 10,
  fetchPage,
  hasNextPage,
  newPageLoading = false,
  loading = false,
  className,
  rowHeight,
  emptyDataMessage,
  paginationDisabled = false,
}: Props<T>) {
  const isMobile = useIsMobile()
  const [order, setOrder] = React.useState<SORT_ORDER>('asc')
  const [orderBy, setOrderBy] = React.useState<string>()
  const [page, setPage] = React.useState(0)

  const rowHeightWithPadding = (rowHeight ?? 50) + (isMobile ? MOBILE_ROW_PADDING : ROW_PADDING) // padding

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
    fetchPage(newPage)
  }

  useEffect(() => {
    async function loadData() {
      if ((paginatedData?.length ?? 0) < page + 1 && !loading) {
        await fetchPage(page)
      }
    }

    loadData()
  }, [page, paginatedData, loading])

  // data not loaded yet
  const rows = (paginatedData?.length ?? 0) < page + 1 ? [] : paginatedData[page]

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = rowsPerPage - rows.length

  const visibleRows = React.useMemo(
    () =>
      orderBy
        ? stableSort(rows as any, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )
        : rows,
    [rows, order, orderBy, page, rowsPerPage]
  )

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleChangePage(event, 0)
  }
  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleChangePage(event, paginatedData.length - 1)
  }

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleChangePage(event, page - 1)
  }

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleChangePage(event, page + 1)
  }

  return (
    <TablePaper className={className} $rowHeightWithPadding={rowHeightWithPadding} $minHeight={200}>
      <TableContainer>
        <_Table>
          <TableHeader
            onRequestSort={handleRequestSort}
            order={order}
            {...{ orderBy }}
            columns={columns}
          />
          <TableBody>
            {visibleRows.map((row, index) => {
              return (
                <TableRow key={index}>
                  {columns.map((column) => (
                    <TableCellWrapper
                      key={column.dataKey}
                      $hideOnMobile={column.hideOnMobile ?? false}
                      $hideOnDesktop={column.hideOnDesktop ?? false}
                      width={column.width}
                    >
                      {column.formatter
                        ? column.formatter({ data: row as T, index })
                        : typeof (row as any)[column.dataKey] === 'bigint'
                          ? (row as any)[column.dataKey].toString()
                          : (row as any)[column.dataKey]}
                    </TableCellWrapper>
                  ))}
                </TableRow>
              )
            })}
            {!newPageLoading && page > 0 && emptyRows > 0 && (
              <TableRow
                style={{
                  height: rowHeightWithPadding * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
            {newPageLoading &&
              [...Array(rowsPerPage).keys()].map((row) => (
                <TableRow key={`row-skeleton-${row}`}>
                  {columns.map((column) => (
                    <TableCellWrapper
                      key={`loading-skeleton-${column.dataKey}`}
                      $hideOnMobile={column.hideOnMobile ?? false}
                      $hideOnDesktop={column.hideOnDesktop ?? false}
                    >
                      <SkeletonWrapper variant='rounded' height={20} />
                    </TableCellWrapper>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </_Table>
      </TableContainer>
      {!paginationDisabled && (
        <TableFooterWrapper>
          <TableRow>
            <PageControls>
              <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label='first page'
              >
                <FirstPageIcon />
              </IconButton>

              <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label='previous page'
              >
                <KeyboardArrowLeft />
              </IconButton>
              <Page>{page + 1}</Page>
              <IconButton
                onClick={handleNextButtonClick}
                aria-label='next page'
                disabled={!hasNextPage && page >= paginatedData.length - 1}
              >
                <KeyboardArrowRight />
              </IconButton>

              <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page === paginatedData.length - 1}
                aria-label='last page'
              >
                <LastPageIcon />
              </IconButton>
            </PageControls>
          </TableRow>
        </TableFooterWrapper>
      )}
      {rows.length === 0 && !loading && <EmptyDataMessage>{emptyDataMessage}</EmptyDataMessage>}
    </TablePaper>
  )
}

const TableFooterWrapper = styled(TableFooter)`
  width: 100%;
  display: flex;
  justify-content: center;
  button {
    color: ${COLORS.white};
    &:hover {
      color: ${hexToRgb(COLORS.white, 0.8)};
    }
    &:disabled {
      color: ${hexToRgb(COLORS.white, 0.5)};
    }
  }
`

const SkeletonWrapper = styled(Skeleton)`
  background-color: ${COLORS.background.container};
`

const EmptyDataMessage = styled.div`
  text-align: center;
  padding: 20px;
`

const PageControls = styled(Box)`
  display: flex;
  align-items: center;
`

const Page = styled.div``
