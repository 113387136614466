import { SATOSHI_DUST_THRESHOLD } from '@packages/constants'

export function calculateFeeFromBps(amount: bigint | number, bps: number) {
  let serviceFeeValue = (BigInt(amount) * BigInt(bps)) / BigInt(10000)
  if (serviceFeeValue < SATOSHI_DUST_THRESHOLD) {
    serviceFeeValue = 0n
  }
  return serviceFeeValue
}

export function isFeeWithinRange({
  actualFee,
  minimumFee,
  amount,
  feeBps,
  differenceBps,
  onlyGreater = false,
}: {
  actualFee: bigint
  minimumFee?: bigint
  amount: bigint
  feeBps: number
  differenceBps: number
  onlyGreater?: boolean
}) {
  const expectedFee = calculateFeeFromBps(amount, feeBps)
  const range = (expectedFee * BigInt(differenceBps)) / BigInt(10000)
  if (onlyGreater) {
    return (
      actualFee >= expectedFee - BigInt(range) ||
      (minimumFee && expectedFee < minimumFee && actualFee === minimumFee)
    )
  }
  return actualFee >= expectedFee - BigInt(range) && actualFee <= expectedFee + BigInt(range)
}
