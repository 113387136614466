import { useMemo } from 'react'
import Decimal from 'decimal.js-light'

import { useWalletContext } from '../WalletContext'

export function useBtcBalance() {
  const { btcBalances, btcPrice } = useWalletContext()

  const btcBalanceUsd = useMemo(() => {
    const btcPriceUsd = btcPrice?.btcPriceUsd ?? 0
    return new Decimal(btcBalances?.chainBalanceBtc ?? 0).mul(btcPriceUsd).toNumber()
  }, [btcPrice, btcBalances?.chainBalanceBtc])

  return { btcBalanceUsd, btcBalance: Number(btcBalances?.chainBalanceBtc ?? 0) }
}
