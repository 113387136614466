import { styled } from 'styled-components'

import { RuneDetails } from '@packages/interfaces'
import { formatRuneName } from '@packages/utils'

import { HeaderText } from 'src/shared/components'
import { BREAKPOINTS } from 'src/shared/constants'

import { RuneImage } from './RuneImage'

interface Props {
  runeDetails?: RuneDetails
  className?: string
}

export function RuneNameHeader({ runeDetails, className }: Props) {
  const formattedRuneName = runeDetails ? formatRuneName(runeDetails) : ''
  return (
    <RuneName className={className} $runeName={formattedRuneName}>
      {runeDetails && <RuneImage runeDetails={runeDetails} size='medium' />}
      {formattedRuneName}
    </RuneName>
  )
}

const RuneName = styled(HeaderText)<{ $runeName: string }>`
  display: flex;
  align-items: center;
  gap: 5px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: ${({ $runeName }) => ($runeName.length > 20 ? 20 : 23)}px;
  }
`
