import { ChangeEvent, HTMLProps, ReactElement, useEffect, useRef, useState } from 'react'
import { formatUnits, parseUnits } from '@ethersproject/units'
import { BigNumber } from '@ethersproject/bignumber'
import { styled } from 'styled-components'
import Skeleton from '@mui/material/Skeleton'

import { hexToRgb } from 'src/shared/utils'
import { COLORS, NUMBER_REGEX } from 'src/shared/constants'

export type BigNumberInputProps = {
  decimals: number
  value?: string
  onChange: (value: string) => void
  onFocus?: () => void
  onBlur?: () => void
  renderInput?: (props: HTMLProps<HTMLInputElement>) => ReactElement
  placeholder?: string
  max?: string
  min?: string
  disabled?: boolean
  loading?: boolean
  inputRef?: any
}

export function BigNumberInput({
  decimals,
  value,
  onChange,
  onFocus = () => {},
  onBlur = () => {},
  renderInput,
  placeholder = '0.0',
  max,
  min,
  disabled,
  loading,
  inputRef,
}: BigNumberInputProps) {
  const [inputValue, setInputValue] = useState('')
  const [focused, setFocused] = useState(false)
  const internalInputRef = useRef<HTMLInputElement>(null)

  // Use the provided inputRef or the internal one
  const inputElementRef = inputRef || internalInputRef

  const handleOnFocus = () => {
    setFocused(true)
    onFocus()
  }

  const handleOnBlur = () => {
    setFocused(false)
    onBlur()
  }

  useEffect(() => {
    if (disabled) {
      setFocused(false)
    }
  }, [disabled])

  // update current value
  useEffect(() => {
    if (!value) {
      setInputValue('')
    } else {
      let parseInputValue

      try {
        parseInputValue = parseUnits(inputValue || '0', decimals)
      } catch (e) {
        console.error(e)
        // do nothing
      }

      if ((!parseInputValue || !parseInputValue.eq(value)) && value !== '0') {
        setInputValue(formatUnits(value, decimals))
      } else if (value === '0' && !focused) {
        setInputValue('')
      }
    }
  }, [value, decimals, inputValue])

  const updateValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget
    const cursorPosition = event.currentTarget.selectionStart // Get the current cursor position
    const added = value.length > inputValue.length
    let newCursorPosition = cursorPosition
      ? added
        ? cursorPosition - 1
        : cursorPosition + 1
      : null
    newCursorPosition = cursorPosition === 0 ? 1 : newCursorPosition

    if (value === '') {
      onChange(value)
      setInputValue(value)
      return
    }

    let newValue: BigNumber
    try {
      const startsWithDot = value.startsWith('.')
      if (startsWithDot || NUMBER_REGEX.test(value)) {
        newValue = parseUnits(`${startsWithDot ? '0' + value : value}`, decimals)
      } else {
        restoreCursorPosition(newCursorPosition)
        return
      }
    } catch (e) {
      console.error(e)
      restoreCursorPosition(newCursorPosition)
      return
    }

    const invalidValue = (min && newValue.lt(min)) || (max && newValue.gt(max))
    if (invalidValue) {
      restoreCursorPosition(newCursorPosition)
      return
    }

    setInputValue(value)
    onChange(newValue.toString())
  }

  function restoreCursorPosition(cursorPosition: number | null) {
    // Restore cursor position after value is updated
    setTimeout(() => {
      if (inputElementRef.current && cursorPosition !== null) {
        inputElementRef.current.setSelectionRange(cursorPosition, cursorPosition)
      }
    }, 0) // Ensure this happens after the DOM updates
  }

  const inputProps = {
    placeholder,
    onChange: updateValue,
    onFocus: handleOnFocus,
    onBlur: handleOnBlur,
    type: 'text',
    value: inputValue,
    disabled,
  }

  if (loading) return <SkeletonWrapper />

  return renderInput ? (
    renderInput({ ...inputProps })
  ) : (
    <input {...inputProps} ref={inputElementRef} />
  )
}

const SkeletonWrapper = styled(Skeleton)`
  height: 50px;
  background-color: ${hexToRgb(COLORS.white, 0.1)};
  border-radius: 8px;
  margin-right: 15px;
`
