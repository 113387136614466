export function formatNumber({
  numStr,
  useCompactNotation = false,
  showAllDecimals = false,
  maxDecimals,
}: {
  numStr: string
  useCompactNotation?: boolean
  maxDecimals?: number
  showAllDecimals?: boolean
}) {
  let integerLength = 0
  let decimalLength = 0

  if (numStr.includes('e')) {
    const [base, exponent] = numStr.split('e')
    const baseParts = base.split('.')
    const baseDecimalLength = baseParts[1] ? baseParts[1].length : 0
    decimalLength = Math.max(baseDecimalLength - Number(exponent), 0)
    integerLength = baseParts[0].replace('-', '').length + Math.max(Number(exponent), 0)
  } else if (numStr.includes('.')) {
    const parts = numStr.split('.')
    integerLength = parts[0].length
    decimalLength = parts[1].length
  } else {
    integerLength = numStr.length
  }

  let notation: 'compact' | 'standard' | 'scientific' | 'engineering' | undefined =
    useCompactNotation ? 'compact' : 'standard'

  if (integerLength >= 20) {
    notation = 'scientific'
  }

  if (integerLength <= 4) {
    notation = 'standard'
  }

  let num = Number(numStr)

  let minimumSignificantDigits
  if (num < 1 && decimalLength > 1) {
    minimumSignificantDigits = 1
  }

  let minimumFractionDigits
  if (showAllDecimals) {
    minimumFractionDigits = decimalLength
  }

  if (maxDecimals) {
    if (decimalLength > maxDecimals) {
      num = parseFloat(num.toFixed(maxDecimals))
    }
  }

  const formattedNumber = new Intl.NumberFormat('en-US', {
    notation,
    ...(minimumSignificantDigits && { minimumSignificantDigits }),
    ...(minimumFractionDigits && { minimumFractionDigits }),
  }).format(num)

  const roundedNumber = Math.round(num)

  const trimmedFormattedNumber = formattedNumber
    .replace(/(\.\d*?[1-9])0+$/g, '$1')
    .replace(/\.0+$/, '')

  if (notation === 'compact') {
    if (num === roundedNumber) {
      return trimmedFormattedNumber
    } else {
      return '>' + trimmedFormattedNumber
    }
  } else {
    return trimmedFormattedNumber
  }
}
