import { useEffect, useMemo, useState } from 'react'

import { PaginatedTable } from 'src/shared/components'
import { readItemFromLocalStorage, unixMsTimestampToDateString } from 'src/shared/utils'
import { TransactionExternalLink } from 'src/web3'
import { WalletPickerMenu, useWalletContext } from 'src/wallet'

import {
  ETCH_REVEALS_TRANSACTION_IDS,
  ETCH_REVEAL_REQUEST_LOCAL_STORAGE_KEY_PREFIX,
  RUNE_NAME_REGEX,
} from '../constants'
import { RevealedEtchedRune } from '../interfaces'

export function EtchedRunesTable() {
  const { runesAddress } = useWalletContext()
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false)

  const [etchedRunesTrxIds, setEtchedRunesTrxIds] = useState<string[]>([])

  useEffect(() => {
    setEtchedRunesTrxIds(readItemFromLocalStorage(ETCH_REVEALS_TRANSACTION_IDS, []) as string[])
  }, [])

  const etchedRunes = useMemo(() => {
    return etchedRunesTrxIds
      .map((transactionId) => {
        const rune: RevealedEtchedRune | undefined = readItemFromLocalStorage(
          `${ETCH_REVEAL_REQUEST_LOCAL_STORAGE_KEY_PREFIX}${transactionId}`,
          undefined,
        )
        return rune
      })
      .filter(
        (rune): rune is RevealedEtchedRune =>
          rune !== undefined && rune.etchingCommit.committedByAddress === runesAddress?.addrString,
      )
  }, [etchedRunesTrxIds, runesAddress])

  return (
    <>
      <PaginatedTable
        columns={[
          {
            dataKey: 'rune',
            label: 'Name',
            formatter: ({ data: payload }) =>
              payload.etchingParams.rune?.replace(RUNE_NAME_REGEX.replaceSpacersWithBullet, '•'),
          },

          {
            dataKey: 'commitTransactionId',
            label: 'Commited At',
            formatter: ({ data: payload }) => (
              <TransactionExternalLink transactionId={payload.etchingCommit.txId}>
                {unixMsTimestampToDateString(BigInt(payload.etchingCommit.committedAtTimestamp))}
              </TransactionExternalLink>
            ),
          },

          {
            dataKey: 'revealTransactionId',
            label: 'Revealed At',
            formatter: ({ data: payload }) => (
              <TransactionExternalLink transactionId={payload.revealTransactionId}>
                {unixMsTimestampToDateString(BigInt(payload.revealedAtTimestamp))}
              </TransactionExternalLink>
            ),
          },
        ]}
        paginatedData={[etchedRunes]}
        fetchPage={async () => {}}
        loading={false}
        hasNextPage={false}
      />
      <WalletPickerMenu isOpen={isWalletModalOpen} onClose={() => setIsWalletModalOpen(false)} />
    </>
  )
}
